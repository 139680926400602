.we-offer {
  --border-style: 1px solid var(--grey-lines);

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list {
    counter-reset: list;
  }

  &__title-wrapper {
    display: flex;
  }

  &__image-wrap {
    margin-left: calc(0px - var(--grid-spacer));
  }
}

@include respond-up("s-large") {
  .we-offer {
    padding-top: 160px;

    &__list-desktop-wrapper,
    &__picture {
      height: calc(604 / 738 * (var(--grid-column7) + var(--grid-spacer)));
    }

    &__list_titles {
      padding-top: 53px;
      padding-bottom: 53px;

      .we-offer {
        &__item {
          padding-top: 36px;
          padding-bottom: 36px;
        }
      }
    }
  }
}

@include respond-up("xs-large") {
  .we-offer {
    padding-top: 140px;

    &__list_images {
      position: relative;

      .we-offer__item {
        transition: opacity;
        transition-duration: var(--default-timing);
        transition-timing-function: var(--default-transition-function);
        margin-left: calc(0px - var(--grid-spacer));
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc(var(--grid-column7) + var(--grid-spacer));

        &:not(:first-child) {
          opacity: 0;
        }

        &._hover {
          opacity: 1;
        }
      }
    }

    &__list-wrapper {
      border-top: var(--border-style);
      border-bottom: var(--border-style);
      margin-top: 40px;
    }

    &__list {
      display: flex;

      &_mobile {
        display: none;
      }
    }

    &__list-desktop-wrapper {
      display: grid;
      grid-template-columns: var(--grid-column7) var(--grid-column7);
      grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));
    }

    &__list_titles {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .we-offer {
        &__title {
          transition: color;
          transition-duration: var(--default-timing);
          transition-timing-function: var(--default-transition-function);
        }

        &__item {
          position: relative;

          &._shadow {
            .we-offer__title {
              color: var(--grey-font);
            }
          }

          &:not(:last-child) {
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              border-top: var(--border-style);
              bottom: 0;
              transition: border-color;
              transition-duration: var(--default-timing);
              transition-timing-function: var(--default-transition-function);
            }

            &._hovered {
              &:before {
                border-color: black;
              }
            }
          }
        }
      }
    }

    &__title-wrapper {
      display: flex;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .we-offer {
    &__list-desktop-wrapper,
    &__picture {
      height: calc(464 / 505 * (var(--grid-column7) + var(--grid-spacer)));
    }

    &__list_titles {
      padding-top: 19px;
      padding-bottom: 19px;

      .we-offer {
        &__item {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
    }
  }
}

@include respond("medium") {
  .we-offer {
    padding-top: 120px;

    &__picture {
      height: calc(140 / 170 * (var(--grid-column2) + var(--grid-spacer)));
    }

    &__list-wrapper {
      border-top: var(--border-style);
      margin-top: 35px;
    }

    &__item {
      padding: 20px 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: calc(var(--vw, 1vw) * 100);
        height: 1px;
        border-top: var(--border-style);
        bottom: 0;
        left: calc(0px - var(--grid-spacer));
      }
    }

    &__image-wrap {
      grid-column: 1/3;
    }

    &__title-wrapper {
      grid-column: 3/11;
      padding-left: calc(var(--grid-column1) / 2);
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}

@include respond-down("medium") {
  .we-offer {
    &__list-desktop-wrapper {
      display: none;
    }

    &__list {
      &_mobile {
        display: block;
      }
    }
  }
}

@include respond-down("small") {
  .we-offer {
    padding-top: 60px;

    &__picture {
      height: calc(73 / 94 * (var(--grid-column3) - (var(--grid-column1) - var(--grid-gap)) + var(--grid-spacer)));
    }

    &__list-wrapper {
      border-top: var(--border-style);
      margin-top: 18px;
    }

    &__item {
      padding: 16px 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: calc(var(--vw, 1vw) * 100);
        height: 1px;
        border-top: var(--border-style);
        bottom: 0;
        left: calc(0px - var(--grid-spacer));
      }
    }

    &__image-wrap {
      grid-column: 1/4;
      padding-right: calc(var(--grid-column1) - var(--grid-gap));
    }

    &__title-wrapper {
      grid-column: 4/11;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
