.filter-checkbox-list {
  input[type="checkbox"] {
    display: none;

    &:checked + label {
      &:before {
        background-image: url("../images/svg/filter-checkbox-checked.svg");
      }
    }
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;

    font-family: var(--h-font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: url("../images/svg/filter-checkbox-unchecked.svg");
    }
  }

  .filter-field {
    &__values {
      margin: -8px 0;
    }

    &__value {
      padding: 8px 0;
    }
  }
}