.preloader-mobile {
  position: fixed;
  z-index: 5900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--grey-background-opacity-70);
  transition: opacity .3s var(--default-transition-function) .2s;
  opacity: 1;
  pointer-events: auto;

  &__icon {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    svg {
      circle {
        transition: stroke-dasharray 1s ease;
      }
    }
  }

  &._hidden {
    pointer-events: none;
    opacity: 0;
  }
}

@include respond-up('xs-large') {
  .preloader-mobile {
    display: none;
  }
}

@include respond-down('medium') {
  .preloader-mobile {
    display: flex;
  }
}