.custom-cursor {
  display: block;
  height: 66px;
  width: 66px;
  margin-left: -33px;
  margin-top: -33px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000100;

  &__arrow,
  &__drag,
  &__plus,
  &__preloader {
    display: flex;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform-origin: center;

    svg {
      transform: scale(.3);
      transition: transform .2s;
    }
  }

  &__disc-inner {
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid var(--grey-font);
      transition-property: width, height;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.1, 0, 0, 0.9);
    }
  }

  &__disc {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    transform-origin: center;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: scale(1) translate3d(-50%, -50%, 0);
    will-change: transform;
    transition: transform .3s cubic-bezier(0.1, 0, 0, 0.9), opacity .3s;

    svg {
      width: 100%;
    }
  }

  &__arrow {
    width: 39px;
    height: 28px;
    overflow: hidden;
    transition: opacity .2s 0s, transform .2s 0s;
    //transform: scale(.3) translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);

    svg {
      path {
        stroke: #fff;
        transition: stroke .2s linear;
      }
    }

    &_prev {
      svg {
        transform: scale(.3) rotate3d(0, 0, 1, 180deg);
      }
    }
  }

  &__drag {
    display: flex;
    transition: opacity .2s 0s, transform .2s 0s;

    svg {
      circle {
        stroke: #fff;
        transition: stroke .2s linear;

        &#progress-bar {
          stroke-dasharray: 333;
          stroke-dashoffset: 0;
          transform: rotate(-90deg) translateX(-106px);
        }
      }

      path {
        fill: #fff;
        transition: fill .2s linear;
      }
    }
  }

  &__plus {
    width: 100%;
    height: 100%;
    transform-box: fill-box;
    transition-property: opacity, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(.165, .84, .44, 1);
  }

  &__preloader {
    svg {
      circle {
        transition: stroke-dasharray 1s ease;
      }
    }
  }

  &._arrows_prev .custom-cursor__arrow_prev,
  &._arrows_next .custom-cursor__arrow_next,
  &._drag .custom-cursor__drag,
  &._plus .custom-cursor__plus {
    opacity: 1;

    svg {
      transform: scale(1);
    }
  }

  &._arrows_prev .custom-cursor__arrow_prev {
    opacity: 1;

    svg {
      transform: scale(1) rotate3d(0, 0, 1, 180deg);
    }
  }

  &._arrows_prev .custom-cursor__disc,
  &._arrows_next .custom-cursor__disc,
  &._drag .custom-cursor__disc,
  &._plus .custom-cursor__disc,
  &._preloader .custom-cursor__disc {
    opacity: 0;
    transform: scale(1) translate3d(-50%, -50%, 0);
  }

  &._black {
    .custom-cursor {
      &__arrow {
        svg {
          path {
            stroke: black;
          }
        }
      }

      &__drag {
        svg {
          circle {
            stroke: black;
          }

          path {
            fill: black;
          }
        }
      }
    }
  }

  &._expand {
    .custom-cursor {
      &__disc-inner {
        &:before {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  &._preloader {
    mix-blend-mode: difference;

    .custom-cursor__preloader {
      opacity: 1;
      transform: scale(1) translate3d(-50%, -50%, 0);
    }
  }

  &._hide {
    display: none;
  }
}

.no-cursor {
  cursor: none!important;
}