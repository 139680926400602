.drop-menu {
  --drop-menu-timing: 0.3s;
  position: fixed;
  background: var(--light-background);
  pointer-events: none;

  //width: calc(var(--vw, 1vw) * 100);
  //height: calc(var(--vw, 1vw) * 100);
  //top: calc(var(--vh, 1vh) * 100 * -1);

  width: 100vw;
  height: 100vh;
  top: -100vh;

  transition: transform;
  transition-delay: .12s;
  transition-duration: var(--drop-menu-timing);
  transition-timing-function: var(--header-timing-function);
  z-index: 4700;

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__inner-item {
    overflow: hidden;
  }

  &._opened {
    pointer-events: auto;
    z-index: 5000;
    transform: translate(0, 100%);

    .drop-menu {
      &__share-block-wrap,
      &__menu-accordion-link-wrap-text,
      &__phone-wrap {
        opacity: 1;
        transform: translate(0, 0);
      }

      &__menu-accordion-link {
        &:after {
          opacity: 1;
        }
      }

      &__small-menu-link {
        transform: translate(0, 0);
      }

      &__menu-accordion-item {
        &:before {
          transform: translate(0, 0);
        }
      }
    }
  }

  &__inner-item-link {
    padding: 10px;
    margin: -10px;
    display: flex;
  }

  &__inner-item {
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        .drop-menu {
          &__inner-item-wrap {
            transition-delay: calc((#{$i} - 1) * .03s);
          }
        }
      }
    }
  }

  &__inner-item-wrap {
    opacity: 0;
    transform: translate(0, 100%);
    transition: opacity, transform;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__menu-accordion-link-wrap {
    //overflow: hidden;
    display: flex;
  }

  &__inner-list {
    padding-right: 35px;
    display: flex;
    row-gap: 20px;
    column-gap: 24px;
    flex-wrap: wrap;
    margin-top: -5px;
    padding-bottom: 35px;
  }

  &__menu-accordion-list {
    border-top: 1px solid var(--grey-lines);
    counter-reset: list;
    overflow: hidden;
  }

  &__menu-accordion-link-wrap-text {
    transform: translate(20px, 0);
    opacity: 0;
    transition: transform, opacity;
    transition-duration: var(--drop-menu-timing);
    transition-timing-function: var(--default-transition-function);

    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: uppercase;
  }

  &__menu-accordion-item {
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: var(--grey-lines);
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-100%, 0);
      transition: transform;
      transition-duration: var(--drop-menu-timing);
      transition-timing-function: var(--default-transition-function);
    }

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        &:before {
          transition-delay: calc(var(--drop-menu-timing) + #{$i} * .12s);
        }

        .drop-menu {
          &__menu-accordion-link {
            &:after {
              transition-delay: calc(var(--drop-menu-timing) + #{$i} * .12s);
            }
          }

          &__menu-accordion-link-wrap-text {
            transition-delay: calc(var(--drop-menu-timing) + #{$i} * .12s);
          }
        }
      }
    }

    &._opened {
      .drop-menu {
        &__inner-item-wrap {
          opacity: 1;
          transform: translate(0, 0);
        }

        &__menu-accordion-block {
          height: auto;
          opacity: 1;
        }

        &__menu-accordion-link {
          &:after {
            border-width: 0 5px 7px 5px;
            border-color: transparent transparent #000000 transparent;
            //transition-delay: unset;
          }
        }

        &__inner-list {
          pointer-events: auto;
        }
      }
    }

    &._opened,
    &._closing {
      .drop-menu {
        &__menu-accordion-link {
          &:after {
            transition-delay: unset;
          }
        }
      }
    }
  }

  &__inner-list {
    pointer-events: none;
  }

  &__small-menu-link {
    display: flex;
    transform: translate(0, -100%);
    transition: transform;
    transition-duration: var(--drop-menu-timing);
    transition-timing-function: var(--default-transition-function);
  }

  &__small-menu-item {
    overflow: hidden;

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        .drop-menu {
          &__small-menu-link {
            transition-delay: calc(var(--drop-menu-timing) + #{$i} * .1s);
          }
        }
      }
    }
  }

  &__menu-accordion-block {
    height: 0;
    display: block;
    opacity: 0;
    transition: height, opacity;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--header-timing-function);
  }

  &__menu-accordion-link {
    overflow: hidden;
    display: flex;
    padding: 35px 35px 35px 0;

    position: relative;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 5px 0 5px;
      border-color: #000000 transparent transparent transparent;
      opacity: 0;
      transition: border, opacity;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--header-timing-function);
    }
  }

  &__wrapper {
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__contacts-list {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
  }

  &__menu {
    display: grid;
    position: relative;
  }

  &__menu-images {
    position: relative;
    height: inherit;
    width: inherit;

    &._hide {
      height: 0;
      transition: height .3s var(--default-transition-function);
    }
  }

  &__menu-images-wrapper {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: calc(var(--grid-column7) + var(--grid-gap));
    width: var(--grid-column3);
    height: calc(192 / 258 * var(--grid-column3));
  }

  &__picture {
    height: calc(192 / 258 * var(--grid-column3));
  }

  &__share-block {
    overflow: hidden;
  }

  &__share-block-wrap {
    transform: translate(0, 100%);
    opacity: 0;
    transition: opacity, transform;
    transition-delay: calc(var(--drop-menu-timing) * 2);
    transition-duration: var(--default-timing);
    transition-timing-function: var(--header-timing-function);
  }
}

@include respond-up('s-large') {
  .drop-menu {
    &__menu-accordion-link-wrap-text {
      font-size: 35px;
    }
  }
}

@include respond-up('xs-large') {
  .drop-menu {
    &__inner-item-link,
    &__small-menu-link-text {
      transition: color;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--header-timing-function);
    }

    &__menu-images-wrapper {
      top: 85px;
    }

    &__menu-image-wrap {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 0;
      transition: height .6s var(--default-transition-function);
      overflow: hidden;

      &._active {
        animation: drop-menu-height-in .6s var(--default-transition-function) forwards;

        .drop-menu {
          &__picture {
            animation: drop-menu-picture-in .6s var(--default-transition-function);
          }
        }
      }

      &._visible {
        height: 100%;
      }
    }

    &__inner-item {
      &._shadow {
        .drop-menu__inner-item-link {
          color: var(--grey-font);
        }
      }
    }

    &__small-menu {
      padding-top: 35px;
    }

    &__small-menu-item {
      &._shadow {
        .drop-menu__small-menu-link-text {
          color: var(--grey-font);
        }
      }
    }

    &__menu-accordion-wrapper {
      grid-row: 1/3;
    }

    &__share-block {
      grid-column: 2/3;
    }

    &__menu {
      grid-template-columns: var(--grid-column6) var(--grid-column4);
      grid-template-rows: 1fr auto;
      height: calc(100vh - var(--header-height));
      padding-bottom: 60px;
      grid-column-gap: calc(var(--grid-column5) + 2 * var(--grid-gap));
    }
  }
}

@include respond-between('medium', 'xs-large') {
  .drop-menu {
    &__menu-accordion-link-wrap-text {
      font-size: 30px;
    }
  }
}

@include respond-up('medium') {
  .drop-menu {
    &__share-block {
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }

    &__small-menu-item {
      margin-bottom: 8px;
    }

    &__menu {
      padding-top: 50px;
    }

    &__socials {
      margin-top: 30px;
    }

    &__phone-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .drop-menu {
    &__menu-accordion-wrapper {
      grid-column: 1/3;
      margin-bottom: 90px;
    }

    &__menu {
      padding-bottom: 160px;
      grid-template-columns: var(--grid-column6) var(--grid-column4);
    }

    &__wrapper {
      column-gap: var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    &__menu-images-wrapper {
      display: none;
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &__small-menu {
      margin-top: 40px;
      margin-bottom: 35px;
    }

    &__small-menu-item {
      margin-bottom: 10px;
    }

    &__socials {
      margin-top: 20px;
    }

    &__menu {
      padding-top: 30px;
      padding-bottom: 18px;
    }

    &__menu-accordion-link {
      padding: 20px 25px 20px 0;
    }

    &__menu-accordion-link-wrap-text {
      font-size: 24px;
    }

    &__inner-list {
      padding-bottom: 25px;
      flex-direction: column;
      row-gap: 10px;
    }

    &__phone-wrap {
      transform: translate(0, 100%);
      opacity: 0;
      transition: opacity, transform;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--header-timing-function);
      transition-delay: calc(var(--drop-menu-timing) * 2);
    }

    &__share-block-wrap {
      padding-top: clamp(30px, 30vh, 30px);
      transition-delay: calc(var(--drop-menu-timing) * 2.5);
    }
  }
}

@keyframes drop-menu-height-in {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes drop-menu-picture-in {
  from {
    transform: scale3d(1.1, 1.1, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}