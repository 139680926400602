.popular-products {

}

@include respond-up('s-large') {
  .popular-products {
    padding-bottom: 120px;
  }
}

@include respond-between('medium', 'xs-large') {
  .popular-products {
    padding-bottom: 80px;
  }
}

@include respond-down('small') {
  .popular-products {
    padding-bottom: 50px;
  }
}