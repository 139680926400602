.index-certificate {
  background: var(--dark-background-2);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__parallax-container-inner {
    height: inherit;

    div {
      height: 100%;
      width: 100%;
    }
  }

  &__cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/image-icons/index/cover.png');
    height: inherit;
  }

  &__cover-wrapper {
    transform: rotate(15.94deg);
    z-index: 30;
    height: inherit;
    position: absolute;
  }

  &__card {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/image-icons/index/card.png');
  }

  &__card-wrapper {
    z-index: 20;
    position: absolute;
    height: inherit;
  }

  &__logo {
    opacity: .143;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: white;
      width: auto;
      height: 100%;
    }
  }

  &__logo-wrapper {
    z-index: 10;
    position: absolute;
  }

  &__wrapper {
    z-index: 40;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title,
  &__description {
    text-align: center;
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@include respond-up("x-large") {
  .index-certificate {
  }
}

@include respond-up("large") {
  .index-certificate {
  }
}

@include respond("large") {
  .index-certificate {
  }
}

@include respond-down("large") {
  .index-certificate {
  }
}

@include respond-up("s-large") {
  .index-certificate {
    margin-top: 160px;

    &__wrapper,
    &__description {
      width: var(--grid-column5);
    }

    &__cover-wrapper {
      width: 400px;
      left: -70px;
      top: -58px;
    }

    &__card-wrapper {
      width: 364px;
      bottom: -110px;
      right: -6px;
    }

    &__logo-wrapper {
      width: 851px;
      height: 610px;
      left: -240px;
      top: -130px;
    }
  }
}

@include respond("s-large") {
  .index-certificate {
  }
}

@include respond-down("s-large") {
  .index-certificate {
  }
}

@include respond-up("xs-large") {
  .index-certificate {
    height: 408px;

    &__title {
      margin-bottom: 22px;
    }

    &__description {
      margin-bottom: 40px;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .index-certificate {
    margin-top: 140px;

    &__cover-wrapper {
      width: 336px;
      left: -77px;
      top: -95px;
    }

    &__card-wrapper {
      width: 293px;
      bottom: -110px;
      right: -20px;
    }

    &__logo-wrapper {
      width: 851px;
      height: 610px;
      left: -295px;
      top: -130px;
    }

    &__wrapper,
    &__description {
      width: var(--grid-column7);
    }
  }
}

@include respond-down("xs-large") {
  .index-certificate {
  }
}

@include respond-up("medium") {
  .index-certificate {
  }
}

@include respond("medium") {
  .index-certificate {
    margin-top: 90px;
    height: 369px;

    &__cover-wrapper {
      width: 275px;
      top: -110px;
      left: -70px;
    }

    &__card-wrapper {
      width: 236px;
      bottom: -120px;
      right: -20px;
    }

    &__logo-wrapper {
      width: 600px;
      height: 430px;
      left: -190px;
      top: -40px;
    }

    &__title {
      margin-bottom: 18px;
    }

    &__description {
      margin-bottom: 40px;
    }

    &__wrapper,
    &__description {
      width: var(--grid-column6);
    }
  }
}

@include respond-down("medium") {
  .index-certificate {
  }
}

@include respond-down("small") {
  .index-certificate {
    margin-top: 60px;
    height: 399px;

    &__cover-wrapper {
      width: 164px;
      left: -40px;
      top: -160px;
    }

    &__card-wrapper {
      width: 151px;
      right: -10px;
      bottom: -150px;
    }

    &__title {
      margin-bottom: 12px;
    }

    &__description {
      margin-bottom: 35px;
    }

    &__logo-wrapper {
      width: 420px;
      height: 300px;
      left: -233px;
      top: -3px;
    }

    &__wrapper,
    &__description {
      width: var(--grid-column9);
    }
  }
}
