.white-small-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey-background);
  border-radius: 50px;
  width: fit-content;

  &__text {
    font-family: var(--h-font);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #000;
    text-transform: lowercase;
  }
}

@include respond-up('medium') {
  .white-small-label {
    padding: 2px 6px;
  }
}

@include respond-down('small') {
  .white-small-label {
    padding: 1px 6px;
  }
}