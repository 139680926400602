.articles {
  &__list {
    border-top: 1px solid var(--grey-lines);
  }

  &__item {
    &.animation-wrap {
      overflow: visible;
    }
  }

  &__categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@include respond-up("x-large") {
  .articles {
  }
}

@include respond-up("large") {
  .articles {
  }
}

@include respond("large") {
  .articles {
  }
}

@include respond-down("large") {
  .articles {
  }
}

@include respond-up("s-large") {
  .articles {
  }
}

@include respond("s-large") {
  .articles {
  }
}

@include respond-down("s-large") {
  .articles {
  }
}

@include respond-up("xs-large") {
  .articles {
    padding-bottom: 20px;

    &__category {
      &:hover {
        .text__grey-font {
          color: black;
        }
      }
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .articles {
  }
}

@include respond-down("xs-large") {
  .articles {
  }
}

@include respond-up("medium") {
  .articles {
    &__category {
      margin-left: 22px;
    }

    &__categories {
      margin-left: -22px;
    }

    &__list {
      margin-top: 25px;
    }
  }
}

@include respond("medium") {
  .articles {
  }
}

@include respond-down("medium") {
  .articles {
    padding-bottom: 10px;
  }
}

@include respond-down("small") {
  .articles {
    &__category {
      margin-left: 18px;
    }

    &__categories {
      margin-left: -18px;
    }

    &__list {
      margin-top: 15px;
    }
  }
}