.material-accordion-item {
  //border-bottom: 1px solid var(--grey-lines);
  position: relative;

  &__link-title {
    display: block;
    flex: 1 0 auto;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: var(--grey-lines);
  }

  &:last-child {
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--grey-lines);
    }
  }

  &._opened {
    .material-accordion-item {
      &__link-description {
        height: auto;
        opacity: 1;
        transition-delay: 0s;
      }

      &__link-icon {
        &::after {
          opacity: 0;
        }
      }
    }
  }

  &__link {
    overflow: hidden;
    grid-row-gap: 0;
  }

  &__link-picture-wrap {
    display: flex;
    height: max-content;
  }

  &__link-picture {
    transition: width, height;
    transition-duration: .4s;
    transition-timing-function: var(--default-transition-function);
    display: flex;
  }

  &__link-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link-texts {
    display: flex;
    flex-direction: column;
  }

  &__link-texts-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link-icon {
    position: relative;
    border: 1px solid var(--grey-lines);
    border-radius: 100%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      background-color: var(--primary-color);
    }

    &::before {
      transform: translate3d(-50%, -50%, 0);
    }

    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }

  &__link-description {
    color: var(--grey-font);
    height: 0;
    opacity: 0;
    transition: opacity .5s cubic-bezier(.4, .6, .2, 1), margin-top 0s .5s;
  }

  &__content {
    height: 0;
    overflow: hidden;
    transition: height .5s cubic-bezier(.4, .6, .2, 1);
  }

  &__values {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__value-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }

  &__value-picture {
    display: flex;
  }

  &__value-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__value-link {
    display: flex;
    flex-direction: column;
  }

  &__content-description {
    color: var(--grey-font);
  }

  &__link-icon {
    position: absolute;
    right: 0;
  }
}

@include respond-up('large') {
  .material-accordion-item {

  }
}

@include respond-up('s-large') {
  .material-accordion-item {
    min-height: 244px;

    &._opened {
      .material-accordion-item {
        &__link-description {
          margin-top: 49px;
        }
      }
    }

    &__link {
      padding: 35px 0;
    }

    &__link-texts {
      padding-top: 4vw;
    }

    &__link-description {
      width: var(--grid-column5);
    }

    &__content-inner {
      padding: 25px 0 100px;
    }

    &__values {
      grid-row-gap: 60px;
    }

    &__value-title {
      font-size: 15px;
      margin-top: 18px;
    }

  }
}

@include respond-up('xs-large') {
  .material-accordion-item {
    &._opened {
      .material-accordion-item {
        &__link-picture {
          width: var(--grid-column6);
          height: calc(var(--grid-column6) * (174 / 258));
        }
      }
    }

    &__link-picture-wrap {
      grid-column: 1/7;
    }

    &__link-picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * (174 / 258));
    }

    &__link-texts {
      grid-column: 8/16;
    }

    &__link-icon {
      width: 66px;
      height: 66px;

      &::before,
      &::after {
        width: 16px;
      }
    }

    &__content-description {
      display: none;
    }

    &__value-picture {
      width: var(--grid-column3);
      height: var(--grid-column3);
    }
  }
}

@include respond('xs-large') {
  .material-accordion-item {
    min-height: 177px;

    &._opened {
      .material-accordion-item {
        &__link-description {
          margin-top: 37px;
        }
      }
    }

    &__link {
      padding: 30px 0;
    }

    &__link-texts {
      padding-top: 4vw;
    }

    &__link-description {
      width: var(--grid-column6);
    }

    &__content-inner {
      padding: 30px 0 60px;
    }

    &__values {
      grid-row-gap: 50px;
    }

    &__value-title {
      font-size: 13px;
      margin-top: 15px;
    }
  }
}

@include respond-up('medium') {
  .material-accordion-item {
    &__values {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

@include respond('medium') {
  .material-accordion-item {
    min-height: 198px;

    &._opened {
      .material-accordion-item {
        &__link-picture {
          width: var(--grid-column4);
          height: calc(var(--grid-column4) * (174 / 258));
        }
      }
    }

    &__link {
      padding: 25px 0;
    }

    &__link-picture-wrap {
      grid-column: 1/5;
      grid-row: 1/2;
    }

    &__link-picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * (174 / 258));
    }

    &__link-texts {
      grid-column: 6/11;
      grid-row: 1/2;
      padding-top: 51px;
    }

    &__link-description {
      grid-column: 1/7;
      grid-row: 2/3;
    }

    &__content-inner {
      padding: 15px 0 50px;
    }

    &__values {
      grid-row-gap: 50px;
    }

    &__value-picture {
      width: var(--grid-column2);
      height: var(--grid-column2);
    }

    &__content-description {
      width: var(--grid-column6);
      margin-bottom: 40px;
    }

    &__link-icon {
      top: 72px;
    }
  }
}

@include respond-down('medium') {
  .material-accordion-item {
    &__link-icon {
      width: 46px;
      height: 46px;

      &::before,
      &::after {
        width: 12px;
      }
    }

    &__link-description {
      display: none;
    }

    &__value-title {
      font-size: 12px;
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .material-accordion-item {
    &__link {
      padding: 18px 0;
    }

    &__link-picture-wrap {
      grid-column: 1/6;
    }

    &__link-picture {
      width: var(--grid-column5);
      height: calc(var(--grid-column5) * (174 / 258));
    }

    &__link-texts {
      grid-column: 1/11;
      margin-top: 20px;
    }

    &__link-texts-top {
      align-items: flex-end;
    }

    &__link-description {
      grid-column: 1/11;
      margin-top: 30px;
    }

    &__content-inner {
      padding: 12px 0 40px;
    }

    &__values {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 22px;
    }

    &__value-picture {
      width: var(--grid-column5);
      height: var(--grid-column5);
    }

    &__content-description {
      margin-bottom: 30px;
    }
  }
}