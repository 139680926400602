.example-slider {
  overflow: hidden;

  &__picture {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrapper {
    display: flex;

    :nth-child(2) {
      flex: 0 1 auto;
    }
  }

  &__slider {
    &.swiper {
      overflow: visible;
    }
  }

  &__item {
    position: relative;

    &.swiper-slide {
      width: var(--grid-column9);
    }
  }

  &__item-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__list {
    &.swiper-wrapper {
      transition-timing-function: linear;
    }
  }
}

@include respond-up("s-large") {
  .example-slider {
    margin-top: 160px;

    &__text-wrapper {
      :nth-child(1) {
        flex: 0 0 var(--grid-column4);
      }
    }
  }
}

@include respond-up("xs-large") {
  .example-slider {

    &_wide {
      .example-slider {
        &__item {
          &.swiper-slide {
            width: var(--grid-wide-column8);
          }
        }

        &__picture {
          height: calc(334 / 475 * var(--grid-wide-column8));
        }
      }
    }

    &__list {
      margin-top: 40px;
    }

    &__picture {
      height: calc(580 / 829 * var(--grid-column9));
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .example-slider {
    margin-top: 140px;

    &__item {
      &.swiper-slide {
        width: var(--grid-column10);
      }
    }

    &__picture {
      height: calc(580 / 829 * var(--grid-column10));
    }

    &__text-wrapper {
      :nth-child(1) {
        flex: 0 0 var(--grid-column5);
      }
    }
  }
}

@include respond-up("medium") {
  .example-slider {
    &__text-wrapper {
      margin-top: 18px;
      flex-direction: row;
      justify-content: space-between;

      :nth-child(1) {
        margin-right: var(--grid-gap);
      }
    }
  }
}

@include respond("medium") {
  .example-slider {
    margin-top: 120px;

    &_wide {
      .example-slider {
        &__item {
          &.swiper-slide {
            width: var(--grid-wide-column6);
          }
        }

        &__picture {
          height: calc(334 / 475 * var(--grid-wide-column6));
        }
      }
    }

    &__list {
      margin-top: 30px;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column8);
      }
    }

    &__picture {
      height: calc(580 / 829 * var(--grid-column8));
    }

    &__text-wrapper {
      :nth-child(1) {
        flex: 0 0 var(--grid-column4);
      }
    }
  }
}

@include respond-down("small") {
  .example-slider {
    margin-top: 60px;

    &_wide {
      .example-slider {
        &__item {
          &.swiper-slide {
            width: var(--grid-wide-column8);
          }
        }

        &__picture {
          height: calc(334 / 475 * var(--grid-wide-column8));
        }
      }
    }

    &__list {
      margin-top: 18px;
    }

    &__picture {
      height: calc(580 / 829 * var(--grid-column9));
    }

    &__text-wrapper {
      margin-top: 15px;
      flex-direction: column;

      :nth-child(1) {
        width: var(--grid-column9);
      }

      :nth-child(2) {
        margin-top: 12px;
      }
    }
  }
}
