@include respond-up('xs-large') {
  .installment-hint {
    display: none;
  }
}

@include respond('medium') {
  .installment-hint {

    &__wrap {
      padding: 40px var(--grid-spacer-and-indent);
      align-items: center;
    }

    &__text {
      flex: 0 0 var(--grid-column9);
      font-size: 18px;
    }
  }
}

@include respond-down('medium') {
  .installment-hint {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background var(--default-timing) var(--default-transition-function);
    pointer-events: none;

    &._opened {
      background: rgba(0, 0, 0, .60);
      z-index: 4800;
      pointer-events: auto;

      .installment-hint {
        &__wrap {
          opacity: 1;
        }
      }
    }

    &__container {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      background: var(--light-background);
      width: 100%;
      transition: opacity var(--default-timing) var(--default-transition-function);
      opacity: 0;
    }

    &__text {
      font-family: var(--font);
      font-weight: 400;
      line-height: 145%;
      color: #000;
    }

    &__closer {
      background-image: url('../images/svg/modal-closer-black.svg');
      position: static;
    }
  }
}

@include respond-down('small') {
  .installment-hint {

    &__wrap {
      padding: 30px var(--grid-spacer-and-indent);
    }

    &__text {
      flex: 0 0 var(--grid-column8);
      font-size: 15px;
    }
  }
}