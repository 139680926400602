.product-description {
  display: grid;
  overflow: hidden;

  &__block {
    &:nth-child(1) {
      .product-description {
        &__parallax-container {
          margin-left: calc(0px - var(--grid-spacer));
        }
      }
    }

    &:nth-child(4) {
      .product-description {
        &__parallax-container {
          margin-right: calc(0px - var(--grid-spacer));
        }
      }
    }
  }

  &__block-description {
    display: block;
    height: 0;
    opacity: 0;
    pointer-events: none;
    transition: height, opacity;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--header-timing-function);
  }

  &__element {
    &._opened {
      .product-description {
        &__block-description {
          opacity: 1;
        }

        &__block-title-cross {
          &:before,
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__block-title-cross {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;

    &:before,
    &:after {
      position: absolute;
      width: 16px;
      height: 2px;
      background: black;
      content: '';
      right: 0;
      top: 50%;
      transition: transform;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--header-timing-function);
    }

    &:before {
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &__block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: uppercase;
  }

  &__block-description {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("x-large") {
  .product-description {
  }
}

@include respond-up("large") {
  .product-description {
  }
}

@include respond("large") {
  .product-description {
  }
}

@include respond-down("large") {
  .product-description {
  }
}

@include respond-up("s-large") {
  .product-description {
    margin-top: 160px;
    grid-row-gap: 160px;
    width: fit-content;

    &__block-title {
      font-size: 20px;
    }
  }
}

@include respond("s-large") {
  .product-description {
  }
}

@include respond-down("s-large") {
  .product-description {
  }
}

@include respond-up("xs-large") {
  .product-description {
    grid-template-columns: repeat(2, var(--grid-column7));
    grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));

    &__block {
      &:nth-child(1),
      &:nth-child(4) {
        .product-description {
          &__parallax-container-inner,
          &__parallax-container {
            height: calc(590 / 738 * (var(--grid-column7) + var(--grid-spacer)));
          }

          &__picture {
            height: calc(679 / 738 * (var(--grid-column7) + var(--grid-spacer)));
          }
        }
      }

      &:nth-child(2) {
        padding-right: calc(var(--grid-column1) + var(--grid-gap));
      }

      &:nth-child(3) {
        padding-left: calc(var(--grid-column1) + var(--grid-gap));
      }

      &:nth-child(2),
      &:nth-child(3) {
        .product-description {
          &__parallax-container-inner,
          &__parallax-container {
            height: calc(460 / 543 * var(--grid-column6));
          }

          &__picture {
            height: calc(529 / 543 * var(--grid-column6));
          }
        }
      }
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .product-description {
    margin-top: 140px;
    grid-row-gap: 140px;
    grid-template-columns: repeat(2, 1fr);

    &__block-title {
      font-size: 18px;
    }
  }
}

@include respond-down("xs-large") {
  .product-description {
  }
}

@include respond-up("medium") {
  .product-description {
    &__block-title {
      padding-top: 22px;
      padding-bottom: 20px;
      margin-bottom: -20px;
    }

    &__block-description {
      font-size: 16px;

      > * {
        padding-top: 20px;
      }
    }
  }
}

@include respond("medium") {
  .product-description {
    margin-top: 120px;
    grid-row-gap: 120px;

    &__block-title-cross{
      margin-top: -5px;
    }

    &__block-title {
      font-size: 17px;
    }

    &__block {
      &:nth-child(1),
      &:nth-child(4) {
        .product-description {
          &__parallax-container-inner,
          &__parallax-container {
            height: calc(516 / 646 * (var(--grid-column8) + var(--grid-spacer)));
          }

          &__picture {
            height: calc(593 / 646 * (var(--grid-column8) + var(--grid-spacer)));
          }
        }
      }

      &:nth-child(1) {
        padding-right: calc(var(--grid-column2) + var(--grid-gap));
      }

      &:nth-child(2) {
        padding-left: calc(var(--grid-column3) + var(--grid-gap));
      }

      &:nth-child(3) {
        padding-right: calc(var(--grid-column3) + var(--grid-gap));
      }

      &:nth-child(4) {
        padding-left: calc(var(--grid-column2) + var(--grid-gap));
      }

      &:nth-child(2),
      &:nth-child(3) {
        .product-description {
          &__parallax-container-inner,
          &__parallax-container {
            height: calc(454 / 536 * var(--grid-column7));
          }

          &__picture {
            height: calc(522 / 536 * var(--grid-column7));
          }
        }
      }
    }
  }
}

@include respond-down("medium") {
  .product-description {
    grid-template-columns: var(--grid-columns-number);
    grid-column-gap: var(--grid-gap);

    &__block {
      grid-column: span 8; //temp
    }
  }
}

@include respond-down("small") {
  .product-description {
    margin-top: 60px;
    grid-row-gap: 50px;

    &__block-title-cross{
      margin-top: -7px;
    }

    &__block-title {
      padding-top: 18px;
      font-size: 16px;
      padding-bottom: 18px;
      margin-bottom: -18px;
    }

    &__block-description {
      font-size: 15px;

      > * {
        padding-top: 20px;
      }
    }

    &__block {
      &:nth-child(1),
      &:nth-child(4) {
        .product-description {
          &__parallax-container-inner,
          &__parallax-container {
            height: calc(255 / 320 * (var(--grid-column9) + var(--grid-spacer)));
          }

          &__picture {
            height: calc(293 / 320 * (var(--grid-column9) + var(--grid-spacer)));
          }
        }
      }

      &:nth-child(1),
      &:nth-child(3) {
        padding-right: calc(var(--grid-column1) + var(--grid-gap));
      }

      &:nth-child(2),
      &:nth-child(4) {
        padding-left: calc(var(--grid-column1) + var(--grid-gap));
      }

      &:nth-child(2),
      &:nth-child(3) {
        .product-description {
          &__parallax-container-inner,
          &__parallax-container {
            height: calc(255 / 300 * var(--grid-column9));
          }

          &__picture {
            height: calc(293 / 300 * var(--grid-column9));
          }
        }
      }
    }
  }
}
