.about-principles {
  &__wrapper {
    padding-top: var(--about-principles-padding-top);
    padding-bottom: var(--about-principles-padding-bottom);
  }

  &__inner-wrapper {
    display: flex; // да, флексы, выкусите
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }

  &__inner-wrapper_mobile,
  &__inner-blocks-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__block-description {
    color: var(--grey-font);
  }

  &__block-title {
    font-family: var(--font);
    font-style: italic;
    font-weight: 400;
    line-height: 135%;
    color: black;

    margin-top: var(--block-title-margin);
    margin-bottom: var(--block-title-margin);
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__block-title-line {
    flex: 1 0 auto;
    margin-left: 20px;
    height: 1px;
    background: var(--grey-lines);
  }

  &__block-subtitle {
    margin-bottom: var(--block-subtitle-margin);
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: uppercase;
    color: black;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("x-large") {
  .about-principles {
  }
}

@include respond-up("large") {
  .about-principles {
  }
}

@include respond("large") {
  .about-principles {
  }
}

@include respond-down("large") {
  .about-principles {
  }
}

@include respond-up("s-large") {
  .about-principles {
    --about-principles-padding-bottom: 120px;

    &__block-title {
      width: var(--grid-column3);
    }

    &__block-subtitle {
      font-size: 20px;
    }

    &__block-subtitle,
    &__block-description {
      width: var(--grid-column5);
    }

    &__inner-blocks-wrapper {
      &:nth-child(1) {
        flex: 0 0 var(--grid-column7);

        .about-principles {
          &__block {
            // 1
            &:nth-child(1) {
              .about-principles {
                &__image-wrap {
                  margin-left: calc(0px - var(--grid-spacer));
                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(509 / 738 * (var(--grid-column7) + var(--grid-indent)));
                }

                &__picture {
                  height: calc(585 / 738 * (var(--grid-column7) + var(--grid-indent)));
                }

                &__text-wrap {
                  padding: 0 calc(var(--grid-column1) + var(--grid-gap));
                }
              }
            }

            // 3
            &:nth-child(2) {
              margin-top: 150px;
              flex: 0 0 var(--grid-column7);
              padding-right: calc(var(--grid-column2) + 2 * var(--grid-gap));

              .about-principles {
                &__image-wrap {

                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(481 / 448 * var(--grid-column5));
                }

                &__picture {
                  height: calc(553 / 448 * var(--grid-column5));
                }

                &__text-wrap {
                  width: var(--grid-column5);
                }
              }
            }
          }
        }
      }

      &:nth-child(2) {
        flex: 0 0 var(--grid-column8);

        .about-principles {
          &__block {
            // 2
            &:nth-child(1) {
              margin-top: calc(var(--grid-column2) + var(--grid-gap));
              padding-left: calc(var(--grid-column2) + 2 * var(--grid-gap));
              padding-right: calc(var(--grid-column1) + 1 * var(--grid-gap));;

              .about-principles {
                &__image-wrap {
                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(479 / 448 * var(--grid-column5));
                }

                &__picture {
                  height: calc(551 / 448 * var(--grid-column5));
                }

                &__text-wrap {
                }
              }
            }

            // 4
            &:nth-child(2) {
              margin-top: 150px;
              grid-column: 8/16;
              flex: 0 0 var(--grid-column8);

              .about-principles {
                &__image-wrap {

                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(537 / 734 * var(--grid-column8));
                }

                &__picture {
                  height: calc(618 / 734 * var(--grid-column8));
                }

                &__text-wrap {
                  width: var(--grid-column5);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond("s-large") {
  .about-principles {
  }
}

@include respond-down("s-large") {
  .about-principles {
  }
}

@include respond-up("xs-large") {
  .about-principles {
    --about-principles-padding-top: 140px;
    --block-title-margin: 40px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .about-principles {
    --about-principles-padding-bottom: 80px;

    &__block-subtitle,
    &__block-description {
      width: var(--grid-column6);
    }

    &__block-title {
      width: var(--grid-column4);
    }

    &__block-subtitle {
      font-size: 18px;
    }

    &__inner-blocks-wrapper {
      &:nth-child(1) {
        flex: 0 0 var(--grid-column7);

        .about-principles {
          &__block {
            // 1
            &:nth-child(1) {
              .about-principles {
                &__image-wrap {
                  margin-left: calc(0px - var(--grid-spacer));
                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(346 / 502 * (var(--grid-column7)));
                }

                &__picture {
                  height: calc(398 / 502 * (var(--grid-column7)));
                }

                &__text-wrap {
                  padding-left: calc(var(--grid-column1) + var(--grid-gap));
                }
              }
            }

            // 3
            &:nth-child(2) {
              margin-top: 150px;
              flex: 0 0 var(--grid-column7);
              padding-right: calc(var(--grid-column1) + var(--grid-gap));

              .about-principles {
                &__image-wrap {

                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(375 / 386 * var(--grid-column6));
                }

                &__picture {
                  height: calc(431 / 386 * var(--grid-column6));
                }

                &__text-wrap {
                  width: var(--grid-column6);
                }
              }
            }
          }
        }
      }

      &:nth-child(2) {
        flex: 0 0 var(--grid-column8);

        .about-principles {
          &__block {
            // 2
            &:nth-child(1) {
              margin-top: calc(var(--grid-column2));
              padding-left: calc(var(--grid-column2) + 2 * var(--grid-gap));

              .about-principles {
                &__image-wrap {
                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(401 / 375 * var(--grid-column5));
                }

                &__picture {
                  height: calc(461 / 375 * var(--grid-column5));
                }

                &__text-wrap {
                }
              }
            }

            // 4
            &:nth-child(2) {
              margin-top: 150px;
              flex: 0 0 var(--grid-column8);
              padding-left: calc(var(--grid-column1) + var(--grid-gap));

              .about-principles {
                &__image-wrap {

                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(346 / 442 * var(--grid-column8));
                }

                &__picture {
                  height: calc(398 / 442 * var(--grid-column8));
                }

                &__text-wrap {
                  width: var(--grid-column5);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down("xs-large") {
  .about-principles {
  }
}

@include respond-up("medium") {
  .about-principles {
    --block-subtitle-margin: 25px;

    &__block-title {
      font-size: 15px;
    }

    &__inner-wrapper {
      display: flex;

      &_mobile {
        display: none;
      }
    }
  }
}

@include respond("medium") {
  .about-principles {
    --about-principles-padding-top: 120px;
    --about-principles-padding-bottom: 80px;
    --block-title-margin: 35px;

    &__block-subtitle,
    &__block-description {
      width: var(--grid-column4);
    }

    &__block-subtitle {
      font-size: 17px;
    }

    &__block-title {
      width: var(--grid-column3);
    }

    &__inner-blocks-wrapper {
      flex: 0 0 var(--grid-column5);

      &:nth-child(1) {
        .about-principles {
          &__block {
            // 1
            &:nth-child(1) {
              .about-principles {
                &__image-wrap {
                  margin-left: calc(0px - var(--grid-spacer));
                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(316 / 408 * (var(--grid-column5)));
                }

                &__picture {
                  height: calc(363 / 408 * (var(--grid-column5)));
                }

                &__text-wrap {
                  padding-right: calc(var(--grid-column1) + var(--grid-gap));
                }
              }
            }

            // 3
            &:nth-child(2) {
              margin-top: 150px;
              padding-right: calc(var(--grid-column1) + var(--grid-gap));

              .about-principles {
                &__image-wrap {

                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(317 / 297 * var(--grid-column4));
                }

                &__picture {
                  height: calc(365 / 297 * var(--grid-column4));
                }

                &__text-wrap {
                }
              }
            }
          }
        }
      }

      &:nth-child(2) {
        .about-principles {
          &__block {
            // 2
            &:nth-child(1) {
              margin-top: calc(var(--grid-column2) + 2 * var(--grid-gap));
              padding-left: calc(var(--grid-column1) + var(--grid-gap));

              .about-principles {
                &__image-wrap {
                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(300 / 297 * var(--grid-column4));
                }

                &__picture {
                  height: calc(345 / 297 * var(--grid-column4));
                }

                &__text-wrap {
                }
              }
            }

            // 4
            &:nth-child(2) {
              margin-top: 150px;

              .about-principles {
                &__image-wrap {

                }

                &__parallax-container-inner,
                &__parallax-container {
                  height: calc(378 / 316 * var(--grid-column5));
                }

                &__picture {
                  height: calc(435 / 316 * var(--grid-column5));
                }

                &__text-wrap {
                  width: var(--grid-column4);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down("medium") {
  .about-principles {
  }
}

@include respond-down("small") {
  .about-principles {
    --about-principles-padding-top: 60px;
    --about-principles-padding-bottom: 60px;
    --block-title-margin: 25px;
    --block-subtitle-margin: 18px;

    &__block-subtitle,
    &__block-description {
      width: var(--grid-column8);
    }

    &__block {
      &:not(:nth-child(1)) {
        margin-top: 60px;
      }

      &:nth-child(1) {
        padding-right: calc(var(--grid-column1) + var(--grid-gap));

        .about-principles {
          &__image-wrap {
            margin-left: calc(0px - var(--grid-spacer));
          }

          &__parallax-container-inner,
          &__parallax-container {
            height: calc(249 / 312 * (var(--grid-column9) + var(--grid-spacer)));
          }

          &__picture {
            height: calc(286 / 312 * (var(--grid-column9) + var(--grid-spacer)));
          }

          &__text-wrap {
            padding-right: calc(var(--grid-column1) + var(--grid-gap));
          }
        }
      }

      &:nth-child(2) {
        padding-left: calc(var(--grid-column2) + var(--grid-gap));

        .about-principles {
          &__image-wrap {

          }

          &__parallax-container-inner,
          &__parallax-container {
            height: calc(268 / 266 * (var(--grid-column8)));
          }

          &__picture {
            height: calc(308 / 266 * (var(--grid-column8)));
          }

          &__text-wrap {

          }
        }
      }

      &:nth-child(3) {
        padding-right: calc(var(--grid-column2) + var(--grid-gap));

        .about-principles {
          &__image-wrap {

          }

          &__parallax-container-inner,
          &__parallax-container {
            height: calc(283 / 265 * (var(--grid-column8)));
          }

          &__picture {
            height: calc(325 / 265 * (var(--grid-column8)));
          }

          &__text-wrap {
          }
        }
      }

      &:nth-child(4) {
        padding-left: calc(var(--grid-column1) + var(--grid-gap));

        .about-principles {
          &__image-wrap {

          }

          &__parallax-container-inner,
          &__parallax-container {
            height: calc(248 / 300 * (var(--grid-column9)));
          }

          &__picture {
            height: calc(285 / 300 * (var(--grid-column9)));
          }

          &__text-wrap {
            padding-left: calc(var(--grid-column1) + var(--grid-gap));
          }
        }
      }
    }

    &__inner-wrapper {
      display: none;
    }

    &__block-title {
      font-size: 14px;
      width: var(--grid-column6);
    }

    &__block-subtitle {
      font-size: 16px;
    }
  }
}
