.product-order-sticky {
  display: flex;
  align-items: flex-end;

  position: fixed;
  z-index: 4600;
  left: 0;
  right: 0;
  background-color: var(--light-background);
  padding: 0 var(--grid-spacer);
  transition: height .5s cubic-bezier(.65,0,.25,1), transform .5s cubic-bezier(.65,0,.25,1);
  will-change: transform;

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__main {
    display: flex;
  }

  &__title,
  &__config,
  &__order {
    display: flex;
    align-items: center;
  }

  &__title,
  &__config-title,
  &__order {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
  }

  &__title,
  &__order {
    text-transform: uppercase;
  }

  &__config-title {
    margin-left: 15px;
  }
}

@include respond-up('xs-large') {
  .product-order-sticky {
    background-color: var(--dark-background-2);

    &__config-icon {
      path {
        transition: stroke .3s var(--default-transition-function);
      }
    }

    &__config {
      margin-left: 60px;

      &:hover {
        .product-order-sticky__config-icon {
          path {
            stroke: var(--primary-color);
          }
        }
      }
    }

    &__order-title {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: var(--text-and-border-color-by-theme);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .65s cubic-bezier(.215,.61,.355,1);
        z-index: 20;
      }
    }

    &__order {
      &:hover {
        .product-order-sticky__order-title {
          &::before {
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .4s var(--teaser-transition-func);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .product-order-sticky {
    height: 61px;
    top: 0;
    transform: translate3d(0, -100%, 0);

    &._sticky {
      transform: translate3d(0, 0, 0);

      &._up {
        height: calc(61px + var(--header-height));
      }
    }

    &__inner {
      height: 61px;
    }

    &__order-pipe {
      margin: 0 25px;
    }
  }
}

@include respond('medium') {
  .product-order-sticky {
    &__config {
      margin-left: 30px;
    }
  }
}

@include respond-down('small') {
  .product-order-sticky {
    height: 52px;
    bottom: 0;
    transform: translate3d(0, 100%, 0);

    &._sticky {
      transform: translate3d(0, 0, 0);
    }

    &__inner {
      height: 52px;
      padding: 8px var(--grid-spacer);
    }

    &__main {
      display: none;
    }

    &__order {
      justify-content: center;
      font-size: 12px;
      background-color: black;
      color: #fff;
      width: 100%;
    }

    &__order-pipe {
      margin: 0 20px;
    }
  }
}