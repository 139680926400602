.svg-animation-wrap {
  &.need-animation,
  &._closed {
    .svg-animation-item00 {
      svg .svg-elem-1 {
        stroke-dashoffset: 12.370522499084473px;
      }

      svg .svg-elem-2 {
        stroke-dashoffset: 16.903355598449707px;
      }

      svg .svg-elem-3 {
        stroke-dashoffset: 228.11630249023438px;
      }

      svg .svg-elem-4 {
        stroke-dashoffset: 349.40875244140625px;
      }

      svg .svg-elem-5 {
        stroke-dashoffset: 107.36183166503906px;
      }

      svg .svg-elem-6 {
        stroke-dashoffset: 11.972298622131348px;
      }

      svg .svg-elem-7 {
        stroke-dashoffset: 39.69911184307752px;
      }

      svg .svg-elem-8 {
        stroke-dashoffset: 8.283185307179586px;
      }

      svg .svg-elem-9 {
        stroke-dashoffset: 8.283185307179586px;
      }

      svg .svg-elem-10 {
        stroke-dashoffset: 8.283185307179586px;
      }

      svg .svg-elem-11 {
        stroke-dashoffset: 8.283185307179586px;
      }

      svg .svg-elem-12 {
        stroke-dashoffset: 8.283185307179586px;
      }

      svg .svg-elem-13 {
        stroke-dashoffset: 8.283185307179586px;
      }

      svg .svg-elem-14 {
        stroke-dashoffset: 8.283185307179586px;
      }
    }

    .svg-animation-item01 {
      svg .svg-elem-1 {
        stroke-dashoffset: 20.397289276123047px;
      }

      svg .svg-elem-2 {
        stroke-dashoffset: 20.397281646728516px;
      }

      svg .svg-elem-3 {
        stroke-dashoffset: 437.83349609375px;
      }

      svg .svg-elem-4 {
        stroke-dashoffset: 45.16716003417969px;
      }

      svg .svg-elem-5 {
        stroke-dashoffset: 45.16716003417969px;
      }

      svg .svg-elem-6 {
        stroke-dashoffset: 14.641098022460938px;
      }

      svg .svg-elem-7 {
        stroke-dashoffset: 14.641098022460938px;
      }

      svg .svg-elem-8 {
        stroke-dashoffset: 54.724395751953125px;
      }

      svg .svg-elem-9 {
        stroke-dashoffset: 54.724395751953125px;
      }
    }

    .svg-animation-item02 {
      svg .svg-elem-1 {
        stroke-dashoffset: 430.4522705078125px;
      }

      svg .svg-elem-2 {
        stroke-dashoffset: 84.76361083984375px;
      }

      svg .svg-elem-3 {
        stroke-dashoffset: 23.48059844970703px;
      }

      svg .svg-elem-4 {
        stroke-dashoffset: 23.48059844970703px;
      }

      svg .svg-elem-5 {
        stroke-dashoffset: 23.480804443359375px;
      }

      svg .svg-elem-6 {
        stroke-dashoffset: 23.480804443359375px;
      }

      svg .svg-elem-7 {
        stroke-dashoffset: 82.86738586425781px;
      }

      svg .svg-elem-8 {
        stroke-dashoffset: 158.1016387939453px;
      }

      svg .svg-elem-9 {
        stroke-dashoffset: 150.4035186767578px;
      }

      svg .svg-elem-10 {
        stroke-dashoffset: 23.48059844970703px;
      }

      svg .svg-elem-11 {
        stroke-dashoffset: 23.48059844970703px;
      }

      svg .svg-elem-12 {
        stroke-dashoffset: 30.615259517075703px;
      }
    }

    .svg-animation-item03 {
      svg .svg-elem-1 {
        stroke-dashoffset: 128.63750457763672px;
      }

      svg .svg-elem-2 {
        stroke-dashoffset: 295.0033264160156px;
      }

      svg .svg-elem-3 {
        stroke-dashoffset: 133.68594360351562px;
      }

      svg .svg-elem-4 {
        stroke-dashoffset: 21.465103149414062px;
      }

      svg .svg-elem-5 {
        stroke-dashoffset: 20.837200164794922px;
      }

      svg .svg-elem-6 {
        stroke-dashoffset: 23.348831176757812px;
      }

      svg .svg-elem-7 {
        stroke-dashoffset: 26.488399505615234px;
      }

      svg .svg-elem-8 {
        stroke-dashoffset: 50.3026237487793px;
      }
    }
  }

  .svg-animation-item00 {
    svg .svg-elem-1 {
      stroke-dasharray: 12.370522499084473px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    }

    svg .svg-elem-2 {
      stroke-dasharray: 16.903355598449707px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    }

    svg .svg-elem-3 {
      stroke-dasharray: 228.11630249023438px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
    }

    svg .svg-elem-4 {
      stroke-dasharray: 349.40875244140625px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
    }

    svg .svg-elem-5 {
      stroke-dasharray: 107.36183166503906px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
    }

    svg .svg-elem-6 {
      stroke-dasharray: 11.972298622131348px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
    }

    svg .svg-elem-7 {
      stroke-dasharray: 39.69911184307752px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
    }

    svg .svg-elem-8 {
      stroke-dasharray: 8.283185307179586px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
    }

    svg .svg-elem-9 {
      stroke-dasharray: 8.283185307179586px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
    }

    svg .svg-elem-10 {
      stroke-dasharray: 8.283185307179586px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
    }

    svg .svg-elem-11 {
      stroke-dasharray: 8.283185307179586px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
    }

    svg .svg-elem-12 {
      stroke-dasharray: 8.283185307179586px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
    }

    svg .svg-elem-13 {
      stroke-dasharray: 8.283185307179586px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
    }

    svg .svg-elem-14 {
      stroke-dasharray: 8.283185307179586px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
    }
  }

  .svg-animation-item01 {
    svg .svg-elem-1 {
      stroke-dasharray: 20.397289276123047px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    }

    svg .svg-elem-2 {
      stroke-dasharray: 20.397281646728516px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    }

    svg .svg-elem-3 {
      stroke-dasharray: 437.83349609375px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
    }

    svg .svg-elem-4 {
      stroke-dasharray: 45.16716003417969px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
    }

    svg .svg-elem-5 {
      stroke-dasharray: 45.16716003417969px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
    }

    svg .svg-elem-6 {
      stroke-dasharray: 14.641098022460938px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
    }

    svg .svg-elem-7 {
      stroke-dasharray: 14.641098022460938px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
    }

    svg .svg-elem-8 {
      stroke-dasharray: 54.724395751953125px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
    }

    svg .svg-elem-9 {
      stroke-dasharray: 54.724395751953125px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
    }
  }

  .svg-animation-item02 {
    svg .svg-elem-1 {
      stroke-dasharray: 430.4522705078125px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    }

    svg .svg-elem-2 {
      stroke-dasharray: 84.76361083984375px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    }

    svg .svg-elem-3 {
      stroke-dasharray: 23.48059844970703px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
    }

    svg .svg-elem-4 {
      stroke-dasharray: 23.48059844970703px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
    }

    svg .svg-elem-5 {
      stroke-dasharray: 23.480804443359375px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
    }

    svg .svg-elem-6 {
      stroke-dasharray: 23.480804443359375px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
    }

    svg .svg-elem-7 {
      stroke-dasharray: 82.86738586425781px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
    }

    svg .svg-elem-8 {
      stroke-dasharray: 158.1016387939453px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
    }

    svg .svg-elem-9 {
      stroke-dasharray: 150.4035186767578px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
    }

    svg .svg-elem-10 {
      stroke-dasharray: 23.48059844970703px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
    }

    svg .svg-elem-11 {
      stroke-dasharray: 23.48059844970703px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
    }

    svg .svg-elem-12 {
      stroke-dasharray: 30.615259517075703px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
    }
  }

  .svg-animation-item03 {
    svg .svg-elem-1 {
      stroke-dasharray: 128.63750457763672px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    }

    svg .svg-elem-2 {
      stroke-dasharray: 295.0033264160156px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    }

    svg .svg-elem-3 {
      stroke-dasharray: 133.68594360351562px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
    }

    svg .svg-elem-4 {
      stroke-dasharray: 21.465103149414062px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
    }

    svg .svg-elem-5 {
      stroke-dasharray: 20.837200164794922px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
    }

    svg .svg-elem-6 {
      stroke-dasharray: 23.348831176757812px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
    }

    svg .svg-elem-7 {
      stroke-dasharray: 26.488399505615234px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
    }

    svg .svg-elem-8 {
      stroke-dasharray: 50.3026237487793px;
      transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
    }
  }

  &._opened {
    .svg-animation-item00 {
      svg .svg-elem-1 {
        stroke-dasharray: 12.370522499084473px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      }

      svg .svg-elem-2 {
        stroke-dasharray: 16.903355598449707px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
      }

      svg .svg-elem-3 {
        stroke-dasharray: 228.11630249023438px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
      }

      svg .svg-elem-4 {
        stroke-dasharray: 349.40875244140625px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
      }

      svg .svg-elem-5 {
        stroke-dasharray: 107.36183166503906px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
      }

      svg .svg-elem-6 {
        stroke-dasharray: 11.972298622131348px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
      }

      svg .svg-elem-7 {
        stroke-dasharray: 39.69911184307752px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
      }

      svg .svg-elem-8 {
        stroke-dasharray: 8.283185307179586px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
      }

      svg .svg-elem-9 {
        stroke-dasharray: 8.283185307179586px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
      }

      svg .svg-elem-10 {
        stroke-dasharray: 8.283185307179586px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
      }

      svg .svg-elem-11 {
        stroke-dasharray: 8.283185307179586px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
      }

      svg .svg-elem-12 {
        stroke-dasharray: 8.283185307179586px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
      }

      svg .svg-elem-13 {
        stroke-dasharray: 8.283185307179586px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
      }

      svg .svg-elem-14 {
        stroke-dasharray: 8.283185307179586px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
      }
    }

    .svg-animation-item01 {
      svg .svg-elem-1 {
        stroke-dasharray: 20.397289276123047px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      }

      svg .svg-elem-2 {
        stroke-dasharray: 20.397281646728516px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
      }

      svg .svg-elem-3 {
        stroke-dasharray: 437.83349609375px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
      }

      svg .svg-elem-4 {
        stroke-dasharray: 45.16716003417969px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
      }

      svg .svg-elem-5 {
        stroke-dasharray: 45.16716003417969px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
      }

      svg .svg-elem-6 {
        stroke-dasharray: 14.641098022460938px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
      }

      svg .svg-elem-7 {
        stroke-dasharray: 14.641098022460938px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
      }

      svg .svg-elem-8 {
        stroke-dasharray: 54.724395751953125px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
      }

      svg .svg-elem-9 {
        stroke-dasharray: 54.724395751953125px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
      }
    }

    .svg-animation-item02 {
      svg .svg-elem-1 {
        stroke-dasharray: 430.4522705078125px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      }

      svg .svg-elem-2 {
        stroke-dasharray: 84.76361083984375px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
      }

      svg .svg-elem-3 {
        stroke-dasharray: 23.48059844970703px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
      }

      svg .svg-elem-4 {
        stroke-dasharray: 23.48059844970703px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
      }

      svg .svg-elem-5 {
        stroke-dasharray: 23.480804443359375px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
      }

      svg .svg-elem-6 {
        stroke-dasharray: 23.480804443359375px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
      }

      svg .svg-elem-7 {
        stroke-dasharray: 82.86738586425781px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
      }

      svg .svg-elem-8 {
        stroke-dasharray: 158.1016387939453px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
      }

      svg .svg-elem-9 {
        stroke-dasharray: 150.4035186767578px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
      }

      svg .svg-elem-10 {
        stroke-dasharray: 23.48059844970703px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
      }

      svg .svg-elem-11 {
        stroke-dasharray: 23.48059844970703px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
      }

      svg .svg-elem-12 {
        stroke-dasharray: 30.615259517075703px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
      }
    }

    .svg-animation-item03 {
      svg .svg-elem-1 {
        stroke-dasharray: 128.63750457763672px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      }

      svg .svg-elem-2 {
        stroke-dasharray: 295.0033264160156px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
      }

      svg .svg-elem-3 {
        stroke-dasharray: 133.68594360351562px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
      }

      svg .svg-elem-4 {
        stroke-dasharray: 21.465103149414062px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
      }

      svg .svg-elem-5 {
        stroke-dasharray: 20.837200164794922px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
      }

      svg .svg-elem-6 {
        stroke-dasharray: 23.348831176757812px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
      }

      svg .svg-elem-7 {
        stroke-dasharray: 26.488399505615234px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
      }

      svg .svg-elem-8 {
        stroke-dasharray: 50.3026237487793px;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
      }
    }
  }
}