@include respond-up("s-large") {
  .to-partners-form {
    margin-top: 160px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .to-partners-form {
    margin-top: 140px;
  }
}

@include respond("medium") {
  .to-partners-form {
    margin-top: 120px;
  }
}

@include respond-down("small") {
  .to-partners-form {
    margin-top: 60px;
  }
}
