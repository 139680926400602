.hero-slider {
  position: relative;
  overflow: hidden;
  height: 100vh;

  &__marquee{
    position: absolute;
    z-index: 10;
    width: 100%;
  }

  &__parallax-container,
  &__parallax-container-inner {
    height: 100vh;
  }

  &__picture {
    position: absolute;
    display: flex;
    width: 100vw;
    height: 130vh;
    transition: transform 1.2s var(--default-bezier);
    transform: scale3d(1.2, 1.2, 1);

    &::before {
      content: '';
      position: absolute;
      z-index: 35;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--black-bg-opacity-05);
      mix-blend-mode: multiply;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list {
    width: 100%;
    position: relative;
    z-index: 15;
    overflow: hidden;
    height: 130vh;
    touch-action: pan-y;
  }

  &__item {
    position: absolute;
    display: block;
    height: 100%;
    z-index: 5;
    overflow: hidden;
    transition: transform 0s, width 0s;
    transition-timing-function: var(--default-bezier);

    &._active {
      z-index: 10;
      width: 100%;
      transform: translate3d(0, 0, 0);
      //transition-duration: 1.2s, 0s;

      .hero-slider {
        &__picture {
          transform: scale3d(1, 1, 1);
        }
      }
    }

    &._in-next-act {
      animation: hero-image-next 1.2s var(--default-bezier) forwards;

      .hero-slider {
        &__picture {
          &::before {
            animation: hero-black-out 1.2s var(--default-bezier) forwards;
          }
        }
      }
    }

    &._out-next-act {
      z-index: 15;
      width: 0;
      transform: translate3d(0, 0, 0);
      transition-duration: 0s, 1.2s;
      left: 0;

      .hero-slider {
        &__picture {
          left: 0;
          transform: translate3d(-40%, 0, 0) scale3d(1.2, 1.2, 1);
        }
      }
    }

    &._in-prev-act {
      animation: hero-image-prev 1.2s var(--default-bezier) forwards;

      .hero-slider {
        &__picture {
          &::before {
            animation: hero-black-out 1.2s var(--default-bezier) forwards;
          }
        }
      }
    }

    &._out-prev-act {
      z-index: 15;
      width: 0;
      transform: translate3d(0, 0, 0);
      transition-duration: 0s, 1.2s;
      right: 0;

      .hero-slider {
        &__picture {
          right: 0;
          transform: translate3d(40%, 0, 0) scale3d(1.2, 1.2, 1);
        }
      }
    }
  }

  &__content-wrap {
    position: absolute;
    bottom: 0;
    z-index: 25;
    width: 100%;
    pointer-events: none;
  }

  &__content {
    grid-row-gap: 0;
  }

  &__title-slider {
    position: relative;
    grid-column: 1/11;

    &::after {
      position: absolute;
      content: '';
      width: 1px;
      height: 60px;
      background: white;
      bottom: 0;
      animation: hero-line 2s cubic-bezier(0.79, 0, 0.15, 1) infinite;
    }
  }

  &__title {
    transition: opacity, transform;
    transition-duration: .33s;
    transition-timing-function: var(--default-bezier);
    color: #fff;
  }

  &__title-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    max-width: var(--grid-column10);

    span {
      transform: translate3d(0, 100%, 0);
      transition: transform .65s var(--default-bezier);
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc(#{$i} * .1s);
        }
      }
    }
  }

  &__title-slide {
    position: absolute;
    left: 0;

    &._active {
      .hero-slider {
        &__title-inner {
          span {
            transform: translate3d(0, 0, 0);
          }
        }

        &__description {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: .5s;
        }

        &__link {
          pointer-events: auto;
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: .6s;
        }
      }
    }

    &._out-next-act,
    &._out-prev-act {
      .hero-slider {
        &__title {
          opacity: 0;
          transform: translate3d(0, 10px, 0);
        }

        &__description {
          opacity: 0;
          transform: translate3d(0, 10px, 0);
          transition-duration: .33s;
        }

        &__link {
          opacity: 0;
          transform: translate3d(0, 10px, 0);
          transition-duration: .33s;
        }
      }
    }
  }

  &__description,
  &__link {
    transition: opacity, transform;
    transition-duration: .65s;
    transition-timing-function: var(--default-bezier);
    color: #fff;
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  &__link {
    .button__more-plus-wrap {
      &::before,
      &::after {
        background: #fff;
      }
    }
  }

  &__pagination-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__pagination {
    display: flex;
    align-items: center;
    position: relative;
    height: 16px;
    overflow: hidden;
  }

  &__pagination-slider {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 16px;
    margin-top: 2px;
  }

  &__pagination-total {
    margin-top: 2px;
  }

  &__pagination-slide,
  &__pagination-total {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    text-transform: uppercase;
    color: #fff;
  }

  &__pagination-slide {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 100%, 0);

    &._active {
      transform: translate3d(0, 0, 0);
      transition-delay: .6s;
    }

    &._in-next-act {
      animation: hero-pagination-next-in .6s var(--default-bezier) forwards;
    }

    &._in-prev-act {
      animation: hero-pagination-prev-in .6s var(--default-bezier) forwards;
    }

    &._out-next-act {
      animation: hero-pagination-next-out .6s var(--default-bezier) forwards;
    }

    &._out-prev-act {
      animation: hero-pagination-prev-out .6s var(--default-bezier) forwards;
    }
  }

  &__pagination-dash {
    height: 1px;
    width: 35px;
    background: #fff;
    margin: 0 12px;
  }
}

@include respond-up('xs-large') {
  .hero-slider {
    &__marquee{
      top: 136px;
      height: 70px;
    }

    &__title-slider {
      &::after {
        left: calc(var(--grid-column5) + var(--grid-gap));
      }
    }

    &__title-slide {
      padding-bottom: 100px;
    }

    &__arrows-desktop {
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
    }

    &__arrow-desktop {
      flex: 0 0 50%;
    }

    &__pagination-wrap {
      bottom: 100px;
    }

    &__link {
      margin-top: 40px;
    }

    &__arrows-mobile {
      display: none;
    }

    &__description-wrap {
      padding-left: calc(var(--grid-column5) + var(--grid-gap));
    }
  }
}

@include respond-up('medium') {
  .hero-slider {
    &__item {
      &_mobile {
        display: none;
      }
    }

    &__title-slide {
      bottom: 0;
    }

    &__pagination-wrap {
      position: absolute;
      left: var(--grid-spacer);
    }

    &__description-wrap {
      margin-top: 80px;
    }
  }
}

@include respond('medium') {
  .hero-slider {
    &__marquee{
      top: 114px;
      height: 58px;
    }

    &__title-slider {
      grid-column: 1/9;

      &::after {
        left: calc(var(--grid-column3) + var(--grid-gap));
      }
    }

    &__title-slide {
      padding-bottom: 110px;
    }

    &__title-inner {
      max-width: var(--grid-column8);
    }

    &__pagination-wrap {
      bottom: 110px;
    }

    &__link {
      margin-top: 35px;
    }

    &__arrows-mobile {
      position: absolute;
      right: var(--grid-spacer);
      bottom: 110px;

      .arrow_next {
        margin-left: 40px;
      }
    }

    &__description-wrap {
      padding-left: calc(var(--grid-column3) + var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .hero-slider {
    &__arrows-desktop {
      display: none;
    }

    &__arrows-mobile {
      pointer-events: auto;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

@include respond-down('small') {
  .hero-slider {
    &__marquee{
      top: 80px;
      height: 33px;
    }

    &__item {
      &_desktop {
        display: none;
      }
    }

    &__title-slider {
      grid-row: 2;
      margin-top: 50px;

      &::after {
        left: calc(var(--grid-column2) + var(--grid-gap));
      }
    }

    &__title-slide {
      top: 0;
      padding-bottom: 80px;
    }

    &__link {
      margin-top: 45px;
    }

    &__pagination-wrap {
      align-items: center;
      grid-column: 1/4;
      grid-row: 1;
    }

    &__arrows-mobile {
      grid-column: 8/11;
      grid-row: 1;

      .arrow_next {
        margin-left: 30px;
      }
    }

    &__description-wrap {
      padding-left: calc(var(--grid-column2) + var(--grid-gap));
      margin-top: 40px;
    }
  }
}

@keyframes hero-image-next {
  from {
    transform: translate3d(50%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-image-prev {
  from {
    transform: translate3d(-50%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-black-out {
  from {
    background: var(--black-bg-opacity-80);
  }
  17% {
    background: var(--black-bg-opacity-80);
  }
  to {
    background: var(--black-bg-opacity-05);
  }
}

@keyframes hero-pagination-next-in {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-pagination-next-out {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes hero-pagination-prev-in {
  from {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-pagination-prev-out {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 100%, 0);
  }
}