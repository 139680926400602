.collections {
  &__item {
    &.animation-wrap {
      overflow: visible;
    }
  }

  &__text-wrap {
    display: flex;
    column-gap: var(--grid-gap);
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
  }

  &__list {
    display: flex;
    flex-direction: column;
    grid-row: 1;
    grid-column: 1/calc(var(--grid-columns-number) + 1);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .collections {
    &__text-wrap {
      grid-column: 1/8;
      padding-top: 40px;
    }

    &__parallax-container {
      grid-column: 9/16;
    }

    &__info-wrap {
      flex: 0 0 var(--grid-column6);
      padding-top: 18px;
    }

    &__link {
      margin-top: 50px;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(380 / 738 * (var(--grid-column7) + var(--grid-spacer-and-indent)));
    }

    &__picture {
      height: calc(548 / 738 * (var(--grid-column7) + var(--grid-spacer-and-indent)));
    }

    &__list {
      row-gap: 70px;
    }

    &__text {
      grid-column: 7/14;
    }
  }
}

@include respond-up('xs-large') {
  .collections {
    &.grid-block {
      grid-row-gap: 140px;
    }

    &__text-wrap {
      border-top: 1px solid var(--grey-lines);
    }

    &__description {
      margin-top: 18px;
    }

    &__parallax-container {
      width: calc(var(--grid-column7) + var(--grid-spacer-and-indent));
    }

    &__text {
      row-gap: 60px;
    }
  }
}

@include respond('xs-large') {
  .collections {
    &__link {
      margin-top: 40px;
    }

    &__text-wrap {
      padding-top: 30px;
      grid-column: 1/9;
    }

    &__info-wrap {
      padding-top: 18px;
    }

    &__parallax-container {
      grid-column: 9/16;
    }

    &__list {
      row-gap: 60px;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(260 / 502 * (var(--grid-column7) + var(--grid-spacer-and-indent)));
    }

    &__picture {
      height: calc(375 / 502 * (var(--grid-column7) + var(--grid-spacer-and-indent)));
    }

    &__text {
      grid-column: 7/16;
    }
  }
}

@include respond-up('medium') {
  .collections {
    padding-bottom: 20px;
  }
}

@include respond('medium') {
  .collections {
    &.grid-block {
      grid-row-gap: 100px;
    }

    &__link {
      margin-top: 30px;
    }

    &__list {
      row-gap: 50px;
    }

    &__item-link {
      padding-top: 30px;

      &.grid-block {
        grid-row-gap: 22px;
      }
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(380 / 774 * var(--grid-column10));
    }

    &__picture {
      height: calc(548 / 774 * var(--grid-column10));
    }

    &__description {
      margin-top: 15px;
    }

    &__info-wrap {
      padding-top: 15px;
    }

    &__text {
      row-gap: 56px;
      grid-column: 3/11;
    }

    &__text-wrap {
      grid-column: 2/9;
    }
  }
}

@include respond-down('medium') {
  .collections {
    &__parallax-container {
      grid-row: 1;
      grid-column: 1/11;
    }

    &__text-wrap {
      grid-row: 2;
    }

    &__item-link {
      border-top: 1px solid var(--grey-lines);
    }
  }
}

@include respond-down('small') {
  .collections {
    padding-bottom: 10px;

    &.grid-block {
      grid-row-gap: 50px;
    }

    &__text-wrap {
      grid-column: 1/11;
    }

    &__link {
      margin-top: 20px;
    }

    &__list {
      row-gap: 35px;
    }

    &__item-link {
      padding-top: 20px;

      &.grid-block {
        grid-row-gap: 10px;
      }
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(172 / 334 * var(--grid-column10));
    }

    &__picture {
      height: calc(248 / 334 * var(--grid-column10));
    }

    &__description {
      margin-top: 10px;
    }

    &__info-wrap {
      padding-top: 10px;
    }

    &__text {
      row-gap: 40px;
      grid-column: 1/11;
    }
  }
}