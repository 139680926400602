.cart-comment {
  &__comment {
    resize: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
    color: black;
    background: var(--dark-background);
  }

  &__label {
    top: 20px;

    &.smart-label {
      &__primary {
        transform: unset;
      }

      &__secondary {
        transform: translate3d(60%, 0, 0);
      }
    }
  }

  &__field {
    .smart-label {
      &._focus {
        .smart-label__secondary {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

@include respond-up('xs-large') {
  .cart-comment {
    margin-top: 68px;
  }
}

@include respond-up('medium') {
  .cart-comment {
    &__comment {
      height: 124px;
      font-size: 16px;
      padding: 20px 22px;
    }
  }
}

@include respond('medium') {
  .cart-comment {
    margin-top: 53px;
  }
}

@include respond-down('small') {
  .cart-comment {
    margin-top: 45px;

    &__comment {
      height: 114px;
      font-size: 15px;
      padding: 20px 18px;
    }
  }
}