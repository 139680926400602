.product-config-block {
  &__picture {
    display: flex;
    align-items: center;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__slider-main-container {
    position: relative;
  }

  &__arrow {
    position: absolute;
    z-index: 50;
    top: 0;
    width: 50%;
    height: 100%;

    &_prev {
      left: 0;
    }

    &_next {
      right: 0;
    }
  }

  &__slider-thumb-item {
    &.swiper-slide {
      width: 67px
    }
  }

  &__image-wrap {
    position: relative;
  }

  &__tabs-wrap {
    position: absolute;
    z-index: 55;
  }
}

@include respond-up('s-large') {
  .product-config-block {
    &__image-wrap {
      grid-column: 1/9;
    }

    &__config-wrap {
      grid-column: 10/16;
    }

    &__iframe-wrap,
    &__picture {
      width: var(--grid-column8);
      height: var(--grid-column8);
    }

    &__picture {
      &_thumb {
        width: 67px;
        height: 67px;
      }
    }

    &__tabs-wrap {
      top: 30px;
      right: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .product-config-block {
    &__slider-thumb {
      margin-top: 30px;
    }

    &__pagination {
      &.swiper-pagination {
        display: none;
      }
    }

    &__slider-thumb-item {
      position: relative;
      z-index: 100;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: black;
        transition: opacity var(--default-timing) var(--default-transition-function);
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }

      &.swiper-slide-thumb-active {
        &:after {
          opacity: 1;
        }
      }
    }

    &__picture {
      &_thumb {
        pointer-events: none;
      }
    }
  }
}

@include respond('xs-large') {
  .product-config-block {
    &__image-wrap {
      grid-column: 1/8;
    }

    &__config-wrap {
      grid-column: 9/16;

      &.animation-wrap {
        overflow: visible;
      }
    }

    &__iframe-wrap,
    &__picture {
      width: var(--grid-column7);
      height: var(--grid-column7);
    }

    &__picture {
      &_thumb {
        width: 67px;
        height: 67px;
      }
    }

    &__tabs-wrap {
      top: 10px;
      right: 10px;
    }
  }
}

@include respond-up('medium') {
  .product-config-block {
    &__config {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .product-config-block {
    &.grid-block {
      row-gap: 37px;
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: -35px;
      }
    }

    &__config-wrap {
      margin-top: 60px;
    }

    &__tabs-wrap {
      bottom: 25px;
    }
  }
}

@include respond-down('medium') {
  .product-config-block {
    &__sliders-wrap,
    &__iframe-wrap {
      margin: 0 calc(var(--grid-spacer) * -1);
    }

    &__config-wrap,
    &__image-wrap {
      grid-column: 1/11;
    }

    &__iframe-wrap,
    &__picture {
      width: calc(var(--grid-column10) + var(--grid-spacer) * 2);
      height: calc(var(--grid-column10) + var(--grid-spacer) * 2);
    }

    &__slider-thumb,
    &__arrow {
      display: none;
    }

    &__tabs-wrap {
      left: 0;
    }
  }
}

@include respond-down('small') {
  .product-config-block {
    &.grid-block {
      row-gap: 20px;
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: -18px;
      }
    }

    &__config-wrap {
      margin-top: 30px;
    }

    &__config {
      margin-top: 20px;
    }

    &__tabs-wrap {
      bottom: 12px;
    }
  }
}