.tabs {
  margin: 0 calc(var(--grid-spacer) * -1);
  padding: 0 var(--grid-spacer);
  overflow: scroll hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &__link {
    display: flex;
  }

  &__text {
    white-space: nowrap;
    font-family: var(--h-font);
    font-style: normal;
  }

  &__list {
    display: flex;
    width: 100%;
  }

  &__first {
    //Табы 1
    .tabs {
      &__text {
        font-weight: 500;
        line-height: 125%;
        color: var(--grey-font);
        transition: color .4s var(--default-transition-function);
      }

      &__item {
        &._active {
          .tabs {
            &__text {
              color: black;
            }
          }
        }
      }
    }
  }

  &__second {
    //Табы 2
    .tabs {
      &__text {
        font-weight: 500;
        line-height: 125%;
        color: var(--grey-font);
        transition: color .4s var(--default-transition-function);
      }

      &__item {
        position: relative;

        &:before {
          position: absolute;
          bottom: 5px;
          height: 1px;
          transition: background .4s var(--default-transition-function);
          content: '';
        }

        &._active {
          &:before {
            background: black;
          }

          .tabs {
            &__text {
              color: black;
            }
          }
        }
      }

      &__list {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          height: 1px;
          bottom: 5px;
          background: var(--grey-lines);
        }
      }
    }
  }

  &__third {
    //Табы 3
    .tabs {
      &__text {
        font-weight: 400;
        line-height: 125%;
      }

      &__link {
        padding: 10px 30px;
        background: var(--light-color-opacity-15);
        color: white;
        border-radius: 50px;
        margin: 5px;
        transition: background, color;
        transition-duration: .4s;
        transition-timing-function: var(--default-transition-function);
      }

      &__list {
        margin: -5px;
      }

      &__item {
        &._active {
          .tabs {
            &__link {
              background: white;
              color: black;
            }
          }
        }
      }
    }
  }
}

@include respond-up("xs-large") {
  .tabs {
    &__first,
    &__second {
      //Табы 1
      //Табы 2
      .tabs {
        &__link {
          &:hover {
            .tabs {
              &__text {
                color: black;
              }
            }
          }
        }
      }
    }

    &__third {
      .tabs {
        &__item {
          &:hover {
            .tabs {
              &__link {
                background: var(--light-color-opacity-58);
                color: black;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up("medium") {
  .tabs {
    &__first {
      //Табы 1
      .tabs {
        &__text {
          font-size: 14px;
        }

        &__link {
          padding: 5px 17.5px;
        }

        &__list {
          margin: -5px -17.5px;
        }
      }
    }

    &__second {
      //Табы 2
      .tabs {
        &__item {
          &:before {
            width: calc(100% - 35px);
            left: 17.5px;
          }
        }

        &__text {
          font-size: 16px;
        }

        &__link {
          padding: 5px 17.5px 25px;
        }

        &__list {
          margin: -5px -17.5px;
          width: calc(100% + 35px);

          &:before {
            width: calc(100% - 35px);
            left: 17.5px;
          }
        }
      }
    }

    &__third {
      //Табы 3
      .tabs {
        &__text {
          font-size: 16px;
        }
      }
    }
  }
}

@include respond-down("small") {
  .tabs {
    &__first {
      //Табы 1
      .tabs {
        &__text {
          font-size: 13px;
        }

        &__link {
          padding: 5px 10px;
        }

        &__list {
          margin: 0 -10px;
        }
      }
    }

    &__second {
      //Табы 2
      .tabs {
        &__item {
          &:before {
            width: calc(100% - 18px);
            left: 9px;
          }
        }

        &__text {
          font-size: 14px;
        }

        &__link {
          padding: 5px 9px 18px;
        }

        &__list {
          margin: -5px -9px;
          width: calc(100% + 18px);

          &:before {
            width: calc(100% - 18px);
            left: 9px;
          }
        }
      }
    }

    &__third {
      //Табы 3
      .tabs {
        &__text {
          font-size: 15px;
        }
      }
    }
  }
}
