.about-first-block {
  &__wrapper {
    padding-top: var(--about-first-block-top-padding);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__inner-wrapper {
    position: relative;
    grid-row-gap: 0;
  }
}

@include respond-up("x-large") {
  .about-first-block {
  }
}

@include respond-up("large") {
  .about-first-block {
  }
}

@include respond("large") {
  .about-first-block {
  }
}

@include respond-down("large") {
  .about-first-block {
  }
}

@include respond-up("s-large") {
  .about-first-block {
    &__main-description {
      grid-column: 1/12;
    }

    &__main-image-wrap {
      grid-column: 1/8;
      grid-row: 2/3;
      margin-top: 130px;

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(620 / 638 * var(--grid-column7));
        }

        &__picture {
          height: calc(713 / 638 * var(--grid-column7));
        }
      }
    }

    &__additional-image-wrap {
      position: absolute;
      right: 0;
      top: calc(var(--grid-column1) + 2 * var(--grid-gap));
      width: calc(var(--grid-column2) + var(--grid-spacer));

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(271 / 262 * (var(--grid-column2) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(312 / 262 * (var(--grid-column2) + var(--grid-spacer)));
        }
      }
    }

    &__additional-description {
      grid-column: 10/15;
      grid-row: 2/3;
      margin-top: calc(130px + var(--grid-column3) + var(--grid-gap));
    }
  }
}

@include respond("s-large") {
  .about-first-block {
  }
}

@include respond-down("s-large") {
  .about-first-block {
  }
}

@include respond-up("xs-large") {
  .about-first-block {
    --about-first-block-top-padding: 100px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .about-first-block {
    &__main-description {
      grid-column: 1/12;
    }

    &__main-image-wrap {
      grid-column: 1/9;
      grid-row: 2/3;
      margin-top: 140px;
      padding-right: var(--grid-column1);

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(457 / 470 * (var(--grid-column7) + var(--grid-gap)));
        }

        &__picture {
          height: calc(526 / 470 * (var(--grid-column7) + var(--grid-gap)));
        }
      }
    }

    &__additional-image-wrap {
      position: absolute;
      right: 0;
      top: calc(var(--grid-column3) + .75 * var(--grid-gap));
      width: calc(var(--grid-column2) + var(--grid-spacer));
      transform: translateY(-50%);

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(271 / 262 * (var(--grid-column2) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(312 / 262 * (var(--grid-column2) + var(--grid-spacer)));
        }
      }
    }

    &__additional-description {
      grid-column: 10/16;
      grid-row: 2/3;
      margin-top: calc(140px + var(--grid-column2) + var(--grid-gap) + var(--grid-column1) / 2);
    }
  }
}

@include respond-down("xs-large") {
  .about-first-block {
  }
}

@include respond-up("medium") {
  .about-first-block {
  }
}

@include respond("medium") {
  .about-first-block {
    --about-first-block-top-padding: 90px;

    &__main-description {
      grid-column: 1/10;
    }

    &__main-image-wrap {
      grid-column: 1/6;
      margin-top: 120px;
      grid-row: 2/4;

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(368 / 378 * var(--grid-column5));
        }

        &__picture {
          height: calc(423 / 378 * var(--grid-column5));
        }
      }
    }

    &__additional-image-wrap {
      grid-row: 2/3;
      grid-column: 9/11;
      margin-right: calc(0px - var(--grid-spacer));
      margin-top: 60px;

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(176 / 170 * (var(--grid-column2) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(202 / 170 * (var(--grid-column2) + var(--grid-spacer)));
        }
      }
    }

    &__additional-description {
      grid-column: 7/11;
      grid-row: 3/4;
      margin-top: 70px;
    }
  }
}

@include respond-down("medium") {
  .about-first-block {
  }
}

@include respond-down("small") {
  .about-first-block {
    --about-first-block-top-padding: 40px;

    &__main-description {
      grid-row: 1/2;
      grid-column: 1/11;
    }

    &__main-image-wrap {
      grid-row: 4/5;
      grid-column: 1/8;
      margin-left: calc(0px - var(--grid-spacer));
      margin-top: 40px;

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(230 / 252 * (var(--grid-column7) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(265 / 252 * (var(--grid-column7) + var(--grid-spacer)));
        }
      }
    }

    &__additional-image-wrap {
      grid-row: 2/3;
      grid-column: 7/11;
      margin-right: calc(0px - var(--grid-spacer));
      margin-top: 40px;

      .about-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(154 / 149 * (var(--grid-column4) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(177 / 149 * (var(--grid-column4) + var(--grid-spacer)));
        }
      }
    }

    &__additional-description {
      grid-row: 3/4;
      grid-column: 2/10;
      margin-top: 60px;
    }
  }
}
