.hero-block {
  position: relative;
  height: 100vh;

  &:after {
    z-index: 60;
    position: absolute;
    content: '';
    width: 1px;
    background: white;
    bottom: 0;
    left: 50%;
    animation: hero-line 2s cubic-bezier(0.79, 0, 0.15, 1) infinite;
  }

  &__heading {
    margin-top: var(--about-hero-heading-top-margin);
  }

  &__inner-wrapper {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    padding-top: var(--header-height);
    padding-bottom: var(--about-hero-padding-bottom);
  }

  &__text-wrapper {
    color: white;

    .mini-heading {
      color: white;

      &:before {
        background: white;
      }
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 70;
  }

  &__image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 40;
  }

  &__parallax-container,
  &__parallax-container-inner {
    height: 100vh;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 130vh;
    transition: transform 1.2s var(--default-bezier);
    transform: scale3d(1.2, 1.2, 1) translateY(-27vh);
    //transform: scale(1.2);
  }

  &.animate-out {
    .hero-block {
      &__picture {
        transform: scale3d(1, 1, 1) translateY(-27vh);
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("s-large") {
  .hero-block {
    --about-hero-heading-top-margin: 22px;
  }
}

@include respond-up("xs-large") {
  .hero-block {
    --about-hero-padding-bottom: 100px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .hero-block {
    --about-hero-heading-top-margin: 25px;
  }
}

@include respond-up("medium") {
  .hero-block {
    &:after {
      height: 60px;
    }
  }
}

@include respond("medium") {
  .hero-block {
    --about-hero-padding-bottom: 90px;
    --about-hero-heading-top-margin: 25px;
  }
}

@include respond-down("small") {
  .hero-block {
    &:after {
      height: 50px;
    }

    --about-hero-padding-bottom: 80px;
    --about-hero-heading-top-margin: 20px;
  }
}

@keyframes hero-line {
  0% {
    transform: scale(0);
    transform-origin: top center;
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    transform-origin: bottom center;
  }
}
