%text-cart-point {
  font-family: var(--h-font);
  font-weight: 500;
  line-height: 140%;
}

@include respond-up('medium') {
  %text-cart-point {
    font-size: 20px;
  }
}

@include respond-down('small') {
  %text-cart-point {
    font-size: 16px;
  }
}