.product-text-description {
  &__tabs {
    border-top: 1px solid var(--grey-lines);
  }

  &__block {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__block-wrap {
    display: grid;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--grey-lines);
    }

    &:last-child {
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--grey-lines);
      }
    }

    @for $i from 1 through 30 {
      &:nth-child(#{$i}) {
        .product-text-description {
          &__param {
            transition-delay: calc(((#{$i} - 1) * 0.2 + 0.4) * 1s); // .4, .6, .8 s
          }

          &__value {
            transition-delay: calc(((#{$i} - 1) * 0.2 + 0.8) * 1s); // .8, 1, 1.2 s
          }
        }
      }
    }
  }

  &__param {
    margin-right: var(--grid-gap);

    &.animation-item._transformY {
      transition-duration: .5s;
    }
  }

  &__value {
    &.animation-item._transformY {
      transition-duration: .5s;
    }
  }

  &__item-inner {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

@include respond-up("s-large") {
  .product-text-description {
    &__block-wrap {
      grid-row-gap: 80px;
    }
  }
}

@include respond-up("xs-large") {
  .product-text-description {
    margin-top: 100px;

    &__value {
      flex: 0 0 var(--grid-column5);
    }

    &__param {
      flex: 0 0 var(--grid-column4);
    }

    &__block {
      margin-top: 100px;
      grid-template-columns: var(--grid-column6) var(--grid-column9);
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .product-text-description {

    &__block-wrap {
      grid-row-gap: 70px;
    }
  }
}

@include respond-up("medium") {
  .product-text-description {
    &__item {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &__tabs {
      padding-top: 25px;
    }
  }
}

@include respond("medium") {
  .product-text-description {
    margin-top: 90px;

    &__value {
      flex: 0 0 var(--grid-column4);
    }

    &__param {
      flex: 0 0 var(--grid-column3);
    }

    &__block-wrap {
      grid-row-gap: 45px;
    }

    &__block {
      margin-top: 80px;
      grid-template-columns: var(--grid-column3) var(--grid-column7);
    }
  }
}

@include respond-down("small") {
  .product-text-description {
    margin-top: 60px;

    &__description {
      margin-top: 30px;
    }

    &__value {
      text-align: right;
      flex: 0 0 var(--grid-column5);
    }

    &__param {
      flex: 0 0 var(--grid-column5);
    }

    &__item {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__block-wrap {
      grid-row-gap: 40px;
      margin-top: 30px;
    }

    &__block {
      margin-top: 50px;
    }

    &__tabs {
      padding-top: 20px;
    }
  }
}
