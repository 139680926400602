.product-order-config-accordion {
  &__block {
    overflow: hidden;
    height: 0;
    transition: height .35s ease;
    background-color: #fff;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__link-title,
  &__link-subtitle {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__link-title {
    color: var(--grey-font);
  }

  &__link-subtitle {
    margin-top: 10px;
  }

  &__link-text-wrap {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
  }

  &__link-icon {
    margin-left: auto;
    flex: 0 0 26px;

    &_opened {
      display: none;
    }
  }

  &__block-inner {
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &._opened {
    .product-order-config-accordion {
      &__link {
        background-color: var(--grey-background);
      }

      &__link-icon {
        &_closed {
          display: none;
        }

        &_opened {
          display: flex;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .product-order-config-accordion {
    &__block,
    &__block-inner {
      max-height: calc(var(--grid-column6) * (388 / 543));
    }
  }
}

@include respond-up('xs-large') {
  .product-order-config-accordion {
    &__link {
      &:hover {
        background-color: var(--grey-background);
      }
    }
  }
}

@include respond('xs-large') {
  .product-order-config-accordion {
    &__block,
    &__block-inner {
      max-height: 320px;
    }
  }
}

@include respond-up('medium') {
  .product-order-config-accordion {
    &__link-title {
      font-size: 16px;
    }

    &__link-text-wrap,
    &__link-icon {
      margin-left: 25px;
    }

    &__link-image {
      width: 66px;
      height: 66px;
      flex: 0 0 66px;
    }

    &__link {
      padding: 20px;
    }

    &__block-inner {
      grid-gap: 20px;
      padding: 30px 20px;
    }
  }
}

@include respond('medium') {
  .product-order-config-accordion {
    &__block,
    &__block-inner {
      max-height: 544px;
    }
  }
}

@include respond-down('medium') {
  .product-order-config-accordion {

  }
}

@include respond-down('small') {
  .product-order-config-accordion {
    &__link-title,
    &__link-subtitle {
      font-size: 14px;
    }

    &__link-text-wrap,
    &__link-icon {
      margin-left: 15px;
    }

    &__link-image {
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
    }

    &__link {
      padding: 21px 16px;
    }

    &__block-inner {
      grid-gap: 10px;
      padding: 25px 16px;
    }

    &__block,
    &__block-inner {
      max-height: 232px;
    }
  }
}