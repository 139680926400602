.index-advantages {
  background: var(--dark-background-2);
  &__advantages-item-title {
    position: relative;
    display: flex;
  }

  &__advantages-item-icon {
    display: flex;
    max-width: 258px;
  }

  &__advantages-list {
    counter-reset: list;
  }

  &__advantages-item-title-text {
    flex: 1 0 auto;
  }

  &__advantages-item-description {
    color: var(--grey-font);

    .animation-item {
      opacity: 0;
    }
  }

  .animate-out {
    .index-advantages {
      &__advantages-item-description {
        .animation-item {
          opacity: 1;
        }
      }
    }
  }

  &__advantages-item-accordion-block-wrapper-inner {
    display: flex;
    justify-content: space-between;
  }

  &__advantages-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__advantages-item {
    border-top: 1px solid var(--grey-lines);
  }
}

@include respond-up("s-large") {
  .index-advantages {
    padding-bottom: 40px;

    &__advantages-item-icon {
      flex: 0 0 var(--grid-column2);
    }

    &__advantages-item-description {
      flex: 0 0 var(--grid-column4);
    }
  }
}

@include respond-up("xs-large") {
  .index-advantages {
    padding-top: 70px;

    &__advantages-item-accordion-block-wrapper {
      margin-top: 50px;
      margin-bottom: 80px;
    }

    &__advantages-list {
      margin-top: 40px;
    }

    &__advantages-item {
      padding-top: 25px;
      flex: 0 0 var(--grid-column7);
    }

    &__advantages-item-title {
      &:hover {
        cursor: initial;
      }
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .index-advantages {
    padding-bottom: 10px;

    &__advantages-item-icon {
      flex: 0 0 var(--grid-column3);
    }

    &__advantages-item-description {
      flex: 0 0 var(--grid-column4);
    }
  }
}

@include respond("medium") {
  .index-advantages {
    padding-bottom: 80px;
    padding-top: 50px;

    &__advantages-item-accordion-block-wrapper-inner {
      padding-bottom: 50px;
      padding-top: 10px;
    }

    &__advantages-list {
      margin-top: 35px;
    }

    &__advantages-item-icon {
      flex: 0 0 var(--grid-column3);
    }

    &__advantages-item-description {
      flex: 0 0 var(--grid-column5);
    }

    &__advantages-item-title {
      padding: 30px var(--grid-column1) 30px 0;
    }
  }
}

@include respond-down("medium") {
  .index-advantages {
    &__advantages-item {
      overflow: hidden;
      width: 100%;

      &:last-child {
        border-bottom: 1px solid var(--grey-lines);
      }

      &._opened {
        .index-advantages {
          &__advantages-item-accordion-block-wrapper {
            height: auto;
            opacity: 1;
          }

          &__advantages-item-title {
            &:after {
              transform: translateY(-50%) rotate(180deg);
            }

            &:before {
              width: 2px;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    &__advantages-item-accordion-block-wrapper {
      height: 0;
      opacity: 0;
      transition: opacity, height;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__advantages-item-title {
      grid-column: 1/11;
      padding: 25px var(--grid-column1) 25px 0;
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        height: 2px;
        width: 12px;
        background: black;
        transition: transform, width;
        transition-duration: var(--default-timing);
        transition-timing-function: var(--default-transition-function);
      }

      &:after {
        transform: translateY(-50%) rotate(90deg);
      }

      &:before {
        transform: translateY(-50%);
      }
    }
  }
}

@include respond-down("small") {
  .index-advantages {
    padding-bottom: 50px;
    padding-top: 30px;

    &__advantages-list {
      margin-top: 18px;
    }

    &__advantages-item-description {
      padding-top: 30px;
    }

    &__advantages-item-icon {
      width: var(--grid-column6);
      align-self: center;
    }

    &__advantages-item-accordion-block-wrapper-inner {
      padding-bottom: 40px;
      flex-direction: column;
    }
  }
}