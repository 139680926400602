.cart-delivery {
  &__tc-info {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 120%;
    color: var(--grey-font);
  }

  &__smart-label {
    &.smart-label {
      overflow: visible;
    }
  }
}

@include respond-up('large') {
  .cart-delivery {

  }
}

@include respond-up('medium') {
  .cart-delivery {
    &__tc-info {
      font-size: 14px;
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .cart-delivery {

  }
}

@include respond-down('medium') {
  .cart-delivery {

  }
}

@include respond-down('small') {
  .cart-delivery {
    &__tc-info {
      font-size: 12px;
      margin-top: 20px;
    }
  }
}