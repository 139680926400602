.cart-positions {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    border-top: 1px solid var(--grey-lines);

    &:last-child {
      border-bottom: 1px solid var(--grey-lines);
    }
  }

  &__block {
    height: 0;
    overflow: hidden;
    transition: height var(--default-timing) var(--default-bezier);
  }

  &__image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__total-field-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__prices-wrap {
    display: flex;
    align-items: center;
  }

  &__config,
  &__remove {
    color: var(--grey-font);
  }

  &__config,
  &__link,
  &__change,
  &__remove,
  &__item-price_old,
  &__char {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 120%;
  }

  &__remove-icon {
    &::before,
    &::after {
      background: var(--grey-font);
    }
  }

  &__item-price {
    &_old {
      text-decoration: line-through;
      line-height: 115%;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__chars-list {
    display: flex;
    flex-direction: column;
  }

  &__item-char {
    display: flex;
  }

  &__char {
    &_name {
      color: var(--grey-font);
    }
  }

  &__item-info-wrap {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__image-wrap {
    grid-area: image;
  }

  &__title {
    grid-area: name;
  }

  &__remove {
    grid-area: remove;
  }

  &__change {
    grid-area: change;
  }

  &__config {
    grid-area: config;
  }

  &__container {
    grid-area: details;
  }

  &__total-field-wrap {
    grid-area: total;
  }

  &__link-icon {
    transition: transform var(--default-timing) var(--default-bezier);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 7px;

    svg {
      width: 100%;
      height: 100%;
    }

    &._opened {
      transform: rotate(180deg);
    }
  }
}

@include respond-up('s-large') {
  .cart-positions {
    &__total-field-wrap {
      align-items: flex-end;
      margin-top: 30px;
    }

    &__chars-list {
      padding-bottom: 15px;
    }
  }
}

@include respond-up('xs-large') {
  .cart-positions {
    &__item-price {
      &_old {
        margin-right: 16px;
      }
    }
  }
}

@include respond('xs-large') {
  .cart-positions {
    &__total-field-wrap {
      margin-top: 25px;
    }
  }
}

@include respond-down('xs-large') {
  .cart-positions {
    &__chars-list {
      padding-bottom: 12px;
    }
  }
}

@include respond-up('medium') {
  .cart-positions {
    &__item {
      padding: 20px 0;
    }

    &__image-wrap {
      width: var(--grid-column2);
      height: var(--grid-column2);
    }

    &__item-info-wrap {
      grid-template-columns: var(--grid-column2) var(--grid-column5) var(--grid-column3);
      grid-template-areas:
              "image name remove"
              "image config change"
              "image details . "
              "image . . "
              "image total total"
              "image total total";
    }

    &__change {
      height: fit-content;
      align-self: flex-end;
    }

    &__config,
    &__container {
      margin-top: 15px;
    }

    &__remove,
    &__change {
      justify-self: flex-end;
    }

    &__config,
    &__link,
    &__change,
    &__item-price_old,
    &__char {
      font-size: 14px;
    }

    &__remove {
      font-size: 15px;
    }

    &__chars-list {
      row-gap: 10px;
    }

    &__item-char {
      column-gap: 35px;
    }
  }
}

@include respond('medium') {
  .cart-positions {
    &__total-field-wrap {
      margin-top: 27px;
    }

    &__item-price {
      &_old {
        margin-right: 25px;
      }
    }
  }
}

@include respond-down('small') {
  .cart-positions {
    &__item-info-wrap {
      grid-template-columns: var(--grid-column3) var(--grid-column6) var(--grid-column1);
      grid-template-areas:
              "image name remove"
              "image . ."
              "image change change"
              "config config config"
              "details details details"
              "total total total";
    }

    &__change {
      justify-self: flex-end;
    }

    &__config {
      margin-top: 18px;
    }

    &__container {
      margin-top: 12px;
    }

    &__total-field-wrap {
      margin-top: 18px;
    }

    &__item {
      padding: 15px 0;
    }

    &__image-wrap {
      width: var(--grid-column3);
      height: var(--grid-column3);
    }

    &__config,
    &__link,
    &__change,
    &__item-price_old,
    &__char {
      font-size: 12px;
    }

    &__remove {
      .button__close-text-wrap {
        display: none;
      }
    }

    &__item-price {
      &_old {
        margin-right: 12px;
      }
    }

    &__chars-list {
      row-gap: 12px;
    }

    &__item-char {
      column-gap: var(--grid-gap);
    }

    &__char {
      flex: 0 0 var(--grid-column5);
    }
  }
}