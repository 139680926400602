.cart-order-info {
  &__list {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-lines);
  }

  &__item {
    display: flex;
  }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__item-title {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 120%;
    color: var(--grey-font);
  }
}

@include respond-up('s-large') {
  .cart-order-info {
    &__list {
      padding: 16px 20px;
    }
  }
}

@include respond-up('large') {
  .cart-order-info {

  }
}

@include respond-between('medium', 'xs-large') {
  .cart-order-info {
    &__list {
      padding: 16px;
    }
  }
}

@include respond-up('medium') {
  .cart-order-info {
    &__list {
      row-gap: 12px;
      margin-top: 30px;
    }

    &__item {
      align-items: center;
      column-gap: 12px;
    }

    &__item-icon {
      flex: 0 0 18px;
      height: 18px;
    }

    &__item-title {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .cart-order-info {

  }
}

@include respond-down('medium') {
  .cart-order-info {

  }
}

@include respond-down('small') {
  .cart-order-info {
    &__list {
      row-gap: 8px;
      padding: 10px 12px;
      margin-top: 20px;
    }

    &__item {
      align-items: flex-start;
      column-gap: 8px;
    }

    &__item-icon {
      flex: 0 0 14px;
      height: 14px;
    }

    &__item-title {
      font-size: 12px;
    }
  }
}