.floating-field {
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: all .5s;
    margin: 0;
    //@extend %text-medium;
  }

  &._filled input + label,
  input:focus + label {
    top: -25px;
  }

  &._filled {
    label {
  //    color: red;
  //    background: red;
    }
  }

  & > input[type="text"], & > input[type="password"], & > input[type="search"] {
    &:-internal-autofill-selected {
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
  }
}

@include respond-up('s-large') {
  .floating-field {
    margin-top: 35px;
  }
}

@include respond-up('medium') {
  .floating-field {
    label {
      height: 45px;
    }

    &._filled input + label,
    input:focus + label {
      font-size: 13px;
      color: var(--primary-color-opacity-40);
    }
  }
}

@include respond-down('small') {
  .floating-field {
    label {
      //top: 6px;
    }

    &._filled input + label,
    input:focus + label {
      font-size: 12px;
      top: -18px;
    }
  }
}