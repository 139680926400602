.smart-label {
  position: relative;
  --smart-label-right-padding: var(--default-input-padding);
  --smart-label-left-padding: var(--default-input-padding);
  overflow: hidden;

  &__primary {
    transition-duration: 0.4s;
    position: absolute;
    left: var(--smart-label-left-padding);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &__secondary {
    transition-duration: 0.4s;
    position: absolute;
    right: var(--smart-label-left-padding);
    top: 50%;
    transform: translateX(60%) translateY(-50%);
    pointer-events: none;
    opacity: 0;
    text-transform: uppercase;
    font-size: 90%;
  }

  &._focus {
    .smart-label__primary {
      transition-duration: 0.4s;
      opacity: 0;
    }

    .smart-label__secondary {
      transition-duration: 0.4s;
      transform: translateX(0) translateY(-50%);
      opacity: 1;
    }
  }

  &._typing {
    .smart-label__primary {
      transition-duration: 0.4s;
      opacity: 0;
    }

    .smart-label__secondary {
      transition-duration: 0.4s;
      transform: translateX(0) translateY(-50%);
      opacity: 0;
    }
  }

  &._default {

  }
}

@keyframes smart-label-focus {
  0% {
    opacity: 1;
    right: auto;
    left: var(--smart-label-left-padding);
    transform: translateX(0) translateY(-50%);
    text-transform: none;
  }

  40% {
    opacity: 0;
    right: auto;
    left: var(--smart-label-left-padding);
    transform: translateX(0) translateY(-50%);
    text-transform: none;
  }

  41% {
    left: auto;
    right: var(--smart-label-left-padding);
    transform: translateX(60%) translateY(-50%);
    opacity: 0;
    text-transform: uppercase;
  }

  100% {
    left: auto;
    right: var(--smart-label-left-padding);
    transform: translateX(0) translateY(-50%);
    opacity: 1;
    text-transform: uppercase;
  }
}

@keyframes smart-label-typing {
  0% {
    left: auto;
    right: var(--smart-label-left-padding);
    transform: translateX(0) translateY(-50%);
    opacity: 1;
    text-transform: uppercase;
  }

  100% {
    left: auto;
    right: var(--smart-label-left-padding);
    transform: translateX(0) translateY(-50%);
    opacity: 0;
    text-transform: uppercase;
  }
}