.product-page {
  &__configurations-wrap {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid var(--grey-lines);
    }
  }
}

@include respond-up('s-large') {
  .product-page {
    &__product-collection,
    &__product-viewed,
    &__see-also,
    &__service-accordion,
    &__reviews {
      margin-top: 160px;
    }
  }
}

@include respond('xs-large') {
  .product-page {
    &__product-collection,
    &__product-viewed,
    &__see-also,
    &__service-accordion,
    &__reviews {
      margin-top: 140px;
    }
  }
}

@include respond('medium') {
  .product-page {
    &__product-collection,
    &__product-viewed,
    &__see-also,
    &__service-accordion,
    &__reviews {
      margin-top: 120px;
    }
  }
}

@include respond-down('small') {
  .product-page {
    &__product-collection,
    &__product-viewed,
    &__see-also,
    &__service-accordion,
    &__reviews {
      margin-top: 60px;
    }
  }
}