.more-articles {
  &__list {
    display: flex;
    border-top: 1px solid var(--grey-lines);
    border-bottom: 1px solid var(--grey-lines);
  }
}

@include respond-up("x-large") {
  .more-articles {
  }
}

@include respond-up("large") {
  .more-articles {
  }
}

@include respond("large") {
  .more-articles {
  }
}

@include respond-down("large") {
  .more-articles {
  }
}

@include respond-up("s-large") {
  .more-articles {
    margin-top: 160px;
  }
}

@include respond("s-large") {
  .more-articles {
  }
}

@include respond-down("s-large") {
  .more-articles {
  }
}

@include respond-up("xs-large") {
  .more-articles {
    &__item {
      &:first-child {
        .article-link-more {
          padding: 55px calc(var(--grid-gap) + var(--grid-column1) / 2) 55px 0;
        }
      }

      &:nth-child(2) {
        .article-link-more {
          padding: 55px 0 55px calc(var(--grid-gap) + var(--grid-column1) / 2);
        }
      }
    }

    &__list {
      margin-top: 40px;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .more-articles {
    margin-top: 140px;
  }
}

@include respond-down("xs-large") {
  .more-articles {
  }
}

@include respond-up("medium") {
  .more-articles {
    &__list {
      flex-direction: row;
    }

    &__item {
      flex: 0 0 50%;

      &:first-child {
        border-right: 1px solid var(--grey-lines);
      }
    }
  }
}

@include respond("medium") {
  .more-articles {
    margin-top: 120px;
    &__item {
      &:first-child {
        .article-link-more {
          padding: 45px calc(var(--grid-gap) + var(--grid-column1)) 45px 0;
        }
      }

      &:nth-child(2) {
        .article-link-more {
          padding: 45px 0 45px calc(var(--grid-gap) + var(--grid-column1));
        }
      }
    }

    &__list {
      margin-top: 30px;
    }
  }
}

@include respond-down("medium") {
  .more-articles {
  }
}

@include respond-down("small") {
  .more-articles {
    margin-top: 60px;

    &__list {
      flex-direction: column;
      margin-top: 18px;
    }

    &__item {
        .article-link-more {
          padding: 25px 0;
        }
    }

    &__item {
      &:nth-child(2) {
        border-top: 1px solid var(--grey-lines);
      }
    }
  }
}
