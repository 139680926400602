.index-page {
  &__button-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
}

@include respond-up("s-large") {
  .index-page {
    &__button-wrapper {
      padding-top: 60px;
    }
  }
}

@include respond-up("xs-large") {
  .index-page {
    &__reviews {
      margin-top: 120px;
    }
  }
}

@include respond("xs-large") {
  .index-page {
    &__button-wrapper {
      padding-top: 50px;
    }
  }
}

@include respond-up("medium") {
  .index-page{
    padding-bottom: 20px;
  }
}

@include respond("medium") {
  .index-page {
    &__button-wrapper {
      padding-top: 50px;
    }

    &__reviews {
      margin-top: 100px;
    }
  }
}

@include respond-down("small") {
  .index-page {
    padding-bottom: 10px;

    &__button-wrapper {
      padding-top: 40px;
    }

    &__reviews {
      margin-top: 50px;
    }
  }
}