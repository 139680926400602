.product-see-also {
  &__items {
    display: flex;
  }

  &__item {
    display: flex;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-title {
    display: flex;
    align-items: center;
  }
}

@include respond-up('s-large') {
  .product-see-also {
    &__item {
      &:first-child {
        .product-see-also {
          &__item-title {
            padding: 60px 60px 60px 0;
          }
        }
      }

      &:last-child {
        .product-see-also {
          &__item-title {
            padding: 60px;
          }
        }
      }
    }
  }
}

@include respond-up('xs-large') {
  .product-see-also {
    &__items {
      margin-top: 30px;
      border-top: 1px solid var(--grey-lines);
      border-bottom: 1px solid var(--grey-lines);
    }

    &__item {
      position: relative;
      flex: 0 0 50%;

      &:first-child {
        border-right: 1px solid var(--grey-lines);
      }
    }

    &__picture-wrap {
      display: none;
    }

    &__pictures {
      position: fixed;
      z-index: 50;
      width: var(--grid-column3);
      height: var(--grid-column3);
      pointer-events: none;
      will-change: transform;
    }

    &__picture-fixed-wrap {
      position: absolute;
      z-index: 5;
      overflow: hidden;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      transition: height .5s var(--default-transition-function);

      &._visible {
        height: 100%;
      }

      &._active {
        z-index: 10;
        animation: .5s height-in var(--default-transition-function) forwards;

        .product-see-also__image {
          transform: scale(1);
        }
      }

      &._out {
        bottom: unset;
        top: 0;
        animation: .5s height-out var(--default-transition-function) forwards;

        .product-see-also__picture {
          bottom: unset;
          top: 0;
        }
      }
    }

    &__picture {
      position: absolute;
      bottom: 0;
      left: 0;
      width: var(--grid-column3);
      height: var(--grid-column3);
    }

    &__image {
      transition: transform 1s var(--default-transition-function);
      transform: scale(1.2);
    }
  }
}

@include respond('xs-large') {
  .product-see-also {
    &__item {
      &:first-child {
        .product-see-also {
          &__item-title {
            padding: 50px 50px 50px 0;
          }
        }
      }

      &:last-child {
        .product-see-also {
          &__item-title {
            padding: 50px;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .product-see-also {

  }
}

@include respond('medium') {
  .product-see-also {
    &__items {
      margin-top: 25px;
    }

    &__item-title {
      margin-left: 18px;
    }

    &__picture-wrap {
      flex: 0 0 var(--grid-column2);
      width: var(--grid-column2);
      height: var(--grid-column2);
    }
  }
}

@include respond-down('medium') {
  .product-see-also {
    &__items {
      flex-direction: column;
    }

    &__item {
      border-bottom: 1px solid var(--grey-lines);

      &:first-child {
        border-top: 1px solid var(--grey-lines);
      }
    }

    &__pictures {
      display: none;
    }
  }
}

@include respond-down('small') {
  .product-see-also {
    &__items {
      margin-top: 15px;
    }

    &__item-title {
      margin-left: 10px;
    }

    &__picture-wrap {
      flex: 0 0 var(--grid-column3);
      width: var(--grid-column3);
      height: var(--grid-column3);
    }
  }
}

@keyframes height-in {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes height-out {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}