.configurations {
  position: relative;

  &.animation-wrap {
    overflow: visible;
  }

  &__list {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(var(--list-columns), var(--grid-column));
  }

  &__item-picture,
  &__picture {
    width: 100%;
    display: flex;
  }

  &__item-image,
  &__image {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

@include respond-up('s-large') {
  .configurations {
    margin-top: 160px;
    --block-padding: 160px;

    &.grid-block {
      grid-row-gap: 60px;
    }

    &__picture {
      height: calc(var(--grid-column5) * 448 / 448);
    }

    &__item-picture {
      height: calc(var(--grid-column3) * 258 / 258);
    }
  }
}

@include respond-up('xs-large') {
  .configurations {
    --list-columns: 9;
    padding-top: 40px;
    padding-bottom: 140px;

    &:after {
      left: calc(var(--grid-column6) - (var(--grid-column) / 2));
    }

    &__info-text,
    &__info-image-wrap {
      grid-column: 1/6;
    }

    &__info-text,
    &__title {
      grid-row: 1;
    }

    &__title,
    &__list {
      grid-column: 7/16;
    }

    &__info-image-wrap {
      width: var(--grid-column5);
    }

    &__item {
      width: var(--grid-column3);
    }
  }
}

@include respond('xs-large') {
  .configurations {
    --block-padding: 140px;
    margin-top: 140px;

    &.grid-block {
      grid-row-gap: 50px;
    }

    &__picture {
      height: calc(var(--grid-column5) * 308 / 308);
    }

    &__item-picture {
      height: calc(var(--grid-column3) * 174 / 174);
    }
  }
}

@include respond-up('medium') {
  .configurations {
    &:after {
      position: absolute;
      content: '';
      top: 0;
      width: 1px;
      height: 100%;
      background: var(--grey-lines);
    }

    &__item {
      grid-column: span 3;
    }
  }
}

@include respond('medium') {
  .configurations {
    --list-columns: 6;
    --block-padding: 120px;
    margin-top: 120px;
    padding-top: 35px;
    padding-bottom: 120px;

    &.grid-block {
      grid-row-gap: 40px;
    }

    &:after {
      left: calc(var(--grid-column4) - (var(--grid-column) / 2));
    }

    &__title,
    &__list {
      grid-column: 5/11;
    }

    &__title {
      grid-row: 1;
    }

    &__list,
    &__info-image-wrap,
    &__info-text {
      grid-row: 2;
    }

    &__info-text,
    &__info-image-wrap {
      grid-column: 1/4;
    }

    &__info-image-wrap,
    &__item {
      width: var(--grid-column3);
    }

    &__picture,
    &__item-picture {
      height: calc(var(--grid-column3) * 220 / 220);
    }

    &__info-text {
      height: fit-content;
      padding-top: calc(var(--grid-column3) * 220 / 220 + 50px);
    }
  }
}

@include respond-down('medium') {
  .configurations {

  }
}

@include respond-down('small') {
  .configurations {
    --list-columns: 10;
    margin-top: 60px;
    padding-top: 25px;
    padding-bottom: 60px;

    &.grid-block {
      grid-row-gap: 0;
    }

    //&__info-wrap,
    //&__configuration-list-wrap {
    //  grid-column: 1/11;
    //}
    //
    //&__info-wrap {
    //  grid-row: 1;
    //}
    //
    //&__configuration-list-wrap {
    //  grid-row: 2;
    //}

    &__title,
    &__info-image-wrap,
    &__list {
      grid-column: 1/11;
    }

    &__title {
      grid-row: 1;
    }

    &__info-image-wrap {
      grid-row: 2;
      margin-top: 18px;
    }

    &__info-text {
      grid-column: 3/11;
      grid-row: 3;
      margin-top: 30px;
    }

    &__list {
      grid-row: 4;
      margin-top: 40px;
    }

    &__info-image-wrap {
      width: var(--grid-column10);
    }

    &__item {
      width: var(--grid-column5);
    }

    &__picture {
      height: calc(var(--grid-column10) * 334 / 334);
    }

    &__item-picture {
      height: calc(var(--grid-column5) * 163 / 163);
    }

    &__item {
      grid-column: span 5;
    }
  }
}