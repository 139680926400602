.product-offer-config-field-radio {
  input[type="radio"] {
    display: none;

    &:checked {
      + label {
        &::before {
          content: '';
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          border: 1px solid var(--grey-font);
        }

        .product-offer-config-field-radio__icon {
          display: flex;
        }
      }
    }
  }

  label {
    position: relative;
    display: flex;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding-top: 100%;
  }

  img {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__icon {
    position: absolute;
    z-index: 15;
    display: none;
  }
}

@include respond-up('medium') {
  .product-offer-config-field-radio {
    &__icon {
      right: 15px;
      bottom: 15px;
    }
  }
}

@include respond-down('small') {
  .product-offer-config-field-radio {
    &__icon {
      right: 12px;
      bottom: 12px;
    }
  }
}