.salons {
  &__list-wrap {
    margin: 0 calc(-1 * var(--grid-spacer-and-indent));
    display: flex;
  }

  &__map-container {
    width: 100%;
  }

  &__item {
    display: flex;
  }

  &__item-wrap {
    position: relative;

    &:not(:first-child) {
      border-top: 1px solid var(--grey-lines);
    }
  }

  &__list-wrap {
    border-top: 1px solid var(--grey-lines);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
  }

  &__address-wrap,
  &__salon-info {
    display: flex;
    flex-direction: column;
  }

  &__info-wrap {
    justify-content: space-between;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__schedule-list {
    display: flex;
    flex-direction: column;
  }

  &__schedule-item {
    display: flex;
  }

  &__phone {
    padding: 10px;
    margin: -10px;
  }
}

@include respond-up('x-large') {
  .salons {
    &__picture {
      max-height: 230px;
    }

    &__image-wrap {
      max-width: 305px;
    }
  }
}

@include respond-up('s-large') {
  .salons {
    margin-top: 87px;

    &__list {
      flex: 0 0 calc(var(--grid-column6) + var(--grid-gap) + var(--grid-spacer-and-indent));
    }

    &__picture {
      height: calc((188 / 262) * var(--grid-column3));
    }

    &__phone {
      margin-top: 42px;
    }

    &__list-wrap {
      margin-top: 35px;

      &:after {
        width: calc(var(--grid-column6) + var(--grid-gap) + var(--grid-spacer-and-indent) - 15px);
      }
    }
  }
}

@include respond-up('xs-large') {
  .salons {
    &__to-map-link {
      display: none;
    }

    &__item-wrap {
      padding: 30px 30px 30px 0;
      transition: background var(--default-timing) var(--teaser-transition-func);
      cursor: pointer;

      &._last-item {
        padding-bottom: 0;
      }

      &:hover,
      &._hover {
        background: var(--dark-background);
      }
    }

    &__map-container {
      border-left: 1px solid var(--grey-lines);
    }

    &__image-wrap {
      display: flex;
      width: calc(var(--grid-column2) + var(--grid-spacer-and-indent));
      position: relative;

      &:hover {
        .salons__open-slider {
          opacity: 1;
        }
      }
    }

    &__item {
      column-gap: var(--grid-gap);
    }

    &__list {
      height: 680px;
      overflow-y: scroll;
      position: relative;

      &._no-scroll {
        overflow: scroll hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &._one-item {
        overflow-y: auto;

        .salons__item-wrap {
          border-bottom: 1px solid var(--grey-lines);
        }
      }
    }

    &__line {
      position: sticky;
      left: 0;
      bottom: 0;
      padding-top: 30px;
      background: var(--light-background);
      width: 100%;
      transition: background var(--default-timing) var(--teaser-transition-func);

      &._hover,
      &:hover {
        background: var(--dark-background);
      }
    }

    &__open-slider {
      width: 53px;
      height: 53px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 0;
      transition: opacity var(--default-timing) var(--teaser-transition-func);
      background: rgba(255, 255, 255, 0.6);
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

@include respond('xs-large') {
  .salons {
    margin-top: 57px;

    &__list {
      flex: 0 0 calc(var(--grid-column7) + var(--grid-gap) + var(--grid-spacer-and-indent));
    }

    &__phone {
      margin-top: 44px;
    }

    &__list-wrap {
      margin-top: 40px;

      &:after {
        width: calc(var(--grid-column7) + var(--grid-gap) + var(--grid-spacer-and-indent) - 15px);
      }
    }

    &__picture {
      height: calc((188 / 166) * (var(--grid-column2) + var(--grid-spacer-and-indent)));
    }
  }
}

@include respond-up('medium') {
  .salons {
    &__map {
      min-height: 680px;
    }

    &__address {
      margin-top: 10px;
    }

    &__schedule-list {
      row-gap: 6px;
      margin-top: 14px;
    }

    &__schedule-item {
      column-gap: 25px;
    }
  }
}

//@include respond('medium') {
//  .salons {
//    margin-top: 100px;
//
//    &__list-wrap {
//      margin-top: 35px;
//    }
//
//    &__picture {
//      height: calc((179 / 250) * (var(--grid-column3) + var(--grid-spacer-and-indent)));
//    }
//
//    &__item-wrap {
//      padding: 24px var(--grid-spacer-and-indent) 24px 0;
//    }
//
//    &__item {
//      column-gap: 40px;
//    }
//
//    &__to-map-link {
//      bottom: 24px;
//      right: var(--grid-spacer-and-indent);
//    }
//  }
//}

//@include respond-down('medium') {
//  .salons {
//    &__list-wrap {
//      flex-direction: column;
//    }
//
//    &__list {
//      width: 100%;
//    }
//
//    &__to-map-link {
//      position: absolute;
//    }
//
//    &__map-container {
//      border-top: 1px solid var(--grey-lines);
//    }
//  }
//}

@include respond-down('medium') {
  .salons {
    &__image-wrap {
      width: calc(var(--grid-spacer-and-indent) + var(--grid-column3));
    }
  }
}

@media (max-width: 1023px) and (min-width: 500px) {
  .salons {
    margin-top: 100px;

    &__list-wrap {
      margin-top: 35px;
    }

    &__picture {
      height: calc((179 / 250) * (var(--grid-column3) + var(--grid-spacer-and-indent)));
    }

    &__item-wrap {
      padding: 24px var(--grid-spacer-and-indent) 24px 0;
    }

    &__item {
      column-gap: 40px;
    }

    &__to-map-link {
      bottom: 24px;
      right: var(--grid-spacer-and-indent);
    }

    &__list-wrap {
      flex-direction: column;
    }

    &__list {
      width: 100%;
    }

    &__to-map-link {
      position: absolute;
    }

    &__map-container {
      border-top: 1px solid var(--grey-lines);
    }

    &__address-wrap {
      row-gap: 10px;
    }

    &__salon-info {
      row-gap: 14px;
      margin-top: 38px;
    }

    &__schedule-item {
      column-gap: 25px;
    }

    &__open-slider {
      display: none;
    }
  }
}

@media (max-width: 499px) {
  .salons {
    margin-top: 62px;

    &__map {
      min-height: 420px;
    }

    &__list-wrap {
      margin-top: 18px;
      flex-direction: column;
    }

    &__picture {
      height: calc((188 / 262) * (var(--grid-column3) + var(--grid-spacer-and-indent)));
    }

    &__item-wrap {
      padding: 22px var(--grid-spacer-and-indent) 22px 0;
    }

    &__item {
      column-gap: 30px;
    }

    &__to-map-link {
      bottom: 22px;
      left: var(--grid-spacer-and-indent);
    }

    &__address {
      margin-top: 8px;
    }

    &__schedule-list {
      row-gap: 4px;
      margin-top: 10px;
    }

    &__schedule-item {
      column-gap: 29px;
    }

    &__phone {
      margin-top: 20px;
    }

    &__open-slider {
      display: none;
    }
  }
}

//@include respond-down('small') {
//  .salons {
//    margin-top: 62px;
//
//    &__map {
//      min-height: 420px;
//    }
//
//    &__list-wrap {
//      margin-top: 18px;
//    }
//
//    &__picture {
//      height: calc((188 / 262) * (var(--grid-column3) + var(--grid-spacer-and-indent)));
//    }
//
//    &__item-wrap {
//      padding: 22px var(--grid-spacer-and-indent) 22px 0;
//    }
//
//    &__item {
//      column-gap: 30px;
//    }
//
//    &__to-map-link {
//      bottom: 22px;
//      left: var(--grid-spacer-and-indent);
//    }
//
//    &__address {
//      margin-top: 8px;
//    }
//
//    &__schedule-list {
//      row-gap: 4px;
//      margin-top: 10px;
//    }
//
//    &__schedule-item {
//      column-gap: 29px;
//    }
//
//    &__phone {
//      margin-top: 20px;
//    }
//  }
//}