.cart-page {
  &__content-wrap {
    overflow-x: hidden;
  }
}

@include respond-up("xs-large") {
  .cart-page {
    &__content-wrap {
      grid-column: 1/11;
    }

    &__side-block {
      position: relative;
      grid-column: 12/16;
    }
  }
}

@include respond-up('medium') {
  .cart-page {

  }
}

@include respond('medium') {
  .cart-page {
    &__wrap {
      grid-row-gap: 70px;
    }
  }
}

@include respond-down('medium') {
  .cart-page {
    &__content-wrap {
      grid-row: 1;
    }

    &__content-wrap,
    &__side-block {
      grid-column: 1/11;
    }

    &__side-block {
      grid-row: 2;
    }
  }
}

@include respond-down('small') {
  .cart-page {
    &__wrap {
      grid-row-gap: 45px;
    }
  }
}