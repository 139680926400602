%text-large {
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}

@include respond-up("s-large") {
  %text-large {
    font-size: 20px;
  }
}

// laptop
@include respond("xs-large") {
  %text-large {
    font-size: 20px;
  }
}

@include respond("medium") {
  %text-large {
    font-size: 18px;
  }
}

@include respond-down("small") {
  %text-large {
    font-size: 17px;
  }
}
