%text-paragraph {
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  text-transform: uppercase;
}

@include respond-up("s-large") {
  %text-paragraph {
    font-size: 20px;
  }
}

// laptop
@include respond("xs-large") {
  %text-paragraph {
    font-size: 18px;
  }
}

@include respond("medium") {
  %text-paragraph {
    font-size: 18px;
  }
}

@include respond-down("small") {
  %text-paragraph {
    font-size: 16px;
  }
}