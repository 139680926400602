.product-order-config {
  &__title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    text-transform: uppercase;
  }

  &__offers-block {
    .product-order-config__item {
      border: 1px solid var(--grey-lines);
    }
  }

  &__options-block {
    .product-order-config {
      &__items {
        border-top: 1px solid var(--grey-lines);
      }

      &__item {
        border-bottom: 1px solid var(--grey-lines);
      }
    }

    .product-order-config-accordion {
      transition: padding-bottom .35s ease;

      &._opened {
        padding-bottom: 35px;
      }
    }
  }

  &__button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;

    &._sticky {
      .product-order-config__button {
        position: fixed;
        z-index: 60;
        bottom: 15px;
        right: var(--grid-spacer);
        left: var(--order-button-left);
        width: var(--order-button-width);
      }
    }
  }

  &__button-container {
    width: 100%;
  }

  &__button {
    width: 100%;
    transition: unset;
  }

  &__button-subtitle {
    color: var(--grey-font);
    text-align: center;
  }

  &__button-price-old {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 115%;
    text-transform: uppercase;
    text-decoration: line-through;
    margin-left: 8px;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
  }

  &__hint-wrap {
    display: flex;
    justify-content: center;
  }

  &__installment-hint-wrap {
    position: relative;
  }

  &__installment-hint-button {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__installment-hint-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__installment-hint-button-text {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      border-bottom: 1px solid #000;
      pointer-events: none;
    }

    &::before {
      transform-origin: right;
    }

    &::after {
      transform: scaleX(0);
      transform-origin: left;
    }
  }
}

@include respond-up('s-large') {
  .product-order-config {
    --order-button-left: calc(var(--grid-spacer) + var(--grid-column9) + var(--grid-gap));
    --order-button-width: var(--grid-column6);

    &__installment-hint-wrap {
      margin-top: 14px;
    }

    &__installment-hint-text {
      top: calc(100% + 14px);
    }
  }
}

@include respond-up('xs-large') {
  .product-order-config {
    &__installment-hint-wrap {
      &:hover {
        .product-order-config {
          &__installment-hint-button-text {
            &::before {
              transform: scaleX(0);
              transition: transform .65s cubic-bezier(.215,.61,.355,1)
            }

            &::after {
              transform: scaleX(1);
              transition: transform .65s cubic-bezier(.215,.61,.355,1) .65s;
            }
          }

          &__installment-hint-text {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    &__installment-hint-text {
      position: absolute;
      left: 0;
      background: rgba(0, 0, 0, .77);
      color: #fff;
      padding: 12px 14px;
      width: 272px;
      opacity: 0;
      pointer-events: none;
      transition: opacity var(--default-timing) var(--default-transition-function);
    }
  }
}

@include respond('xs-large') {
  .product-order-config {
    --order-button-left: calc(var(--grid-spacer) + var(--grid-column8) + var(--grid-gap));
    --order-button-width: var(--grid-column7);

    &__installment-hint-wrap {
      margin-top: 12px;
    }

    &__installment-hint-text {
      top: calc(100% + 12px);
    }
  }
}

@include respond-up('medium') {
  .product-order-config {
    &__title {
      font-size: 14px;
    }

    &__items {
      margin-top: 18px;
    }

    &__options-block {
      margin-top: 40px;
    }

    &__button,
    &__button-container {
      height: 64px;
    }

    &__button-subtitle {
      margin-top: 16px;
    }

    &__button-dash {
      margin: 0 25px;
    }

    &__title-wrap {
      column-gap: 20px;
    }
  }
}

@include respond('medium') {
  .product-order-config {
    &__installment-hint-wrap {
      margin-top: 16px;
    }
  }
}

@include respond-down('medium') {
  .product-order-config {
    --order-button-left: var(--grid-spacer);
    --order-button-width: var(--grid-column10);

    &__button-price-old {
      font-size: 10px;
    }

    &__installment-hint-text {
      display: none;
    }

    &__installment-hint-button-text {
      &::before,
      &::after {
        bottom: 0;
      }
    }
  }
}

@include respond-down('small') {
  .product-order-config {
    &__title {
      font-size: 12px;
    }

    &__items {
      margin-top: 15px;
    }

    &__options-block {
      margin-top: 25px;
    }

    &__button,
    &__button-container {
      height: 58px;
    }

    &__button-subtitle {
      margin-top: 12px;
      max-width: var(--grid-column8);
    }

    &__button-dash {
      margin: 0 20px;
    }

    &__title-wrap {
      column-gap: 16px;
    }

    &__installment-hint-wrap {
      margin-top: 12px;
    }
  }
}