.cart-contacts {
  &__input[type="text"] {
    &:-webkit-autofill {
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    font-size: 16px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  &__label,
  &__optional {
    font-family: var(--h-font);
    font-weight: 400;
    line-height: 125%;
  }

  &__optional {
    color: var(--grey-font);
  }

  &__field {
    height: fit-content;
  }
}

@include respond-up('xs-large') {
  .cart-contacts {
    margin-top: 85px;

    &__form {
      grid-row-gap: 20px;
    }
  }
}

@include respond-up('medium') {
  .cart-contacts {
    &__form {
      display: grid;
      grid-column-gap: var(--grid-gap);
      grid-template-columns: repeat(2, var(--grid-column5));
    }

    &__field {
      grid-column: span 1;
    }

    &__input[type="text"] {
      height: 64px;
      padding: 0 22px;
    }

    &__label {
      font-size: 16px;
    }

    &__optional {
      font-size: 13px;
      margin-top: 9px;
    }
  }
}

@include respond('medium') {
  .cart-contacts {
    margin-top: 70px;

    &__form {
      grid-row-gap: 15px;
    }
  }
}

@include respond-down('small') {
  .cart-contacts {
    margin-top: 45px;

    &__form {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &__input[type="text"] {
      height: 57px;
      padding: 0 18px;
    }

    &__label {
      font-size: 15px;
    }

    &__optional {
      margin-top: 8px;
      font-size: 12px;
    }
  }
}