.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--header-height));
  position: relative;

  &__code,
  &__description,
  &__button {
    z-index: 50;
  }

  &__code {
    border-bottom: 1px solid var(--light-color-opacity-50);
    font-family: var(--h-font);
    line-height: 76%;

    svg {
      height: auto;
    }
  }

  &__description {
    text-align: center;
  }

  &__picture {
    display: flex;
    position: absolute;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .error-page {
    &__code {
      font-size: 250px;
    }

    &__picture {
      height: calc(var(--grid-column2) * (177 / 167));

      &_fourth {
        bottom: 130px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .error-page {
    &__description {
      width: var(--grid-column3);
    }

    &__picture {
      width: var(--grid-column2);

      &_first {
        left: calc(-1 * var(--grid-spacer-and-indent));
        top: 150px;
      }

      &_second {
        left: calc(var(--grid-column2) + var(--grid-gap));
        top: 35px;
      }

      &_third {
        right: calc(-1 * var(--grid-spacer-and-indent));
        top: 90px;
      }

      &_fourth {
        right: calc(var(--grid-column2) + var(--grid-gap));
      }

      &_fifth {
        left: calc(var(--grid-column3) + var(--grid-gap));
        bottom: 0;
      }
    }
  }
}

@include respond('xs-large') {
  .error-page {
    &__code {
      font-size: 170px;

      svg {
        width: var(--grid-column5);
      }
    }

    &__picture {
      height: calc(var(--grid-column2) * (116 / 109));

      &_first,
      &_third {
        width: calc(var(--grid-column2) - 7px);
        height: calc((var(--grid-column2) - 7px) * (104 / 99));
      }

      &_fourth {
        bottom: 190px;
      }
    }
  }
}

@include respond-up('medium') {
  .error-page {
    &__description {
      margin-top: 45px;
    }

    &__button {
      margin-top: 30px;
    }

    &__code {
      padding-bottom: 45px;
    }
  }
}

@include respond('medium') {
  .error-page {
    &__description {
      width: var(--grid-column4);
    }

    &__code {
      font-size: 168px;

      svg {
        width: var(--grid-column4);
      }
    }

    &__picture {
      width: var(--grid-column2);
      height: calc(var(--grid-column2) * (149 / 140));

      &_first {
        left: calc(-1 * var(--grid-spacer-and-indent));
        top: 50%;
        transform: translateY(-50%);
        height: calc((var(--grid-column2) + var(--grid-gap) + 12px) * (179 / 170));
      }

      &_second {
        left: calc(var(--grid-column) + var(--grid-gap));
        top: 27px;
      }

      &_first,
      &_third {
        width: calc(var(--grid-column2) + var(--grid-gap) + 12px);
      }

      &_third {
        right: calc(-1 * var(--grid-spacer-and-indent));
        top: 100px;
        height: calc((var(--grid-column2) + var(--grid-gap) + 12px) * (183 / 173));
      }

      &_fourth {
        right: calc(var(--grid-column1) + var(--grid-gap));
        bottom: 88px;
      }

      &_fifth {
        left: calc(var(--grid-column2) + var(--grid-gap));
        bottom: 0;
      }
    }
  }
}

@include respond-down('small') {
  .error-page {

    &__code {
      padding-bottom: 25px;
      font-size: 109px;

      svg {
        width: var(--grid-column6);
      }
    }

    &__description,
    &__button {
      margin-top: 25px;
    }

    &__description {
      width: var(--grid-column6);
    }

    &__picture {
      width: clamp(80px, calc(var(--grid-column3) - 15px), 117px);
      height: clamp(83px, calc((var(--grid-column3) - 15px) * (83 / 80)), 120px);

      &_first {
        left: calc(-1 * var(--grid-spacer-and-indent));
        bottom: 155px;
      }

      &_second {
        left: calc(-1 * var(--grid-spacer-and-indent));
        top: 20px;
      }

      &_third {
        right: calc(-1 * var(--grid-spacer-and-indent));
        top: 64px;
      }

      &_fourth {
        right: calc(var(--grid-column1) + var(--grid-gap));
        bottom: 5vh;
      }

      &_fifth {
        left: calc(var(--grid-column1) + var(--grid-gap));
        bottom: 0;
      }

      &_fourth,
      &_fifth {
        width: clamp(95px, var(--grid-column3), 132px);
        height: clamp(100px, calc(var(--grid-column3) * (100 / 95)), 139px);
      }
    }
  }
}