.link {
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  color: var(--text-and-border-color-by-theme);
  position: relative;
  font-size: 14px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 10px 10px 0;
  margin: -10px -10px 0;

  &._underlined {
    &:before,
    &:after {
      content: '';
      width: calc(100% - 20px);
      height: 1px;
      display: block;
      position: absolute;
      background-color: var(--text-and-border-color-by-theme);
      bottom: 0;
    }

    &:before {
      transform-origin: right;
    }

    &:after {
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  &__filter,
  &__review,
  &__text-with-arrow {
    line-height: 120%;
    padding-bottom: 4px;
  }

  &__big {
    line-height: 110%;
    text-transform: uppercase;
    padding-bottom: 10px;
  }

  &__small {
    font-size: 14px;
    line-height: 115%;
    text-transform: uppercase;
    padding-bottom: 6px;
  }

  &__link {
    font-family: var(--font);
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    padding-bottom: 2px;
  }

  &__review-text {
    margin-right: 10px;
  }

  &__review-icon,
  &__text-with-arrow-icon {
    display: flex;
    align-items: center;
  }

  &__text-with-arrow {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 10px;
      bottom: 0;
      width: calc(100% - 20px);
      height: 1px;
      background-color: #BCBCBC;
      z-index: 10;
    }
    column-gap: 6px;
    &._left {
      .link {
        &__text-with-arrow-icon {
          order: 1;
        }

        &__text-with-arrow-text-wrap {
          order: 2;
        }
      }
    }
  }

  &__round-arrow {
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    transition: .6s all cubic-bezier(.76,0,.24,1);
    svg path {
      transition: .6s all cubic-bezier(.76,0,.24,1);
    }
  }

  &__round-arrow-icon {
    transition: .6s transform cubic-bezier(.76,0,.24,1);
  }

  &__play {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  &__play-icon {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: border-color var(--default-timing) var(--teaser-transition-func);
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent #FFF;
    }
  }
}

@include respond-up('xs-large') {
  .link {
    &._underlined {
      &:hover {
        &::after {
          transition: transform .65s cubic-bezier(.215,.61,.355,1) .65s;
          transform: scaleX(1);
        }

        &::before {
          transition: transform .65s cubic-bezier(.215,.61,.355,1);
          transform: scaleX(0);
        }
      }
    }

    &._line,
    &__text-with-arrow {
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 10px;
        bottom: 0;
        width: calc(100% - 20px);
        height: 1px;
        background-color: var(--text-and-border-color-by-theme);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .65s cubic-bezier(.215,.61,.355,1);
        z-index: 20;
      }

      &:hover {
        &:before {
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .4s var(--teaser-transition-func);
        }
      }
    }

    &__round-arrow {
      overflow: hidden;
      position: relative;
      &:hover {
        background: white;
        //width: 44px;
        //height: 44px;
        transform: scale(1.2);
        transform-origin: center;
        svg path {
          stroke: var(--primary-color);
        }
        .link__round-arrow-icon {
          transform: translate3d(160%,-30px,0) scale(.84);
          &_clone {
            transform: translate3d(-50%, -50%, 0) scale(.84);
          }
        }
      }
    }

    &__round-arrow-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 12px;
      height: 12px;
      display: flex;

      &_clone {
        transform: translate3d(-250%, 20px, 0px);
      }
    }

    &__play {
      &:hover {
        .link__play-icon {
          border-color: white;
        }
      }
    }

    &__play-icon {
      width: 106px;
      height: 106px;
    }
  }
}

@include respond-up('medium') {
  .link {
    &__filter,
    &__big,
    &__review {
      font-size: 15px;
    }

    &__round-arrow {
      width: 36px;
      height: 36px;
    }

    &__play-icon {
     &:before {
        border-width: 8px 0 8px 13px;
      }
    }
  }
}

@include respond('medium') {
  .link {
    &__play-icon {
      width: 82px;
      height: 82px;
    }
  }
}

@include respond-down('medium') {
  .link {
    &__round-arrow-icon_clone {
      display: none;
    }
  }
}

@include respond-down('small') {
  .link {
    &__filter,
    &__big,
    &__review {
      font-size: 14px;
    }

    &__round-arrow {
      width: 28px;
      height: 28px;
      svg {
        width: 10px;
        height: 10px;
      }
    }

    &__play-icon {
      width: 64px;
      height: 64px;

      &:before {
        border-width: 6px 0 6px 10px;
      }
    }
  }
}