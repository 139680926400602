.new-label {
  color: white;
  background: black;
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  width: min-content;
}

@include respond-up("xs-large") {
  .new-label {
    font-size: 13px;
    padding: 9px 22px;
  }
}

@include respond-down("medium") {
  .new-label {
    font-size: 8px;
    padding: 6px 14px;
  }
}