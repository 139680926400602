.index-collections {
  background: var(--dark-background-2);
  &__slider {
    margin-top: 0 !important; // temp
  }
}

@include respond-up("x-large") {
  .index-collections {
  }
}

@include respond-up("large") {
  .index-collections {
  }
}

@include respond("large") {
  .index-collections {
  }
}

@include respond-down("large") {
  .index-collections {
  }
}

@include respond-up("s-large") {
  .index-collections {
    padding-top: 120px;
    padding-bottom: 70px;
  }
}

@include respond("s-large") {
  .index-collections {
  }
}

@include respond-down("s-large") {
  .index-collections {
  }
}

@include respond-up("xs-large") {
  .index-collections {
  }
}

// laptop 1100
@include respond("xs-large") {
  .index-collections {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}

@include respond-down("xs-large") {
  .index-collections {
  }
}

@include respond-up("medium") {
  .index-collections {
  }
}

@include respond("medium") {
  .index-collections {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@include respond-down("medium") {
  .index-collections {
  }
}

@include respond-down("small") {
  .index-collections {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}
