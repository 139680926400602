// правая слевая, а левая справа. ну бывает
.partners-first-block {
  .grid-block{
    grid-row-gap: 0;
  }
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__left-image-wrap {
    margin-right: calc(0px - var(--grid-spacer));
  }

  &__right-image-wrap {
    margin-left: calc(0px - var(--grid-spacer));
  }
}

@include respond-up("s-large") {
  .partners-first-block {
    &__description-link {
      margin-top: 40px;
    }

    &__title {
      grid-column: 1/8;
    }

    &__left-image-wrap {
      grid-column: 13/16;
      margin-top: -20px;
      grid-row: 1/3;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(355 / 358 * (var(--grid-column3) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(408 / 358 * (var(--grid-column3) + var(--grid-spacer)));
        }
      }
    }

    &__right-image-wrap {
      grid-column: 1/4;
      margin-top: 100px;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(339 / 358 * (var(--grid-column3) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(390 / 358 * (var(--grid-column3) + var(--grid-spacer)));
        }
      }
    }

    &__description-wrapper {
      grid-column: 6/11;
      margin-top: 160px;
    }
  }
}

@include respond-up("xs-large") {
  .partners-first-block {
    padding-top: 100px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .partners-first-block {
    &__description-link {
      margin-top: 40px;
    }

    &__title {
      grid-column: 1/9;
    }

    &__left-image-wrap {
      grid-column: 13/16;
      grid-row: 1/3;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(240 / 234 * (var(--grid-column3) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(276 / 234 * (var(--grid-column3) + var(--grid-spacer)));
        }
      }
    }

    &__right-image-wrap {
      grid-column: 1/4;
      margin-top: 90px;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(262 / 236 * (var(--grid-column3) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(301 / 236 * (var(--grid-column3) + var(--grid-spacer)));
        }
      }
    }

    &__description-wrapper {
      grid-column: 6/11;
      margin-top: 110px;
    }
  }
}

@include respond("medium") {
  .partners-first-block {
    padding-top: 90px;

    &__description-link {
      margin-top: 35px;
    }

    &__title {
      grid-column: 1/8;
    }

    &__left-image-wrap {
      grid-column: 9/11;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(169 / 170 * (var(--grid-column2) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(194 / 170 * (var(--grid-column2) + var(--grid-spacer)));
        }
      }
    }

    &__right-image-wrap {
      grid-column: 1/3;
      margin-top: 90px;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(161 / 170 * (var(--grid-column2) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(185 / 170 * (var(--grid-column2) + var(--grid-spacer)));
        }
      }
    }

    &__description-wrapper {
      margin-top: 20px;
      grid-column: 4/8;
    }
  }
}

@include respond-down("small") {
  .partners-first-block {
    padding-top: 40px;

    &__description-link {
      margin-top: 28px;
    }

    &__title {
      grid-column: 1/8;
      order: 1;
    }

    &__left-image-wrap {
      grid-column: 8/11;
      order: 2;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(112 / 115 * (var(--grid-column3) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(129 / 115 * (var(--grid-column3) + var(--grid-spacer)));
        }
      }
    }

    &__right-image-wrap {
      order: 4;
      grid-column: 1/7;
      margin-top: 42px;

      .partners-first-block {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(205 / 217 * (var(--grid-column6) + var(--grid-spacer)));
        }

        &__picture {
          height: calc(236 / 217 * (var(--grid-column6) + var(--grid-spacer)));
        }
      }
    }

    &__description-wrapper {
      order: 3;
      margin-top: 40px;
      grid-column: 2/10;
    }
  }
}
