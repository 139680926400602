.index-about {
  background: var(--dark-background-2);
  overflow: hidden;

  &__heading {
    overflow: hidden;
  }

  &__wrapper {
    display: grid;
  }

  &__description {
    color: var(--grey-font);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__first-image-wrap {
    margin-right: calc(0px - var(--grid-spacer));
  }
}

@include respond-up("s-large") {
  .index-about {
    padding-top: 74px;

    &__link {
      margin-top: 40px;
    }

    &__first-image-wrap {
      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(530 / 738 * (var(--grid-column7) + var(--grid-spacer)));
        }
      }
    }

    &__picture-first {
      height: calc(610 / 738 * (var(--grid-column7) + var(--grid-spacer)));
    }

    &__second-image-wrap {
      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(561 / 638 * var(--grid-column7));
        }
      }
    }

    &__picture-second {
      height: calc(645 / 638 * var(--grid-column7));
    }

    &__description {
      margin-top: calc(var(--grid-column1) * 1.5 + var(--grid-gap));
      width: var(--grid-column5);
    }

    &__wrapper {
      grid-column-gap: calc(var(--grid-gap) * 2 + var(--grid-column1));
      grid-template-columns: var(--grid-column7) var(--grid-column7);
    }
  }
}

@include respond-up("xs-large") {
  .index-about {
    padding-bottom: 70px;

    &__title {
      margin-top: 60px;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .index-about {
    padding-top: 28px;

    &__link {
      margin-top: 40px;
    }

    &__first-image-wrap {
      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(388 / 506 * (var(--grid-column7) + var(--grid-spacer)));
        }
      }
    }

    &__picture-first {
      height: calc(446 / 506 * (var(--grid-column7) + var(--grid-spacer)));
    }

    &__second-image-wrap {
      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(388 / 442 * var(--grid-column7));
        }
      }
    }

    &__picture-second {
      height: calc(446 / 442 * var(--grid-column7));
    }

    &__description {
      margin-top: calc(var(--grid-column1) * 1.3 + var(--grid-gap));
      width: var(--grid-column5);
    }

    &__wrapper {
      grid-column-gap: calc(var(--grid-gap) * 2 + var(--grid-column1));
      grid-row-gap: 50px;
      grid-template-columns: var(--grid-column7) var(--grid-column7);
    }
  }
}

@include respond("medium") {
  .index-about {
    padding-top: 30px;
    padding-bottom: 50px;

    &__title {
      margin-top: 50px;
    }

    &__link {
      margin-top: 35px;
    }

    &__first-image-wrap {
      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(388 / 408 * (var(--grid-column5) + var(--grid-spacer)));
        }
      }
    }

    &__picture-first {
      height: calc(446 / 408 * (var(--grid-column5) + var(--grid-spacer)));
    }

    &__second-image-wrap {
      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(300 / 299 * var(--grid-column4));
        }
      }
    }

    &__picture-second {
      height: calc(345 / 299 * var(--grid-column4));
    }

    &__second-image-wrap {
      padding-right: calc(var(--grid-gap) + var(--grid-column1));
    }

    &__description {
      margin-top: 30px;
      width: var(--grid-column5);
    }

    &__wrapper {
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 70px;
      grid-template-columns: var(--grid-column5) var(--grid-column5);
    }
  }
}

@include respond-down("small") {
  .index-about {
    padding-top: 30px;
    padding-bottom: 30px;

    &__description-wrapper {
      order: 3;
    }

    &__title {
      margin-top: 30px;
    }

    &__link {
      margin-top: 30px;
    }

    &__first-image-wrap {
      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(354 / 334 * (var(--grid-column10) + var(--grid-spacer)));
        }
      }
    }

    &__picture-first {
      height: calc(407 / 334 * (var(--grid-column10) + var(--grid-spacer)));
    }

    &__second-image-wrap {
      order: 4;
      margin-top: 20px;
      padding-right: calc(var(--grid-gap) + var(--grid-column4));

      .index-about {
        &__parallax-container,
        &__parallax-container-inner {
          height: calc(220 / 197 * var(--grid-column6));
        }
      }
    }

    &__picture-second {
      height: calc(253 / 197 * var(--grid-column6));
    }

    &__description {
      margin-top: 20px;
    }

    &__wrapper {
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 30px;
      grid-template-columns: var(--grid-column10);
    }
  }
}
