.huge-bottom-link {
  position: relative;

  &__link {
    z-index: 80;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:before {
    z-index: 50;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
  }

  &__label {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  &__title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    text-transform: uppercase;
  }

  &__label,
  &__title {
    color: white;
    width: var(--grid-column10);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap {
    z-index: 30;
  }

  &__image-wrap,
  &__text-wrapper {
    width: 100%;
    position: absolute;
  }

  &__text-wrapper {
    z-index: 60;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@include respond-up("x-large") {
  .huge-bottom-link {
  }
}

@include respond-up("large") {
  .huge-bottom-link {
  }
}

@include respond("large") {
  .huge-bottom-link {
  }
}

@include respond-down("large") {
  .huge-bottom-link {
  }
}

@include respond-up("s-large") {
  .huge-bottom-link {
    height: calc(600 / 1600 * var(--vw, 1vw) * 100);

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(600 / 1600 * var(--vw, 1vw) * 100);
    }

    &__picture {
      height: calc(1200 / 1600 * var(--vw, 1vw) * 100);
    }

    &__title {
      font-size: 52px;
      margin-top: 30px;
    }
  }
}

@include respond("s-large") {
  .huge-bottom-link {
  }
}

@include respond-down("s-large") {
  .huge-bottom-link {
  }
}

@include respond-up("xs-large") {
  .huge-bottom-link {
  }
}

// laptop 1100
@include respond("xs-large") {
  .huge-bottom-link {
    &__title {
      font-size: 46px;
      margin-top: 25px;
    }

    height: calc(420 / 1100 * var(--vw, 1vw) * 100);

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(420 / 1100 * var(--vw, 1vw) * 100);
    }

    &__picture {
      height: calc(840 / 1100 * var(--vw, 1vw) * 100);
    }
  }
}

@include respond-down("xs-large") {
  .huge-bottom-link {
  }
}

@include respond-up("medium") {
  .huge-bottom-link {
    &__image-wrap-mobile {
      display: none;
    }

    &__label {
      font-size: 18px;
    }
  }
}

@include respond("medium") {
  .huge-bottom-link {
    height: calc(420 / 864 * var(--vw, 1vw) * 100);
    min-height: 420px;

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(420 / 864 * var(--vw, 1vw) * 100);
      min-height: 420px;
    }

    &__picture {
      height: calc(840 / 864 * var(--vw, 1vw) * 100);
      min-height: 840px;
    }

    &__title {
      font-size: 42px;
      margin-top: 18px;
    }
  }
}

@include respond-down("medium") {
  .huge-bottom-link {
  }
}

@include respond-down("small") {
  .huge-bottom-link {
    height: 420px;

    &__parallax-container-inner,
    &__parallax-container {
      height: 420px;
    }

    &__picture {
      height: 840px;
    }

    &__image-wrap-desktop {
      display: none;
    }

    &__title {
      font-size: 28px;
      margin-top: 15px;
    }

    &__label {
      font-size: 15px;
    }
  }
}
