.arrow {
  display: flex;

  &_prev {
    svg {
      transform: rotate(180deg);
    }
  }

  &_light {
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}