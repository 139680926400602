//категории на главной это
%text-catalog-category {
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
}

@include respond-up("s-large") {
  %text-catalog-category {
    font-size: 32px;
  }
}

// laptop
@include respond("xs-large") {
  %text-catalog-category {
    font-size: 24px;
  }
}

@include respond("medium") {
  %text-catalog-category {
    font-size: 20px;
  }
}

@include respond-down("small") {
  %text-catalog-category {
    font-size: 18px;
  }
}