.article-link {
  &__categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__text-wrap-top {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    text-transform: uppercase;
    display: flex;
  }

  &__image-wrap {
    margin: var(--article-link-pagging) 0;
  }

  &__text-wrap {
    padding: var(--article-link-pagging) 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-lines);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__bottom-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }
}

@include respond-up("x-large") {
  .article-link {
  }
}

@include respond-up("large") {
  .article-link {
  }
}

@include respond("large") {
  .article-link {
  }
}

@include respond-down("large") {
  .article-link {
  }
}

@include respond-up("s-large") {
  .article-link {
    &__categories {
      margin-bottom: 35px;
    }

    &__heading {
      font-size: 24px;
    }
  }
}

@include respond("s-large") {
  .article-link {
  }
}

@include respond-down("s-large") {
  .article-link {
  }
}

@include respond-up("xs-large") {
  .article-link {
    --article-link-pagging: 50px;

    &__heading {
      width: var(--grid-column5);
    }

    &__image-wrap {
      grid-column: 1/8;
    }

    &__picture {
      height: calc(var(--grid-column7) * 436 / 638);
    }

    &__image-wrap,
    &__parallax-container-inner {
      height: calc(var(--grid-column7) * 335 / 638);
      //background: red;
    }

    &__text-wrap {
      grid-column: 9/16;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .article-link {
    &__categories {
      margin-bottom: 25px;
    }

    &__heading {
      font-size: 20px;
    }
  }
}

@include respond-down("xs-large") {
  .article-link {
  }
}

@include respond-up("medium") {
  .article-link {
    &__category {
      margin-left: 20px;
    }

    &__categories {
      margin-left: -20px;
    }
  }
}

@include respond("medium") {
  .article-link {
    --article-link-pagging: 40px;

    &__categories {
      margin-bottom: 22px;
    }

    &__heading {
      width: var(--grid-column4);
      font-size: 19px;
    }

    &__image-wrap {
      grid-column: 1/5;
    }

    &__picture {
      height: calc(var(--grid-column4) * 200 / 299);
    }

    &__image-wrap,
    &__parallax-container-inner {
      height: calc(var(--grid-column4) * 154 / 299);
    }

    &__text-wrap {
      grid-column: 6/11;
    }
  }
}

@include respond-down("medium") {
  .article-link {
  }
}

@include respond-down("small") {
  .article-link {
    &__category {
      margin-left: 16px;
    }

    &__categories {
      margin-left: -16px;
      margin-bottom: 20px;
    }

    &__image-wrap {
      margin-top: 30px;
    }

    &__bottom-wrapper {
      margin-top: 5px;
    }

    &__text-wrap {
      margin-top: 22px;
      padding-bottom: 30px;
    }

    &__image-wrap,
    &__text-wrap {
      grid-column: 1/11;
    }

    &__picture {
      height: calc(var(--grid-column10) * 224 / 334);
    }

    &__image-wrap,
    &__parallax-container-inner {
      height: calc(var(--grid-column10) * 172 / 334);
    }

    &__heading {
      font-size: 16px;
      width: var(--grid-column8);
    }
  }
}