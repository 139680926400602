.footer {
  background: var(--layout-bg-by-theme);
  position: relative;
  overflow: hidden;

  &__nav-item {
    display: flex;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__image-wrap-inner {
    position: absolute;
    top: 0;
    z-index: 100;
  }

  &__image-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    svg {
      width: 100%;
      height: auto;
      opacity: .9;
    }
  }

  &__container {
    position: relative;
    z-index: 200;
  }

  &__nav-link {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    padding: 5px;
    margin: -5px;
  }

  &__contact-us-link {
    display: flex;
    align-items: flex-start;
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
    position: relative;
  }

  &__link-arrow {
    position: absolute;
    top: 0;
  }

  &__email {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
  }

  &__phone {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }

  &__copy,
  &__policy,
  &__rclass {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: var(--grey-font);
  }

  &__rclass-link {
    color: var(--primary-color);
  }

  &__policy {
    padding: 10px;
    margin: -10px;

    &:before,
    &:after {
      width: calc(100% - 20px);
      bottom: 10px;
    }
  }

  &__rclass {
    padding: 10px;
    margin: -10px;
  }

  &__policy,
  &__rclass-link {
    position: relative;

    &:before,
    &:after {
      content: '';
      height: 1px;
      display: block;
      position: absolute;
      background-color: var(--grey-lines);
    }

    &:before {
      transform-origin: right;
    }

    &:after {
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  &__rclass-link {
    &:before,
    &:after {
      width: 100%;
      bottom: -1px;
      right: 0;
    }
  }

  &__nav-list-items-wrap,
  &__nav-items-list {
    display: flex;
    flex-direction: column;
  }

  &__wrap {
    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__image-wrapper {
    display: flex;

    svg {
      height: auto;
    }
  }
}

@include respond-up('s-large') {
  .footer {
    padding: 194px 0 65px;

    &__contact-us-link {
      font-size: 45px;
    }

    &__nav-list-wrap {
      justify-content: space-between;
    }

    &__to-top-link {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-family: var(--h-font);
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 125%;
    }

    &__wrap {
      &.grid-block {
        grid-template-rows: auto 1fr;
      }
    }

    &__contact-us-link {
      grid-column: 1/7;
      grid-row: 1/2;
    }

    &__contacts {
      grid-column: 1/3;
      grid-row: 2/3;
      margin-top: 40px;
    }

    &__nav-list-wrap {
      grid-column: 9/16;
      grid-row: 1/3;
    }

    &__social-list-wrap {
      grid-column: 1/3;
    }

    &__info-wrap {
      grid-column: 9/16;
    }

    &__nav-list-items-wrap {
      width: var(--grid-column3);
    }

    &__bottom-wrap {
      margin-top: 140px;
    }
  }
}

@include respond-up('xs-large') {
  .footer {
    &__image-wrap-inner {
      left: -116px;
    }

    &__policy {
      &:hover {
        &::after {
          transition: transform .65s cubic-bezier(.215, .61, .355, 1) .65s;
          transform: scaleX(1);
        }

        &::before {
          transition: transform .65s cubic-bezier(.215, .61, .355, 1);
          transform: scaleX(0);
        }
      }
    }

    &__rclass {
      &:hover {
        .footer {
          &__rclass-link {
            &::after {
              transition: transform .65s cubic-bezier(.215, .61, .355, 1) .65s;
              transform: scaleX(1);
            }

            &::before {
              transition: transform .65s cubic-bezier(.215, .61, .355, 1);
              transform: scaleX(0);
            }
          }
        }
      }
    }

    &__info-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__phone,
    &__email,
    &__nav-link {
      transition: color var(--default-timing) var(--teaser-transition-func);

      &:hover {
        color: var(--grey-font);
      }
    }

    &__contact-us-link {
      width: fit-content;
      height: fit-content;

      &:hover {
        .link__round-arrow {
          background: white;
          //width: 44px;
          //height: 44px;
          transform: scale(1.2);
          transform-origin: center;
          svg path {
            stroke: var(--primary-color);
          }
          .link__round-arrow-icon {
            transform: translate3d(160%,-30px,0) scale(.84);
            &_clone {
              transform: translate3d(-50%, -50%, 0) scale(.84);
            }
          }
        }
      }
    }
  }
}

@include respond('xs-large') {
  .footer {
    padding: 130px 0 60px;

    &__image-wrapper {
      width: 680px;
      left: -166px;
    }

    &__contact-us-link {
      grid-column: 1/9;
      grid-row: 1;
    }

    &__contacts {
      grid-column: 1/4;
      grid-row: 2;
    }

    &__nav-list-wrap {
      grid-column: 8/16;
      grid-row: 2;
    }

    &__social-list-wrap {
      grid-column: 1/4;
    }

    &__info-wrap {
      grid-column: 8/16;
    }

    &__nav-list-items-wrap {
      width: var(--grid-column4);
    }

    &__bottom-wrap {
      margin-top: 100px;
    }
  }
}

@include respond-down('xs-large') {
  .footer {
    &__to-top-link {
      display: none;
    }
  }
}

@include respond-between('medium', 'xs-large') {
  .footer {
    &__contact-us-link {
      font-size: 40px;
    }

    &__wrap {
      &.grid-block {
        grid-row-gap: 70px;
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__contact-us-link {
      column-gap: 18px;
    }

    &__link-arrow {
      left: calc(100% + 18px);
    }

    &__email {
      font-size: 18px;
    }

    &__phone {
      font-size: 20px;
    }

    &__nav-list_mob {
      display: none;
    }

    &__nav-list-wrap {
      display: flex;
      align-items: flex-start;
    }

    &__nav-list_desc {
      display: flex;
      column-gap: var(--grid-gap);
    }

    &__nav-link {
      font-size: 15px;
    }

    &__policy-wrap {
      display: flex;

      :not(:first-child) {
        margin-left: 40px;
      }
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 130px 0 32px;

    &__image-wrapper {
      width: 671px;
    }

    &__image-wrap-inner {
      left: -223px;
    }

    &__contact-us-link {
      grid-column: 1/7;
      width: fit-content;
      grid-row: 1;
    }

    &__contacts {
      grid-column: 1/4;
      grid-row: 2;
    }

    &__nav-list-wrap {
      grid-column: 5/11;
      grid-row: 2;
    }

    &__info-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 21px;
    }

    &__social-list-wrap {
      grid-column: 1/4;
    }

    &__info-wrap {
      grid-column: 5/11;
    }

    &__nav-list-items-wrap {
      width: var(--grid-column3);
    }

    &__bottom-wrap {
      margin-top: 90px;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding: 90px 0 43px;

    &__link-arrow {
      left: calc(100% + 10px);
    }

    &__image-wrapper {
      width: 466px;
    }

    &__image-wrap-inner {
      left: -83px;
    }

    &__contact-us-link {
      font-size: 26px;
      column-gap: 10px;
    }

    &__email {
      font-size: 16px;
    }

    &__phone {
      font-size: 18px;
    }

    &__nav-list {
      &_desc {
        display: none;
      }

      &_mob {
        display: flex;
        flex-direction: column;
      }
    }

    &__nav-link {
      font-size: 14px;
    }

    &__contact-us-link {
      grid-column: 1/11;
      width: fit-content;
      grid-row: 1;
    }

    &__contacts {
      grid-column: 1/6;
      grid-row: 2;
      margin-top: 32px;
    }

    &__nav-list-wrap {
      grid-column: 1/11;
      grid-row: 3;
      margin-top: 10px;
    }

    &__info-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__social-list-wrap {
      grid-column: 1/11;
    }

    &__info-wrap {
      grid-column: 1/11;
    }

    &__nav-list-items-wrap {
      opacity: 0;
      height: 0;
      transition: opacity, height;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
      overflow: hidden;
      pointer-events: none;
    }

    &__nav-items-list {
      padding-top: 26px;
    }

    &__accordion-link {
      padding-top: 40px;
    }

    &__nav-list-column {
      &._opened {
        .footer {
          &__nav-list-items-wrap {
            height: auto;
            pointer-events: auto;
            opacity: 1;
          }

          &__accordion-link {
            &:after {
              border-width: 0 5px 7px 5px;
              border-color: transparent transparent #000000 transparent;
            }
          }
        }
      }
    }

    &__policy-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__policy {
      width: fit-content;
    }

    &__bottom-wrap {
      margin-top: 50px;

      &.grid-block {
        row-gap: 31px;
      }
    }

    &__accordion-link {
      font-family: var(--h-font);
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 130%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--grey-lines);
      position: relative;

      &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 55px;
        transform: translateY(-100%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: #000000 transparent transparent transparent;
        transition: border, opacity;
        transition-duration: var(--default-timing);
        transition-timing-function: var(--header-timing-function);
      }
    }
  }
}

