%text-main-big {
  font-family: var(--font) !important;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}

@include respond-up("medium") {
  %text-main-big {
    font-size: 18px;
  }
}

@include respond-down("small") {
  %text-main-big {
    font-size: 16px;
  }
}