.confirmation-block {
  &__block-item-description,
  &__description {
    color: var(--grey-font);
  }

  &__block-item-description {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 52px;
      background: #AEACA8;
      left: 0;
    }
  }

  &__block-list {
    counter-reset: list;
    display: flex;
    justify-content: space-between;
  }

  &__block-text-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
  }

  &__block-item {
    background: var(--dark-background-2);

    &:not(:last-child) {
      margin-right: var(--grid-gap);
    }
  }
}

@include respond-up("s-large") {
  .confirmation-block {
    padding-top: 160px;

    &__block-item {
      flex: 0 0 var(--grid-column5);
      padding: 55px 44px 60px 44px;
    }

    &__blocks {
      padding-top: 90px;
    }

    &__title {
      grid-column: 1/9;
    }

    &__description-wrapper {
      grid-column: 11/16;
    }

    &__description {
      margin-bottom: 40px;
    }

    &__block-item-description {
      margin-top: 120px;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .confirmation-block {
    padding-top: 140px;

    &__block-item {
      flex: 0 0 var(--grid-column5);
      padding: 54px 30px 60px;
    }

    &__block-item-description {
      margin-top: 104px;
    }

    &__blocks {
      padding-top: 80px;
    }

    &__title {
      grid-column: 1/9;
      padding-right: var(--grid-column1);
    }

    &__description-wrapper {
      grid-column: 11/16;
    }

    &__description {
      margin-bottom: 40px;
    }
  }
}

@include respond-up("medium") {
  .confirmation-block {
    &__block-item-description {
      padding-top: 26px;

      &:before {
        height: 4px;
        top: -4px;
      }
    }
  }
}

@include respond("medium") {
  .confirmation-block {
    padding-top: 120px;

    &__block-item {
      flex: 0 0 var(--grid-column5);
      padding: 50px 40px;
    }

    &__block-item-description {
      margin-top: 104px;
    }

    &__blocks {
      padding-top: 60px;
    }

    &__title {
      grid-column: 1/6;
    }

    &__description-wrapper {
      grid-column: 7/11;
    }

    &__description {
      margin-bottom: 40px;
    }
  }
}

@include respond-down("medium") {
  .confirmation-block {
    &__block-list {
      padding-left: var(--grid-spacer);
      padding-right: var(--grid-spacer);
      margin-right: calc(0px - var(--grid-spacer));
      margin-left: calc(0px - var(--grid-spacer));
      overflow: scroll;
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    //мега костюль, чтобы скроллилось до конца в типа сафари
    &__block-item {
      &:last-child {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          height: 1px;
          width: 1px;
          right: calc(0px - var(--grid-gap));
        }
      }
    }
  }
}

@include respond-down("small") {
  .confirmation-block {
    padding-top: 60px;

    &__block-item {
      flex: 0 0 var(--grid-column8);
      padding: 40px 25px 45px;
    }

    &__block-item-description {
      padding-top: 20px;
      margin-top: 80px;

      &:before {
        height: 3px;
        top: -3px;
      }
    }

    &__blocks {
      padding-top: 30px;
    }

    &__title,
    &__description-wrapper {
      grid-column: 1/10;
    }

    &__description-wrapper {
      margin-top: 20px;
    }

    &__description {
      margin-bottom: 25px;
    }
  }
}
