@keyframes push-arrow-diagonal {
  from {
    transform: translate(0, 0);
  }

  50% {
    transform: translate3d(100%, -100%, 0);
  }

  51% {
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}