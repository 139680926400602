.cart-preview-modal {
  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: grid;
    grid-column-gap: var(--grid-gap);
    border-top: 1px solid var(--grey-lines);

    &:last-child {
      border-bottom: 1px solid var(--grey-lines);
    }
  }

  &__button {
    background-color: black;
    align-self: flex-end;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__remove-icon {
    position: relative;
    width: 14px;
    height: 14px;
  }

  &__bottom-wrap,
  &__total-info-wrap {
    display: flex;
  }

  &__items-count {
    color: var(--grey-font);
  }

  &__list {
    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__remove-icon {
    &::before,
    &::after {
      background: var(--grey-font);
    }
  }

  &__description-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: var(--dark-background);
  }

  &__description {
    text-align: center;
  }
}

@include respond-up('xs-large') {
  .cart-preview-modal {
    &__item {
      grid-column: 1/16;
      grid-template-columns: var(--grid-column3) var(--grid-column5) var(--grid-column) var(--grid-column6);
      grid-template-areas: "order-image order-title . order-chars";
      padding: 20px 0;
    }

    &__bottom-wrap {
      margin-top: 35px;
    }

    &__change {
      &::before {
        background: var(--primary-color);
      }
    }

    &__description-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .cart-preview-modal {
    &__list {
      margin-top: 20px;
    }

    &__bottom-wrap {
      align-items: center;
      justify-content: space-between;
    }

    &__total-info-wrap {
      flex-direction: column;
      row-gap: 4px;
    }

    &__description-wrap {
      padding: 16px;
    }
  }
}

@include respond('medium') {
  .cart-preview-modal {
    &__item {
      padding: 25px 0;
      grid-template-areas: "order-image order-title"
                           "order-image order-chars";
    }

    &__bottom-wrap {
      margin-top: 5px;
      padding: 20px 0;
    }

    &__button {
      width: var(--grid-column6);
    }

    &__description-wrap {
      margin-top: 15px;
    }
  }
}

@include respond-down('medium') {
  .cart-preview-modal {
    &__inner {
      position: relative;
    }

    &__item {
      grid-column: 1/11;
      grid-template-columns: var(--grid-column4) var(--grid-column6);
    }

    &__bottom-wrap {
      background: var(--light-background);
      position: sticky;
      z-index: 10;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

@include respond-down('small') {
  .cart-preview-modal {
    &__list{
      margin-top: 15px;
    }

    &__item {
      padding: 15px 0;
      grid-template-areas: "order-image order-title"
                           "order-chars order-chars";
    }

    &__bottom-wrap {
      flex-direction: column;
      row-gap: 16px;
      margin-top: 10px;
      padding: 15px 0;
    }

    &__total-info-wrap {
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 17px;
    }

    &__button {
      width: 100%;
    }

    &__description-wrap {
      margin-top: 18px;
      padding: 8px 10px;
    }
  }
}