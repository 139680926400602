.customers-order {
  &__contacts-wrap {
    display: flex;
    flex-direction: column;
  }

  &__contacts-list {
    display: flex;
    flex-direction: column;
  }

  &__contacts-item {
    display: flex;
    border-top: 1px solid var(--grey-lines);
    &:last-child {
      border-bottom: 1px solid var(--grey-lines);
    }
  }

  &__item-link-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__messengers-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  &__messengers-item {
    padding-left: 14px;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: black;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
}

@include respond-up('s-large') {
  .customers-order {
    &__contacts-wrap {
      grid-column: 8/16;
    }

    &__contacts-item-title {
      flex: 0 0 var(--grid-column5);
    }
  }
}

@include respond-up('xs-large') {
  .customers-order {
    &__contacts-wrap {
      row-gap: 50px;
    }

    &__title {
      grid-column: 1/5;
    }

    &__wrap {
      margin-top: 80px;
    }

    &__contacts-item-link-wrap {
      flex: 0 0 var(--grid-column3);
    }
  }
}

@include respond('xs-large') {
  .customers-order {
    &__contacts-wrap {
      grid-column: 7/16;
    }

    &__contacts-item-title {
      flex: 0 0 var(--grid-column6);
    }
  }
}

@include respond-up('medium') {
  .customers-order {
    &__contacts-item {
      padding: 35px 0;
      column-gap: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .customers-order {
    &__wrap {
      &.grid-block {
        grid-row-gap: 38px;
      }
    }

    &__title {
      grid-column: 1/9;
      grid-row: 1;
    }

    &__wrap {
      margin-top: 70px;
    }

    &__contacts-wrap {
      row-gap: 45px;
    }

    &__contacts-item-title {
      flex: 0 0 var(--grid-column6);
    }

    &__contacts-item-link-wrap {
      flex: 0 0 var(--grid-column4);
    }
  }
}

@include respond-down('medium') {
  .customers-order {
    &__contacts-wrap {
      grid-column: 1/11;
      grid-row: 2;
    }
  }
}

@include respond-down('small') {
  .customers-order {
    &__wrap {
      &.grid-block {
        grid-row-gap: 23px;
      }
    }

    &__title {
      grid-column: 1/11;
      grid-row: 1;
    }

    &__wrap {
      margin-top: 35px;
    }

    &__contacts-wrap {
      row-gap: 40px;
    }

    &__contacts-item {
      padding: 25px 0;
      column-gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    }

    &__contacts-item-title {
      flex: 0 0 var(--grid-column4);
    }

    &__contacts-item-link-wrap {
      flex: 0 0 var(--grid-column5);
    }
  }
}