.cart-success {
  display: flex;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    color: var(--grey-font);
  }
}

@include respond-up('s-large') {
  .cart-success {
    column-gap: 54px;
  }
}

@include respond('xs-large') {
  .cart-success {
    column-gap: 66px;
  }
}

@include respond-up('medium') {
  .cart-success {
    align-items: center;

    &__icon {
      width: 136px;
      height: 136px;
    }

    &__text-wrap {
      row-gap: 20px;
    }
  }
}

@include respond('medium') {
  .cart-success {
    column-gap: var(--grid-gap);
  }
}

@include respond-down('small') {
  .cart-success {
    flex-direction: column;

    &__icon {
      width: 80px;
      height: 80px;
    }

    &__text-wrap {
      margin-top: 20px;
      row-gap: 10px;
    }
  }
}