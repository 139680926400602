.index-page-news {
}

@include respond-up("s-large") {
  .index-page-news {
    padding-top: 100px;
  }
}

@include respond("xs-large") {
  .index-page-news {
    padding-top: 80px;
  }
}

@include respond("medium") {
  .index-page-news {
    padding-top: 80px;
  }
}

@include respond-down("small") {
  .index-page-news {
    padding-top: 50px;
  }
}