.requisites {
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

@include respond-up('s-large') {
  .requisites {
    padding: 120px 0 20px;
  }
}

@include respond-up('xs-large') {
  .requisites {
    &__title {
      grid-column: 1/5;
    }

    &__list {
      grid-column: 7/16;
    }
  }
}

@include respond('xs-large') {
  .requisites {
    padding: 100px 0 20px;
  }
}

@include respond-up('medium') {
  .requisites {

  }
}

@include respond('medium') {
  .requisites {
    padding: 90px 0 20px;

    &__title {
      grid-column: 1/5;
    }

    &__list {
      grid-column: 5/11;
    }
  }
}

@include respond-down('medium') {
  .requisites {

  }
}

@include respond-down('small') {
  .requisites {
    padding: 50px 0 8px;

    &__wrap {
      &.grid-block {
        grid-row-gap: 18px;
      }
    }

    &__title {
      grid-column: 1/11;
      grid-row: 1;
    }

    &__list {
      grid-column: 1/11;
      grid-row: 2;
    }
  }
}