.product-materials {
  border-top: 1px solid var(--grey-lines);
  overflow: hidden;

  &__inner {
    grid-row-gap: unset;
  }

  &__title {
    width: 100%;
  }

  &__button {
    &_mobile {
      display: none;
    }
  }

  &__nav {
    display: flex;
  }

  &__slide {
    position: relative;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        .product-materials__slide-link {
          transition-delay: calc(#{$i} * .15s);
        }
      }
    }
  }

  &__slide-link {
    display: flex;
    width: 100%;
    height: 100%;
    transition: opacity 1.2s cubic-bezier(.4,0,0,1), transform 1.2s cubic-bezier(.4,0,0,1);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform .4s var(--default-transition-function);
  }

  &__slide-title-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-bg-opacity-30);
  }

  &__slide-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: uppercase;
    color: white;
  }

  &__slider-block {
    overflow: hidden;
  }

  &__slider {
    &.swiper {
      overflow: visible;
    }
  }

  &__title-inner {
    display: block;
    overflow: hidden;

    span {
      transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc(#{$i} * .05s);
        }
      }
    }
  }

  &__description-wrap,
  &__button,
  &__arrows {
    transition: transform .5s .5s, opacity .5s .5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.need-animation {
    .product-materials {
      &__title-inner {
        span {
          transform: translate3d(0, 100%, 0);
        }
      }

      &__description-wrap,
      &__button,
      &__arrows {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }

      &__slide-link {
        transform: translate3d(0, 60px, 0);
        opacity: 0;
      }
    }
  }
}

@include respond-up('s-large') {
  .product-materials {
    &__text-block,
    &__slider-block {
      padding: 40px var(--grid-spacer) 159px 0;
    }

    &__description-wrap {
      margin-top: 30px;
    }

    &__slide-title {
      font-size: 20px;
    }
  }
}

@include respond-up('xs-large') {
  .product-materials {
    &__inner {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(var(--grid-column5) + var(--grid-gap) + var(--grid-column) / 2);
        border-left: 1px solid var(--grey-lines);
        height: 100%;
      }
    }

    &__text-block {
      grid-column: 1/6;
    }

    &__slider-block {
      grid-column: 7/16;
      margin-right: calc(var(--grid-spacer) * -1);
      padding-right: var(--grid-spacer);
    }

    &__slide {
      &.swiper-slide {
        width: calc(var(--grid-column) * 4.31 + var(--grid-gap) * 4);
        height: calc(var(--grid-column) * 4.31 + var(--grid-gap) * 4);
        max-width: 587px;
        max-height: 587px;
      }

      &:not(:last-child) {
        margin-right: calc(var(--grid-column) * 0.38);
      }
    }

    &__button {
      margin-top: 45px;
    }

    &__nav {
      justify-content: flex-end;
      margin-top: 60px;
    }

    &__slide-link {
      &:hover {
        .product-materials__image {
          transform: scale3d(1.05, 1.05, 1);
        }
      }
    }
  }
}

@include respond('xs-large') {
  .product-materials {
    &__text-block,
    &__slider-block {
      padding: 40px var(--grid-spacer) 140px 0;
    }

    &__description-wrap {
      margin-top: 25px;
    }

    &__slide-title {
      font-size: 18px;
    }
  }
}

@include respond-up('medium') {
  .product-materials {
    &__pagination-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .product-materials {
    &__inner {
      padding: 35px 0 120px;
      grid-row-gap: 50px;
    }

    &__nav {
      justify-content: space-between;
      margin-top: 50px;
    }

    &__description-wrap {
      margin-top: 35px;
      display: flex;
      justify-content: flex-end;
    }

    &__description {
      width: var(--grid-column6);
    }

    &__slide-title {
      font-size: 17px;
    }
  }
}

@include respond-down('medium') {
  .product-materials {
    &__text-block,
    &__slider-block {
      grid-column: 1/11;
    }

    &__slider-block {
      margin: 0 calc(var(--grid-spacer) * -1);
      padding: 0 var(--grid-spacer);
    }

    &__button {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }

    &__slide {
      &.swiper-slide {
        width: var(--grid-column5);
        height: var(--grid-column5);
      }
    }
  }
}

@include respond-down('small') {
  .product-materials {
    &__arrows {
      display: none;
    }

    &__inner {
      padding: 25px 0 60px;
      grid-row-gap: 30px;
    }

    &__description-wrap {
      margin-top: 18px;
    }

    &__nav {
      margin-top: 40px;
    }

    &__slide-title {
      font-size: 16px;
    }

    &__pagination-wrap {
      position: relative;
      height: 2px;
      margin-top: 18px;

      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 0;
      }
    }
  }
}