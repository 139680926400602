.total-field {
  display: flex;
  border: 1px solid var(--grey-lines);
  width: 138px;

  &__dec,
  &__inc {
    position: relative;
    width: 45px;
    height: 41px;
    flex: 0 0 auto;
  }

  &__dec {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 15px;
      height: 1px;
      background-color: var(--primary-color);
    }
  }

  &__inc {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 15px;
      height: 1px;
      background-color: var(--primary-color);
    }

    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }

  &__input {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    font-size: 14px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--h-font);
    flex: 0 1 100%;
    background-color: var(--light-background);
    font-weight: 500;
    line-height: 120%;
    color: #000;
    opacity: 1;

    &[type=number] {
      height: unset;
      padding: 0;
      margin: 0;
      text-align: center;
    }

    &:disabled {
      opacity: 1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@include respond-up('large') {
  .total-field {

  }
}

@include respond-up('medium') {
  .total-field {

  }
}

@include respond('medium') {
  .total-field {

  }
}

@include respond-down('medium') {
  .total-field {
    width: 102px;

    &__input {
      font-size: 13px;
      line-height: 130%;
    }

    &__dec,
    &__inc {
      width: 37px;
      height: 29px;
    }

    &__dec::before,
    &__inc::before,
    &__inc::after {
      width: 13px;
    }
  }
}

@include respond-down('small') {
  .total-field {
    &__input {
      font-size: 15px;
    }
  }
}