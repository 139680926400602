.about-advantages {
  padding-top: var(--about-advantages-padding-top);
  padding-bottom: var(--about-advantages-padding-bottom);
  background: #DFDCD7;
  overflow: hidden;

  &__advantages-item-icon {
    display: flex;
    max-width: 258px;

    svg {
      width: 100%;
    }
  }

  &__advantages-list {
    counter-reset: list;
  }

  &__advantages-item-title-counter {
    position: relative;
    flex: 0 0 36px;
  }

  &__advantages-item-title {
    position: relative;
    border-top: 1px solid var(--grey-lines);
    display: flex;

    .animation-item__inner {
      &:first-child {
        position: relative;
      }
    }
  }

  &__advantages-item-description {
    color: var(--grey-font);

    .animation-item {
      opacity: 0;
    }
  }

  .animate-out {
    .about-advantages {
      &__advantages-item-description {
        .animation-item {
          opacity: 1;
        }
      }
    }
  }

  &__advantages {
    padding-top: var(--about-advantages-padding-bottom);
  }

  &__quote-block-line {
    height: 1px;
    background: var(--grey-lines);
  }

  &__quotation-title {
    color: var(--grey-font);
  }

  &__quote-block {
    padding-top: var(--quote-block-padding-top);
    grid-template-rows: auto 1fr;
  }

  .grid-block {
    row-gap: 0;
  }

  &__description {
    color: #959493;
    text-align: center;
    padding-top: var(--about-advantages-decsription-padding-top);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("large") {
  .about-advantages {
    &__advantages-item-description {
      grid-column: 4/10;
    }

    &__advantages-item-icon {
      grid-column: 1/3;
    }
  }
}

@include respond-up("s-large") {
  .about-advantages {
    --about-advantages-padding-top: 120px;
    --about-advantages-decsription-padding-top: 150px;
    --quote-block-padding-top: 30px;

    &__advantages-item-accordion-block-wrapper {
      grid-column: 7/16;
    }

    &__advantages-item-accordion-block-wrapper-inner {
      display: grid;
      grid-template-columns: repeat(9, var(--grid-column));
      grid-column-gap: var(--grid-gap);
    }

    &__advantages-item {
      padding-bottom: 100px;
    }

    &__advantages-list-wrapper {
      margin-top: 40px;
    }

    &__advantages-item-title {
      grid-column: 1/6;
      padding-top: 25px;
    }

    &__quote-block-line {
      grid-column: 7/16;
      grid-row: 1/2;
    }

    &__quotation-quote {
      grid-column: 7/8;
      grid-row: 2/3;
      margin-top: 45px;
    }

    &__quotation-wrapper {
      grid-column: 8/16;
      grid-row: 2/3;
      margin-top: 45px;
    }

    &__quotation-title {
      margin-top: 70px;
    }

    &__image-wrap-first {
      grid-column: 1/3;
      margin-left: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(276 / 263 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(317 / 263 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-second {
      grid-column: 7/10;
      margin-top: 90px;

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(273 / 258 * var(--grid-column3));
        }

        &__picture {
          height: calc(314 / 258 * var(--grid-column3));
        }
      }
    }

    &__image-wrap-third {
      grid-column: 14/16;
      margin-top: -100px;
      margin-right: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(278 / 262 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(320 / 262 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-fourth {
      grid-column: 1/5;
      grid-row: 1/3;

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(360 / 353 * var(--grid-column4));
        }

        &__picture {
          height: calc(414 / 353 * var(--grid-column4));
        }
      }
    }

    &__description {
      grid-column: 4/13;
    }
  }
}

@include respond("s-large") {
  .about-advantages {
    &__advantages-item-description {
      grid-column: 5/10;
    }

    &__advantages-item-icon {
      grid-column: 1/4;
    }
  }
}

@include respond-up("xs-large") {
  .about-advantages {
    --about-advantages-padding-bottom: 140px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .about-advantages {
    --about-advantages-padding-top: 80px;
    --about-advantages-decsription-padding-top: 80px;
    --quote-block-padding-top: 60px;

    &__advantages-item-accordion-block-wrapper {
      grid-column: 7/16;
    }

    &__advantages-item-accordion-block-wrapper-inner {
      display: grid;
      grid-template-columns: repeat(9, var(--grid-column));
      grid-column-gap: var(--grid-gap);
    }

    &__advantages-item {
      padding-bottom: 80px;
    }

    &__advantages-list-wrapper {
      margin-top: 40px;
    }

    &__advantages-item-title {
      grid-column: 1/6;
      padding-top: 25px;
    }

    &__advantages-item-icon {
      grid-column: 1/4;
    }

    &__advantages-item-description {
      grid-column: 5/10;
    }

    &__description {
      grid-column: 4/13;
      --about-advantages-decsription-padding-top: 90px;
    }

    &__quote-block-line {
      grid-column: 7/16;
      grid-row: 1/2;
    }

    &__quotation-quote {
      grid-column: 7/8;
      grid-row: 2/3;
      margin-top: 45px;
    }

    &__quotation-wrapper {
      grid-column: 8/16;
      grid-row: 2/3;
      margin-top: 45px;
    }

    &__quotation-title {
      margin-top: 60px;
    }

    &__image-wrap-first {
      grid-column: 1/3;
      margin-left: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(175 / 167 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(201 / 167 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-second {
      grid-column: 7/10;
      margin-top: 100px;

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(184 / 174 * var(--grid-column3));
        }

        &__picture {
          height: calc(212 / 174 * var(--grid-column3));
        }
      }
    }

    &__image-wrap-third {
      grid-column: 14/16;
      margin-top: -115px;
      margin-right: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(177 / 167 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(204 / 167 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-fourth {
      grid-column: 1/5;
      grid-row: 1/3;

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(246 / 241 * var(--grid-column4));
        }

        &__picture {
          height: calc(283 / 241 * var(--grid-column4));
        }
      }
    }
  }
}

@include respond-up("medium") {
  .about-advantages {
    &__advantages-item-accordion-block-wrapper {
      height: auto !important;
    }

    &__advantages-item-title {
      &:hover {
        cursor: initial;
      }
    }
  }
}

@include respond("medium") {
  .about-advantages {
    --about-advantages-padding-top: 80px;
    --about-advantages-padding-bottom: 120px;
    --about-advantages-decsription-padding-top: 40px;
    --quote-block-padding-top: 60px;

    &__advantages-item {
      padding-bottom: 60px;
      grid-template-rows: 1fr auto;

      &.grid-block {
        grid-row-gap: 50px;
      }
    }

    &__advantages-list-wrapper {
      margin-top: 45px;
    }

    &__advantages-item-title {
      grid-row: 1/2;
      grid-column: 1/6;
      padding-top: 25px;
    }

    &__advantages-item-icon {
      grid-column: 1/4;
      grid-row: 2/3;
    }

    &__advantages-item-description {
      grid-column: 6/11;
      grid-row: 2/3;
    }

    &__description {
      grid-column: 2/10;
    }

    &__quote-block-line {
      grid-column: 5/11;
      grid-row: 1/2;
    }

    &__quotation-quote {
      grid-column: 5/6;
      grid-row: 2/3;
      margin-top: 40px;
    }

    &__quotation-wrapper {
      grid-column: 6/11;
      margin-left: calc(0px - var(--grid-gap));
      grid-row: 2/3;
      margin-top: 40px;
    }

    &__quotation-title {
      margin-top: 50px;
    }

    &__image-wrap-first {
      grid-column: 1/3;
      margin-left: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(178 / 170 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(205 / 170 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-second {
      grid-column: 4/8;
      margin-top: 115px;
      padding-left: var(--grid-column1);
      padding-right: var(--grid-column1);

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(180 / 170 * (var(--grid-column2) + 2 * var(--grid-gap)));
        }

        &__picture {
          height: calc(207 / 170 * (var(--grid-column2) + 2 * var(--grid-gap)));
        }
      }
    }

    &__image-wrap-third {
      grid-column: 9/11;
      margin-top: 40px;
      margin-right: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(180 / 170 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(207 / 170 * (var(--grid-column2) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-fourth {
      grid-column: 1/4;
      grid-row: 1/3;

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(231 / 220 * var(--grid-column3));
        }

        &__picture {
          height: calc(266 / 220 * var(--grid-column3));
        }
      }
    }
  }
}

@include respond-down("medium") {
  .about-advantages {
    &__advantages-item-accordion-block-wrapper {
      grid-column: 1/11;
    }

    &__advantages-item-accordion-block-wrapper-inner {
      display: grid;
      grid-template-columns: repeat(var(--grid-columns-number), var(--grid-column));
      grid-column-gap: var(--grid-gap);
    }
  }
}

@include respond-down("small") {
  .about-advantages {
    --about-advantages-padding-top: 40px;
    --about-advantages-padding-bottom: 60px;
    --about-advantages-decsription-padding-top: 35px;
    --quote-block-padding-top: 60px;

    &__advantages-item {
      overflow: hidden;

      &._opened {
        .about-advantages {
          &__advantages-item-accordion-block-wrapper {
            height: auto;
            opacity: 1;
          }

          &__advantages-item-title {
            &:after {
              transform: translateY(-50%) rotate(180deg);
            }

            &:before {
              width: 2px;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    &__advantages-item-accordion-block-wrapper-inner {
      padding-bottom: 40px;
    }

    &__advantages-item-accordion-block-wrapper {
      height: 0;
      opacity: 0;
      transition: opacity, height;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
    }

    &__advantages-list-wrapper {
      margin-top: 18px;
    }

    &__advantages-item-title {
      grid-column: 1/11;
      padding: 25px var(--grid-column1) 25px 0;
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        height: 2px;
        width: 12px;
        background: black;
        transition: transform, width;
        transition-duration: var(--default-timing);
        transition-timing-function: var(--default-transition-function);
      }

      &:after {
        transform: translateY(-50%) rotate(90deg);
      }

      &:before {
        transform: translateY(-50%);
      }
    }

    &__advantages-item-icon {
      grid-column: 3/9;
      margin-top: 5px;
    }

    &__advantages-item-description {
      grid-column: 1/11;
      margin-top: 45px;
    }

    &__advantages-list-wrapper {
      border-bottom: 1px solid var(--grey-lines);
    }

    // --

    &__description {
      grid-column: 1/11;
    }

    &__quote-block-line {
      grid-column: 1/11;
      grid-row: 1/2;
    }

    &__quotation-quote {
      grid-column: 1/3;
      grid-row: 3/4;
      margin-top: 45px;
      justify-self: end;
      margin-right: 24px;
    }

    &__quotation-wrapper {
      grid-column: 3/11;
      grid-row: 3/4;
      margin-top: 45px;
    }

    &__quotation-title {
      margin-top: 40px;
    }

    &__image-wrap-first {
      grid-column: 1/4;
      margin-left: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(120 / 115 * (var(--grid-column3) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(138 / 115 * (var(--grid-column3) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-second {
      grid-column: 2/6;
      margin-top: 78px;

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(137 / 129 * var(--grid-column4));
        }

        &__picture {
          height: calc(158 / 129 * var(--grid-column4));
        }
      }
    }

    &__image-wrap-third {
      grid-column: 8/11;
      margin-top: 35px;
      margin-right: calc(0px - var(--grid-spacer-and-indent));

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(122 / 115 * (var(--grid-column3) + var(--grid-spacer-and-indent)));
        }

        &__picture {
          height: calc(140 / 115 * (var(--grid-column3) + var(--grid-spacer-and-indent)));
        }
      }
    }

    &__image-wrap-fourth {
      grid-column: 1/7;
      grid-row: 2/3;
      margin-top: 25px;

      .about-advantages {
        &__parallax-container-inner,
        &__parallax-container {
          height: calc(206 / 196 * var(--grid-column6));
        }

        &__picture {
          height: calc(237 / 196 * var(--grid-column6));
        }
      }
    }
  }
}
