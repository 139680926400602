body {
  // перекрыть стили для слайдера материалов

  &.glightbox-mobile {
    .goverlay {
      background: var(--light-background);
    }
  }

  .goverlay {
    background: var(--light-background);
  }

  .gloader {
    border: 2px solid #000;
    border-right-color: var(--t);
  }

  .gclose.gbtn {
    opacity: 1;
    background-color: var(--t);
    border: 1px solid var(--grey-lines);
    border-radius: 100%;
    width: 40px;
    height: 40px;

    svg {
      width: 16px;

      path {
        fill: black
      }
    }
  }

  .gnext.gbtn,
  .gprev.gbtn {
    background: {
      color: var(--t);
      image: url('../images/svg/arrow.svg');
      repeat: no-repeat;
      position: center right;
      size: auto;
    };

    svg {
      display: none;
    }

    &:hover {
      background-color: var(--t);
    }
  }

  .glightbox-clean .gprev,
  .glightbox-clean .gnext {
    height: 60px;
  }


  .gprev.gbtn {
    transform: rotate(180deg);
  }

  .gslide-media.gslide-image {
    box-shadow: unset;
    img {
      cursor: none!important;
    }
  }

  .glightbox-clean {
    .gdesc-inner {
      padding: 30px 0;
    }

    .gslide-description {
      background: var(--t);
    }
  }
}

@include respond-up('s-large') {
  body {
    .gnext.gbtn {
      right: 152px;
    }

    .gprev.gbtn {
      left: 152px;
    }

    .glightbox-clean .gdesc-inner {
      padding: 30px 0;
    }
  }
}

@include respond-up('xs-large') {
  body {
    .gclose.gbtn {
      svg {
        transition: transform .3s var(--default-transition-function);
      }

      &:hover {
        background-color: var(--t);

        svg {
          transform: rotate(90deg);
        }
      }
    }

    .gslide-media.gslide-image {
      img {
        width: var(--grid-column7);
      }
    }

    .glightbox-clean .gprev,
    .glightbox-clean .gnext {
      width: 80px;
    }
  }
}

@include respond('xs-large') {
  body {
    .gnext.gbtn {
      right: 195px;
    }

    .gprev.gbtn {
      left: 195px;
    }

    .glightbox-clean .gdesc-inner {
      padding: 25px 0;
    }
  }
}

@include respond-up('medium') {
  body {

  }
}

@include respond('medium') {
  body {
    .glightbox-clean .gdesc-inner {
      padding: 20px 0;
    }

    .gslide-media.gslide-image {
      img {
        width: var(--grid-column8);
      }
    }

    .glightbox-clean .gprev,
    .glightbox-clean .gnext {
      width: 55px;
    }
  }
}

@include respond-down('medium') {
  body {
    &.glightbox-mobile {
      .goverlay {
        background: var(--light-background);
      }

      .glightbox-container .gslide-description {
        flex: 0 1 auto;
        height: auto;
        width: 100%;
        padding: 0;
        position: relative;
        bottom: unset;
        order: unset;
        max-height: unset;
        overflow: unset;
        background: unset;
        margin: 0 auto;
      }

      .gprev.gbtn {
        top: unset;
        bottom: 20px;
        left: 50%;
        transform: rotate(180deg) translate3d(calc(100% + 20px), 0, 0);
      }

      .gnext.gbtn {
        top: unset;
        bottom: 20px;
        left: 50%;
        transform: translate3d(20px, 0, 0);
      }

      .glightbox-container .gslide-title {
        color: black;
      }
    }
  }
}

@include respond-down('small') {
  body {
    &.glightbox-mobile {
      .glightbox-container .gslide-description {
        //width: var(--grid-column10);
      }
    }

    .glightbox-clean .gdesc-inner {
      padding: 18px 0;
    }

    .gslide-media.gslide-image {
      img {
        width: var(--grid-column10);
      }
    }

    .glightbox-clean .gprev,
    .glightbox-clean .gnext {
      width: 50px;
    }
  }
}