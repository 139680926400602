.about {
background: var(--light-background);
}

@include respond-up("x-large") {
  .about {
  }
}

@include respond-up("large") {
  .about {
  }
}

@include respond("large") {
  .about {
  }
}

@include respond-down("large") {
  .about {
  }
}

@include respond-up("s-large") {
  .about {
  }
}

@include respond("s-large") {
  .about {
  }
}

@include respond-down("s-large") {
  .about {
  }
}

@include respond-up("xs-large") {
  .about {
  }
}

// laptop 1100
@include respond("xs-large") {
  .about {
  }
}

@include respond-down("xs-large") {
  .about {
  }
}

@include respond-up("medium") {
  .about {
  }
}

@include respond("medium") {
  .about {
  }
}

@include respond-down("medium") {
  .about {
  }
}

@include respond-down("small") {
  .about {
  }
}
