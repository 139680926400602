.cart-pay {
  &__label-description {
    color: var(--grey-font);
  }

  &__radio-item {
    border-top: 1px solid var(--grey-lines);

    &:last-child {
      border-bottom: 1px solid var(--grey-lines);
    }
  }

  &__radio {
    input[type="radio"] {
      display: none;

      &:checked {
        + label {
          .cart-pay__label-title {
            &::before {
              background: var(--primary-color);
            }
          }
        }
      }
    }
  }

  &__input-label {
    display: flex;
    flex-direction: column;
  }

  &__label-title {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      border-radius: 50%;
      border: 2px solid var(--primary-color);
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      transition: background var(--default-timing) var(--default-bezier);
    }
  }

  &__card-wrap {
    display: flex;
  }

  &__form {
    transition: opacity var(--default-timing) var(--default-bezier);

    &._hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}

@include respond-up('s-large') {
  .cart-pay {
    &__switcher {
      flex: 0 0 var(--grid-column4);
    }

    &__form {
      flex: 0 0 var(--grid-column5);
    }
  }
}

@include respond-up('xs-large') {
  .cart-pay {
    margin-top: 85px;

    &__input-label {
      cursor: pointer;
    }
  }
}

@include respond('xs-large') {
  .cart-pay {
    &__switcher {
      flex: 0 0 var(--grid-column6);
    }

    &__form {
      flex: 0 0 var(--grid-column4);
    }
  }
}

@include respond-up('medium') {
  .cart-pay {
    &__input-label {
      row-gap: 16px;
      padding: 20px 0;
    }

    &__label-title {
      padding-left: 43px;

      &::before {
        width: 18px;
        height: 18px;
      }
    }

    &__card-wrap {
      margin-top: 28px;
      align-items: center;
      column-gap: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .cart-pay {
    margin-top: 70px;

    &__switcher {
      flex: 0 0 var(--grid-column5);
    }

    &__form {
      flex: 0 0 var(--grid-column5);
    }
  }
}

@include respond-down('small') {
  .cart-pay {
    margin-top: 45px;

    &__input-label {
      row-gap: 12px;
      padding: 15px 0;
    }

    &__label-title {
      padding-left: 32px;

      &::before {
        width: 14px;
        height: 14px;
      }
    }

    &__card-wrap {
      margin-top: 30px;
      flex-direction: column;
      row-gap: 18px;
    }

    &__form {
      height: 57px;
      transition: {
        property: opacity, height;
        duration: var(--default-timing);
        timing-function: var(--default-bezier);
      };

      &._hidden {
        height: 0;
      }
    }
  }
}