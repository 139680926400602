.mini-date {
  color: var(--text-and-border-color-by-theme);
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  position: relative;
  padding-left: 18px;
  font-size: 14px;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: var(--text-and-border-color-by-theme);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
