.filter-dropdown {
  display: flex;
  align-items: center;

  .filter-field {
    &__label {
      font-family: var(--h-font);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: var(--grey-font) !important;
    }

    &__wrapper {
      padding-right: 18px;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: #000000 transparent transparent transparent;
      }

      select {
        background-color: var(--t);
        border: unset;
        color: var(--primary-color) !important;
        font-family: var(--h-font);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        appearance: none;
      }
    }
  }
}

@include respond-up('medium') {
  .filter-dropdown {
    .filter-field {
      &__wrapper {
        margin-left: 10px;
      }
    }
  }
}

@include respond-down('small') {
  .filter-dropdown {
    .filter-field {
      &__label {
        display: none;
      }
    }
  }
}