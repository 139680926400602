.categories-filter {
  .filter-form {
    &__tags-list {
      display: flex;
      flex-wrap: wrap;

      input[type=checkbox] {
        display: none;
      }

      label {
        transition: color var(--default-timing) var(--teaser-transition-func);
        cursor: pointer;
        padding: 10px;
        margin: -10px;
        &:before {
          content: '#';
          @extend %text-small-points;
        }
      }

      input[type=checkbox]:checked+label {
        color: black;
      }
    }
  }
}

@include respond-up('s-large') {
  .categories-filter {

  }
}

@include respond-up('xs-large') {
  .categories-filter {
    .filter-form {
      &__tags-list {
        input[type=checkbox]:hover+label {
          color: black;
        }
      }
    }
  }
}

@include respond('xs-large') {
  .categories-filter {

  }
}

@include respond-up('medium') {
  .categories-filter {
    .filter-form {
      &__tags-list {
        column-gap: 22px;
      }
    }
  }
}

@include respond('medium') {
  .categories-filter {

  }
}

@include respond-down('medium') {
  .categories-filter {

  }
}

@include respond-down('small') {
  .categories-filter {
    .filter-form {
      &__tags-list {
        column-gap: 18px;
        row-gap: 12px;
      }
    }
  }
}