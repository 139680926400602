.social-icons {
  &__list {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
  }

  &__link {
    width: 34px;
    height: 34px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background var(--default-timing) var(--teaser-transition-func);
    border-radius: 50%;

    svg path {
      transition: fill var(--default-timing) var(--teaser-transition-func);
    }
  }
}

@include respond-up('xs-large') {
  .social-icons {
    &__link {
      &:hover {
        background: var(--grey-light);
        svg path {
          fill: var(--primary-color);
        }
      }
    }
  }
}
