.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 4700;

  &__inner {
    position: relative;
  }

  &__menu {
    z-index: 5500;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__container {
    position: relative;
    z-index: 4650;
    will-change: transform;

    &._sticky {
      transform: translate3d(0, -100%, 0);
    }
  }

  &__inner {
    background: var(--light-background);
    transition: background-color .5s var(--header-timing-function);
  }

  &._sticky._up {
    .header {
      &__container {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &._transparent {
    &._sticky {
      .hamburger {
        &__top,
        &__bottom {
          &:before,
          &:after {
            background: var(--primary-color);
          }
        }
      }

      .header {
        &__inner {
          background: var(--light-background);
        }

        &__cart-wrap,
        &__link,
        &__modal {
          color: var(--primary-color);

          &:before,
          &:after {
            background: var(--primary-color);
          }
        }

        &__cart-icon {
          svg path {
            stroke: var(--primary-color);
          }
        }

        &__logo {
          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }

    .hamburger {
      &__top,
      &__bottom {
        &:before,
        &:after {
          background: white;
        }
      }
    }

    .header {
      &__container {
        pointer-events: auto;
      }

      &__inner {
        background-color: var(--t);
      }

      &__cart-wrap,
      &__link,
      &__modal {
        color: #fff;

        &:before,
        &:after {
          background: #fff;
        }
      }

      &__cart-icon {
        svg path {
          stroke: #fff;
        }
      }

      &__logo {
        svg path {
          fill: #fff;
        }
      }
    }
  }

  &__link,
  &__modal {
    transition: color .5s var(--header-timing-function);

    &:before,
    &:after {
      transition: background .5s var(--header-timing-function);
    }
  }

  &__logo {
    svg path {
      transition: fill .5s var(--header-timing-function);
    }
  }

  &__wrap {
    height: var(--header-height);
    display: flex;
    align-items: center;
    position: relative;
  }

  &__nav-wrap {
    display: flex;
    justify-content: space-between;
    flex: auto;
    height: 100%;
  }

  &__nav-list,
  &__contacts-list {
    display: flex;
    flex-direction: row;
    column-gap: 35px;
  }

  &__link {
    line-height: 115%;
    text-transform: uppercase;
  }

  &__logo,
  &__nav-list,
  &__contacts-list {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__cart-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--primary-color);
        transition: stroke var(--default-timing) var(--default-bezier);
      }
    }
  }

  &__cart-count {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 120%;
    color: #FFF;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}

@include respond-up('s-large') {
  .header {
    &__wrap {
      column-gap: var(--grid-gap);
    }

    &__logo {
      flex: 0 0 var(--grid-column2);
    }

    &__contacts-list {
      padding-left: 26px;
    }
  }
}

@include respond-up('xs-large') {
  .header {
    &__logo {
      svg {
        margin-top: -5px;
      }
    }

    &__nav-list {

    }

    &__contacts-list {

    }

    &__link {
      &.link {
        padding-bottom: 4px;
      }
    }

    &__modal {
      &.link {
        padding-bottom: 4px;
      }
    }
  }
}

@include respond('xs-large') {
  .header {
    &__logo {
      flex: 0 0 var(--grid-column3);
    }

    &__contacts-list {
      flex: 0 0 calc(var(--grid-gap) + var(--grid-column2));
      justify-content: flex-end;
    }
  }
}

@include respond-down('xs-large') {
  .header {
    &__contact-item {
      &_modal {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &__cart-wrap {
      column-gap: 14px;
    }

    &__cart-count {
      width: 16px;
      height: 16px;
      font-size: 12px;
      top: -3px;
      right: -11px;
    }
  }
}

@include respond('medium') {
  .header {
    &__logo {
      flex: 0 0 var(--grid-column2);
    }

    &__wrap {
      column-gap: var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .header {
    &__wrap {
      justify-content: space-between;
    }

    &__link-item {
      &_catalog,
      &_customers {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .header {
    &__logo {
      svg {
        width: 86px;
        height: auto;
      }
    }

    &__contact-item {
      &_phone {
        display: none;
      }
    }

    &__cart-wrap {
      column-gap: 12px;

      &._price {
        flex-direction: row-reverse;

        .header {
          &__link {
            display: inline;
          }
        }
      }
    }

    &__cart-count {
      width: 14px;
      height: 14px;
      font-size: 9px;
      top: -2px;
      right: -9px;
      line-height: unset;
      align-items: flex-end;
    }

    &__link {
      display: none;
    }
  }
}