.review-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__bottom-wrap {
    display: flex;
    flex-direction: column;
  }

  &__link-image-wrap {
    display: flex;
    &._hide {
      display: none;
    }
    &:nth-child(4) {
      position: relative;
    }
  }

  &__count-another-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49));
    font-size: 18px;
    line-height: 120%;
    color: white;
  }

  &__date {
    font-size: 13px;
  }

  &__info-wrap {
    font-size: 15px;
  }

  &__count-another-image,
  &__date,
  &__info-wrap {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
  }

  &__date,
  &__info-wrap {
    line-height: 125%;
    text-transform: uppercase;
  }

  &__picture {
    display: flex;
    width: 65px;
    height: 65px;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image-list {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
  }

  &__text-block {
    transition: height var(--default-timing) var(--teaser-transition-func);
    height: 300px;
    overflow: hidden;
    pointer-events: none;
  }

  &__text-link {
    margin-top: 20px;
  }

  &__text-element {
    &._opened {
      .review-item {
        &__text-block {
          height: auto;
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }

  &__accordion-link-icon {
    svg {
      transition: transform var(--default-timing) var(--teaser-transition-func);
    }

    &._changed {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

@include respond-up('s-large') {
  .review-item {
    grid-column: span 5;

    &:nth-child(3n) {
      align-content: flex-end;
    }

    &:nth-child(3n - 1) {
      align-content: center;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        top: 0;
        right: calc(-1 * var(--grid-gap));
        background: var(--grey-lines);
      }
    }

    &:nth-child(3n - 2) {
      border-right: 1px solid var(--grey-lines);
      align-content: flex-start;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: -1px;
        left: 0;
        height: 1px;
        width: var(--grid-column15);
        background: var(--grey-lines);
      }
    }

    &__review-text {
      width: calc(var(--grid-column4) + var(--grid-gap) + 18px);
    }
  }
}

@include respond-up('xs-large') {
  .review-item {
    padding-top: 45px;

    &__review-text {
      margin-top: 45px;
    }

    &__bottom-wrap {
      margin-top: 55px;
    }
  }
}

@include respond('xs-large') {
  .review-item {
    flex: 0 0 var(--grid-column7);

    &:nth-child(2n - 1) {
      position: relative;
      &:before,
      &:after {
        position: absolute;
        content: '';
        background: var(--grey-lines);
        top: 0;
      }

      &:before {
        left: 0;
        height: 1px;
        width: var(--grid-column15);
      }

      &:after {
        width: 1px;
        height: 100%;
        right: calc(-1 * (var(--grid-gap) + (var(--grid-column) / 2)));
      }
    }
  }
}

@include respond-up('medium') {
  .review-item {
    &__header {
      column-gap: 22px;
    }

    &__bottom-wrap {
      row-gap: 30px;
    }
  }
}

@include respond('medium') {
  .review-item {
    padding-top: 40px;
    grid-column: span 5;

    &:nth-child(2n - 1) {
      position: relative;
      align-content: flex-start;
      &:before,
      &:after {
        position: absolute;
        content: '';
        background: var(--grey-lines);
        top: 0;
      }

      &:before {
        left: 0;
        height: 1px;
        width: var(--grid-column10);
      }

      &:after {
        width: 1px;
        height: 100%;
        right: calc(-1 * (var(--grid-gap) / 2));
      }
    }

    &:nth-child(2n) {
      align-content: flex-end;
    }

    &__review-text {
      margin-top: 35px;
    }

    &__bottom-wrap {
      margin-top: 45px;
    }

    &__review-text {
      width: calc(var(--grid-column4) + var(--grid-gap) + (var(--grid-column) / 2));
    }
  }
}

@include respond-down('medium') {
  .review-item {
    &__text-link {
      margin-top: 0;
    }
  }
}

@include respond-down('small') {
  .review-item {
    padding-top: 30px;
    grid-column: span 10;
    border-top: 1px solid var(--grey-lines);

    &:last-child {
      padding-bottom: 45px;
      border-bottom: 1px solid var(--grey-lines);
    }

    &__header {
      column-gap: 18px;
    }

    &__review-text {
      margin-top: 25px;
    }

    &__bottom-wrap {
      margin-top: 40px;
      row-gap: 25px;
    }
  }
}