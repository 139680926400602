.cart-tabs {
  &__list {
    column-gap: var(--grid-gap);
    display: flex;
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__item {
    border: 1px solid var(--grey-lines);
    transition: {
      property: border-color, background;
      duration: var(--default-timing);
      timing-function: var(--default-bezier);
    };

    &._active {
      border-color: #000;
      background: var(--dark-background);

      .cart-tabs {
        &__text {
          &:before {
            background: #000;
          }
        }
      }
    }
  }

  .smart-tabs {
    .cart-tabs {
      &__link {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__text {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      border-radius: 50%;
      border: 2px solid #000;
      transition: background var(--default-timing) var(--default-bezier);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .cart-tabs {
    &__item {
      &:hover {
        border-color: #000;
      }
    }
  }
}

@include respond-up('medium') {
  .cart-tabs {
    &__item {
      width: var(--grid-column5);
    }

    &__link {
      padding: 25px;
    }

    &__text {
      &:before {
        width: 14px;
        height: 14px;
      }
    }

    &__icon {
      margin-top: 22px;
    }
  }
}

@include respond-down('small') {
  .cart-tabs {
    &__list {
      flex-direction: column;
      row-gap: 6px;
    }

    &__link {
      padding: 15px 20px 20px;
    }

    &__text {
      &:before {
        width: 10px;
        height: 10px;
      }
    }

    &__icon {
      margin-top: 16px;
    }
  }
}