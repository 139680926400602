.catalog {
  &__item-bottom-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__item-price {
    display: flex;

    .animation-item__inner {
      display: flex;
      flex: 1 0 auto;

      &:nth-child(2) {
        margin-left: 5px;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list {
    display: grid;
  }

  &__item-price {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    position: relative;
    padding-right: calc(12px + var(--item-price-after-size));
    overflow: hidden;
    height: fit-content;

    &:after {
      position: absolute;
      content: '';
      width: var(--item-price-after-size);
      height: var(--item-price-after-size);
      background: black;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 50%;
    }
  }

  //&__title-wrap {
  //  .link__small {
  //    padding-bottom: 0;
  //    &:before {
  //      bottom: -6px;
  //    }
  //  }
  //}
}

@include respond-up("x-large") {
  .catalog {
  }
}

@include respond-up("large") {
  .catalog {
  }
}

@include respond("large") {
  .catalog {
  }
}

@include respond-down("large") {
  .catalog {
  }
}

@include respond-up("s-large") {
  .catalog {
    &__item-price {
      font-size: 17px;
    }

    &__list {
      grid-row-gap: 110px;
    }
  }
}

@include respond("s-large") {
  .catalog {
  }
}

@include respond-down("s-large") {
  .catalog {
  }
}

@include respond-up("xs-large") {
  .catalog {
    --item-price-after-size: 8px;

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(460 / 638 * var(--grid-column7));
    }

    &__picture {
      height: calc(460 / 638 * var(--grid-column7));
    }

    &__item-bottom-wrapper {
      margin-top: 20px;
    }

    &__item {
      &:hover {
        .catalog {
          &__picture {
            transform: scale(1.05);
          }
        }
      }
    }

    &__list {
      grid-template-columns: var(--grid-column7) var(--grid-column7);
      grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));
    }

    &__item-link {
      &:hover {
        .link {
          &._line {
            &:before {
              transform-origin: left;
              transform: scaleX(1);
              transition: transform .4s var(--teaser-transition-func);
            }
          }
        }
      }
    }

    &__title-wrap {
      width: var(--grid-column6);
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .catalog {
    &__item-price {
      font-size: 15px;
    }

    &__list {
      grid-row-gap: 100px;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(318 / 442 * var(--grid-column7));
    }

    &__picture {
      height: calc(318 / 442 * var(--grid-column7));
    }
  }
}

@include respond-down("xs-large") {
  .catalog {
  }
}

@include respond-up("medium") {
  .catalog {
    padding-bottom: 20px;
  }
}

@include respond("medium") {
  .catalog {
    &__list {
      grid-template-columns: var(--grid-column5) var(--grid-column5);
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 70px;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(274 / 378 * var(--grid-column5));
    }

    &__picture {
      height: calc(274 / 378 * var(--grid-column5));
    }

    &__item-price {
      font-size: 15px;
    }

    &__item-bottom-wrapper {
      margin-top: 18px;
    }

    &__title-wrap {
      width: var(--grid-column3);
    }
  }
}

@include respond-down("medium") {
  .catalog {
    --item-price-after-size: 6px;
  }
}

@include respond-down("small") {
  .catalog {
    padding-bottom: 10px;

    &__list {
      grid-template-columns: var(--grid-column10);
      grid-row-gap: 30px;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(242 / 334 * var(--grid-column10));
    }

    &__picture {
      height: calc(242 / 334 * var(--grid-column10));
    }

    &__item-price {
      font-size: 13px;
    }

    &__item-bottom-wrapper {
      margin-top: 14px;
    }

    &__title-wrap {
      width: var(--grid-column7);
    }
  }
}
