.parallax-container {
  display: block;

  &__wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transition: height 1.2s var(--teaser-transition-func);
  }

  &__inner {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;

    * {
      display: block;
    }
  }

  &__image {
    transition: transform 1.2s var(--teaser-transition-func);
  }
}