.reviews {
  &__pagination-button {
    display: flex;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .reviews {

  }
}

@include respond-up('xs-large') {
  .reviews {
    &__list {
      &.grid-block {
        row-gap: 80px;
      }
    }

    &__pagination-button {
      margin-top: 100px;
    }
  }
}

@include respond('xs-large') {
  .reviews {
    &__list {
      &.grid-block {
        display: flex;
        flex-wrap: wrap;
        row-gap: 80px;
        column-gap: calc(var(--grid-column) + (var(--grid-gap) * 2));
      }
    }
  }
}

@include respond-up('medium') {
  .reviews {
    padding-bottom: 20px;
  }
}

@include respond('medium') {
  .reviews {
    &__list {
      &.grid-block {
        row-gap: 70px;
      }
    }

    &__pagination-button {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .reviews {

  }
}

@include respond-down('small') {
  .reviews {
    padding-bottom: 12px;

    &__list {
      &.grid-block {
        row-gap: 45px;
      }
    }

    &__pagination-button {
      margin-top: 50px;
    }
  }
}