.block {
  &__light {
    background: var(--light-background);
    color: var(--primary-color); // temp
  }

  &__black {
    background: var(--black-background);
    color: var(--light-color); // temp
  }
}