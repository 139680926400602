%text-card-description {
  font-family: var(--h-font);
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
}

@include respond-up('s-large') {
  %text-card-description {
    font-size: 18px;
  }
}

@include respond('xs-large') {
  %text-card-description {
    font-size: 17px;
  }
}

@include respond('medium') {
  %text-card-description {
    font-size: 16px;
  }
}

@include respond-down('small') {
  %text-card-description {
    font-size: 14px;
  }
}
