.cart-total {
  &__checkout-info-wrap,
  &__info-wrap {
    display: flex;
    flex-direction: column;
  }

  &__total,
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info-wrap {
    row-gap: 12px;
  }

  &__info-name {
    color: var(--grey-font);
  }

  &__policy {
    color: #727272;
  }

  &__policy-link {
    color: var(--primary-color);
    padding: 10px;
    margin: -10px;
  }

  &__button {
    &.button {
      width: 100%;
    }
  }

  &__promocode-title {
    font-family: var(--h-font);
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
  }

  &__input[type="text"] {
    &:-webkit-autofill {
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    font-size: 16px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  &__field {
    position: relative;

    .smart-label {
      &._focus {
        .smart-label__secondary {
          transform: translate3d(-60%, -50%, 0);
        }
      }
    }
  }

  &__apply-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    position: absolute;
  }

  &__promocode-title {
    font-family: var(--h-font);
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }
}

@include respond-up('s-large') {
  .cart-total {
    &__policy {
      margin-top: 24px;
    }
  }
}

@include respond-up('xs-large') {
  .cart-total {
    position: sticky;
    top: calc(var(--header-height) + 20px);

    &__promo-wrap {
      margin-top: 35px;
    }

    &__button {
      margin-top: 30px;
    }
  }
}

@include respond('xs-large') {
  .cart-total {
    &__policy {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .cart-total {
    &__info-wrap {
      margin-top: 25px;
    }

    &__field {
      margin-top: 15px;
    }

    &__input[type="text"] {
      height: 64px;
      padding: 0 22px;
    }

    &__apply-button {
      top: 10px;
      right: 10px;
      bottom: 10px;
      width: 44px;
      height: 44px;
    }
  }
}

@include respond('medium') {
  .cart-total {
    display: grid;
    grid-template-columns: var(--grid-column4) var(--grid-column5);
    grid-column-gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    grid-row-gap: 40px;
    grid-template-areas:
            "total promo"
            "bottom bottom";

    &__checkout-info-wrap {
      grid-area: total;
    }

    &__promo-wrap {
      grid-area: promo;
    }

    &__bottom-wrap {
      grid-area: bottom;
    }

    &__policy {
      margin-top: 18px;
    }
  }
}

@include respond-down('small') {
  .cart-total {
    &__info-wrap {
      margin-top: 15px;
    }

    &__policy {
      margin-top: 15px;
    }

    &__promo-wrap {
      margin-top: 30px;
    }

    &__field {
      margin-top: 12px;
    }

    &__button {
      margin-top: 25px;
    }

    &__input[type="text"] {
      height: 57px;
      padding: 0 18px;
    }

    &__apply-button {
      top: 8px;
      right: 8px;
      bottom: 8px;
      width: 41px;
      height: 41px;
    }
  }
}