.animation-wrap {
  overflow: hidden;

  &._visible {
    overflow: visible;
  }

  &.need-animation {
    .animation-item {
      &._text-wrap {
        .animation-item__inner {
          span {
            transform: translate3d(0, 100%, 0);
          }
        }

        &._wrap-after {
          &:after, &:before {
            transform: translateY(150%);
          }

          .animation-item {
            &__inner {
              &:after, &:before {
                transform: translateY(170%);
              }
            }
          }
        }
      }

      &._transformY {
        transform: translateY(var(--transform-translate));
      }

      &._transformX {
        transform: translateX(var(--transform-translate));
      }

      &._opacity {
        opacity: 0;
      }

      &._scale {
        transform: scale(var(--scale));
      }

      &._list-lines {
        &:before,
        &:after {
          transform: scale(0);
        }
      }

      &._custom-catalog {
        transform: translateY(100px) scale(1.2);
        opacity: 0;
      }
    }
  }
}

.animation-item {
  // delay
  --animation-delay: 0s;

  &._del-01 {
    --animation-delay: .1s;
  }

  &._del-015 {
    --animation-delay: .15s;
  }

  &._del-02 {
    --animation-delay: .2s;
  }

  &._del-03 {
    --animation-delay: .3s;
  }

  &._del-04 {
    --animation-delay: .4s;
  }

  &._del-05 {
    --animation-delay: .5s;
  }

  &._del-06 {
    --animation-delay: .6s;
  }

  &._del-08 {
    --animation-delay: .8s;
  }

  // duration
  &._dur-1 {
    --animation-duration: 1s;
  }

  &._dur-04 {
    --animation-duration: .4s;
  }

  &._dur-05 {
    --animation-duration: .5s;
  }

  &._dur-06 {
    --animation-duration: .6s;
  }

  &._dur-07 {
    --animation-duration: .7s;
  }

  &._dur-08 {
    --animation-duration: .8s;
  }

  // translate
  &._translate-20 {
    --transform-translate: 20px;
  }

  &._translate-60 {
    --transform-translate: 60px;
  }

  &._translate-100 {
    --transform-translate: 100px;
  }

  &._translate-100-per {
    --transform-translate: 100%;
  }

  &._translate-110 {
    --transform-translate: 110px;
  }

  &._translate-120 {
    --transform-translate: 120px;
  }

  // scale
  &._scale-11 {
    --scale: 1.1;
  }

  &._scale-12 {
    --scale: 1.2;
  }

  &._scale-13 {
    --scale: 1.3;
  }

  &._scale-15 {
    --scale: 1.5;
  }

  &._opacity {
    transition: opacity .45s var(--animation-delay) linear;
  }

  &._scale {
    transition: transform 1.25s var(--animation-delay) cubic-bezier(.215, .61, .355, 1);
  }

  // catalog category transition
  &._catalog-card {
    transition: opacity .45s var(--animation-delay) linear, transform 1.25s var(--animation-delay) cubic-bezier(.215, .61, .355, 1);
  }

  // slider with examples transition
  &._example-slider {
    transition: opacity .6s ease, transform .6s ease;
  }

  // hero slider transition
  &._hero-slider {
    transition: opacity .45s linear, transform 1.85s cubic-bezier(.215, .61, .355, 1);
    transform-origin: center center;
  }

  &._hero-slider-text {
    transition: transform cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: var(--animation-duration);
  }

  &._transformY,
  &._transformX {
    transition: transform .95s var(--animation-delay) cubic-bezier(.215, .61, .355, 1);
  }

  &._opacity-transform {
    transition-property: opacity, transform;
    transition-timing-function: var(--teaser-transition-func);
    transition-duration: var(--animation-duration);
    transition-delay: var(--animation-delay);
  }

  // category card custom transition
  &._category {
    transition: opacity 1.2s cubic-bezier(.4, 0, 0, 1),
    transform 1.2s cubic-bezier(.4, 0, 0, 1);
    transition-delay: var(--animation-delay);
  }

  // collections and articles text transition
  &._article {
    transition: transform 1.5s cubic-bezier(.165, .84, .44, 1), opacity 1.5s;
  }

  // inline forms text transition
  &._form {
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: var(--animation-duration);
    transition-delay: var(--animation-delay);
  }

  &._list-lines {
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        &:before {
          transition-delay: calc(((#{$i} - 1) * .2s));
        }

        &:after {
          transition-delay: calc(.2s + ((#{$i} - 1) * .2s));
        }
      }
    }

    &:before,
    &:after {
      transform-origin: 0 0;
      transition: transform 1s cubic-bezier(.5, 0, 0, 1);
    }
  }

  &._wrap-title {
    width: 100%;
  }

  &._text-wrap {
    .animation-item__inner {
      display: block;
      //white-space: nowrap;
      overflow: hidden;
      width: 100%;
      height: max-content;

      &:first-child {
        width: fit-content;
      }

      span {
        transition: transform cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: var(--animation-duration);
      }

      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          span {
            transition-delay: calc(var(--animation-delay) + #{$i} * .05s);
          }
        }
      }
    }

    &._wrap-after {
      &:after, &:before {
        transition: transform cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: var(--animation-duration);
        transition-delay: var(--animation-delay);
      }
    }

    &._default-wrap {
      .animation-item__inner {
        span {
          transition: transform var(--teaser-transition-func);
          transition-duration: var(--animation-duration);
        }
      }

      &._wrap-after {
        &:after, &:before {
          transition: transform var(--teaser-transition-func);
          transition-duration: var(--animation-duration);
          transition-delay: var(--animation-delay);
        }
      }
    }
  }

  &._custom-catalog {
    --catalog-transition-func: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: opacity .5s var(--catalog-transition-func),
    transform 1s var(--catalog-transition-func);
  }
}

@include respond-up('large') {
  .animation-wrap {
    &._list-del-by-item {
      &:nth-child(4n + 1) {
        .animation-item {
          transition-delay: 0s;
        }
      }

      &:nth-child(4n + 2) {
        .animation-item {
          transition-delay: var(--animation-delay);
        }
      }

      &:nth-child(4n + 3) {
        .animation-item {
          transition-delay: calc(var(--animation-delay) * 2);
        }
      }

      &:nth-child(4n + 4) {
        .animation-item {
          transition-delay: calc(var(--animation-delay) * 3);
        }
      }
    }
  }
}

@include respond-up('xs-large') {
  .animation-wrap {
    &._config-del-by-item {
      &:nth-child(3n + 1) {
        .animation-item {
          transition-delay: 0s;
        }
      }

      &:nth-child(3n + 2) {
        .animation-item {
          transition-delay: var(--animation-delay);
        }
      }

      &:nth-child(3n + 3) {
        .animation-item {
          transition-delay: calc(var(--animation-delay) * 2);
        }
      }
    }
  }
}

@include respond-between('xs-large', 's-large') {
  .animation-wrap {
    &._list-del-by-item {
      &:nth-child(3n + 1) {
        .animation-item {
          transition-delay: 0s;
        }
      }

      &:nth-child(3n + 2) {
        .animation-item {
          transition-delay: var(--animation-delay);
        }
      }

      &:nth-child(3n + 3) {
        .animation-item {
          transition-delay: calc(var(--animation-delay) * 2);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .animation-wrap {
    &:nth-child(2n) {
      .animation-item {
        &._custom-catalog {
          transition-delay: 0.1s;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .animation-wrap {
    &._list-del-by-item,
    &._config-del-by-item {
      &:nth-child(2n + 1) {
        .animation-item {
          transition-delay: 0s;
        }
      }

      &:nth-child(2n + 2) {
        .animation-item {
          transition-delay: var(--animation-delay);
        }
      }
    }
  }
}