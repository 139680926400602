.get-link {
  background: var(--dark-background-2);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__parallax-container-inner {
    height: inherit;

    div {
      height: 100%;
      width: 100%;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__logo-wrapper {
    z-index: 10;
    position: absolute;
  }

  &__wrapper {
    z-index: 40;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .link {
      --text-and-border-color-by-theme: white;
    }
  }

  &__title {
    margin-top: 30px;
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@include respond-up("s-large") {
  .get-link {
    margin-top: 160px;

    &__logo-wrapper {
      left: -240px;
      bottom: -50px;
    }
  }
}

@include respond-up("xs-large") {
  .get-link {
    height: 408px;

    &__title {
      width: 712px;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .get-link {
    margin-top: 140px;

    &__logo-wrapper {
      width: 851px;
      height: 610px;
      left: -295px;
      top: -130px;
    }

    &__wrapper {
      width: var(--grid-column11);
    }
  }
}

@include respond-up("medium") {
  .get-link {
    &__link {
      margin-top: 30px;
    }
  }
}

@include respond("medium") {
  .get-link {
    margin-top: 95px;

    &__title {
      width: 510px;
    }

    &__logo-wrapper {
      width: 600px;
      height: 430px;
      left: -215px;
      top: -44px;
    }

    &__wrapper {
      width: var(--grid-column8);
    }
  }
}

@include respond-down("medium") {
  .get-link {
    height: 369px;
  }
}

@include respond-down("small") {
  .get-link {
    margin-top: 60px;

    &__container {
      width: 100%;
    }

    &__title {
      width: 300px;
    }

    &__link {
      margin-top: 25px;

      &.button__big {
        padding: 20px 42px;
      }
    }

    &__logo-wrapper {
      width: 420px;
      height: 300px;
      left: -206px;
      top: -20px;
    }
  }
}
