.cart-pickup {
  border-top: 1px solid var(--grey-lines);
  border-bottom: 1px solid var(--grey-lines);

  &__map {
    height: 100%;
  }

  &__radio-item {
    border-bottom: 1px solid var(--grey-lines);
  }

  &__radio-list,
  &__input-label,
  &__schedule-list {
    display: flex;
    flex-direction: column;
  }

  &__schedule-list {
    row-gap: 4px;
  }

  &__radio {
    input[type="radio"] {
      display: none;

      &:checked {
        + label {
          background: var(--dark-background);

          &::before {
            background: var(--primary-color);
          }
        }
      }
    }
  }

  &__label-title {
    font-family: var(--h-font);
    font-weight: 500;
    &_city,
    &_address {
      line-height: 130%;
      text-transform: uppercase;
    }

    &_phone,
    &_week-schedule,
    &_weekends-schedule {
      font-size: 13px;
      line-height: 145%;
    }

    &_week-schedule,
    &_weekends-schedule {
      display: flex;
      align-items: center;
    }
  }

  &__input-label {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      border-radius: 50%;
      border: 2px solid var(--primary-color);
      transition: background var(--default-timing) var(--default-bezier);
    }
  }
}

@include respond-up('s-large') {
  .cart-pickup {
    grid-template-columns: calc(var(--grid-column4) + var(--grid-gap)) var(--grid-column6);

    &__input-label {
      padding: 20px 16px 20px 30px;

      &::before {
        top: 20px;
        right: 16px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .cart-pickup {
    display: grid;
    height: 517px;

    &__radio-list {
      grid-column: 1;
      overflow-y: scroll;
    }

    &__map-wrap {
      grid-column: 2;
      height: 100%;
    }

    &__input-label {
      transition: background var(--default-timing) var(--default-bezier);
      cursor: pointer;

      &:hover {
        background: var(--dark-background);
      }
    }
  }
}

@include respond('xs-large') {
  .cart-pickup {
    grid-template-columns: var(--grid-column5) calc(var(--grid-column5) + var(--grid-gap));

    &__input-label {
      padding: 20px;

      &::before {
        top: 20px;
        right: 20px;
      }
    }
  }
}

@include respond-up('medium') {
  .cart-pickup {
    &__label-title {
      &_city,
      &_address {
        font-size: 14px;
      }

      &_address {
        margin-top: 8px;
      }

      &_phone {
        margin-top: 18px;
      }

      &_week-schedule,
      &_weekends-schedule {
        column-gap: 20px;
      }
    }

    &__schedule-list {
      margin-top: 8px;
    }

    &__input-label {
      &::before {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@include respond('medium') {
  .cart-pickup {
    &__map-wrap {
      height: 426px;
    }

    &__input-label {
      padding: 20px var(--grid-spacer-and-indent);

      &::before {
        top: 20px;
        right: var(--grid-spacer-and-indent);
      }
    }

    &__link {
      bottom: 22px;
    }
  }
}

@include respond-down('medium') {
  .cart-pickup {
    margin: 0 calc(-1 * var(--grid-spacer-and-indent));

    &__radio-item {
      position: relative;
    }

    &__link-text {
      font-size: 14px;
      margin-right: 6px;
    }
  }
}

@include respond-down('small') {
  .cart-pickup {
    &__map-wrap {
      height: 400px;
    }

    &__label-title {
      &_city,
      &_address {
        font-size: 13px;
      }

      &_address {
        margin-top: 6px;
      }

      &_week-schedule,
      &_weekends-schedule {
        column-gap: 35px;
      }

      &_phone {
        margin-top: 12px;
      }
    }

    &__input-label {
      padding: 12px var(--grid-spacer-and-indent);

      &::before {
        width: 16px;
        height: 16px;
        top: 12px;
        right: var(--grid-spacer-and-indent);
      }
    }

    &__link {
      position: absolute;
      right: var(--grid-spacer-and-indent);
      bottom: 13px;
      left: unset;
    }

    &__schedule-list {
      margin-top: 8px;
    }
  }
}