.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh; //true-vh

  transition: background-color .5s var(--default-bezier);
  background: var(--layout-bg-by-theme); // temp
  color: var(--text-and-border-color-by-theme); // temp

  &__wrapper {
    // это когда шапка обычная, а не прозрачная поверх
    &._high {
      padding-top: var(--header-height);;
    }
  }

  // светлая страница
  --layout-bg-by-theme: var(--light-background);
  --text-and-border-color-by-theme: var(--primary-color);
  --button-big-bg: var(--primary-color);

  &._grey {
    --layout-bg-by-theme: var(--dark-background-2);
  }

  &._dark {
    // темная страница
    --layout-bg-by-theme: var(--black-background);
    --text-and-border-color-by-theme: var(--light-color);
    --button-big-bg: var(--t);
  }
}

@include respond-up("x-large") {
  .layout {
  }
}

@include respond-up("large") {
  .layout {
  }
}

@include respond("large") {
  .layout {
  }
}

@include respond-down("large") {
  .layout {
  }
}

@include respond-up("s-large") {
  .layout {
  }
}

@include respond("s-large") {
  .layout {
  }
}

@include respond-down("s-large") {
  .layout {
  }
}

@include respond-up("xs-large") {
  .layout {
    --header-height: 82px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .layout {
  }
}

@include respond-down("xs-large") {
  .layout {
  }
}

@include respond-up("medium") {
  .layout {
  }
}

@include respond("medium") {
  .layout {
    --header-height: 74px;
  }
}

@include respond-down("medium") {
  .layout {
  }
}

@include respond-down("small") {
  .layout {
    --header-height: 50px;
  }
}
