.to-partners {
  display: grid;

  &__left-image-wrap {
    display: flex;
    align-items: center;
    max-height: 80vh;
  }

  &__parallax-container {
    flex: 1 0 auto;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description-wrapper {
    display: grid;
  }
}

@include respond-up("x-large") {
  .to-partners {
  }
}

@include respond-up("large") {
  .to-partners {
  }
}

@include respond("large") {
  .to-partners {
  }
}

@include respond-down("large") {
  .to-partners {
  }
}

@include respond-up("s-large") {
  .to-partners {
    padding-top: 217px;
    grid-template-columns: var(--grid-column5) var(--grid-column9);
    grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));

    &__title {
      padding-top: 80px;
    }

    &__description-wrapper {
      margin-top: 50px;
      grid-template-columns: var(--grid-column4) var(--grid-column4);
      grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));
    }

    &__parallax-container,
    &__parallax-container-inner {
      height: calc(695 / 829 * var(--grid-column9));
    }

    &__picture {
      height: calc(484 / 577 * var(--grid-column9));
    }
  }
}

@include respond("s-large") {
  .to-partners {
  }
}

@include respond-down("s-large") {
  .to-partners {
  }
}

@include respond-up("xs-large") {
  .to-partners {
  }
}

// laptop 1100
@include respond("xs-large") {
  .to-partners {
    padding-top: 140px;
    grid-template-columns: var(--grid-column5) var(--grid-column9);
    grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));

    &__title {
      padding-top: 60px;
    }

    &__description-wrapper {
      margin-top: 50px;
      grid-template-columns: var(--grid-column4) var(--grid-column4);
      grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));
    }

    &__parallax-container,
    &__parallax-container-inner {
      height: calc(421 / 577 * var(--grid-column9));
    }

    &__picture {
      height: calc(484 / 577 * var(--grid-column9));
    }
  }
}

@include respond-down("xs-large") {
  .to-partners {
  }
}

@include respond-up("medium") {
  .to-partners {
  }
}

@include respond("medium") {
  .to-partners {
    padding-top: 120px;

    &__title {
      padding-top: 108px;
      padding-left: calc(var(--grid-gap) + var(--grid-gap));
    }

    &__description-wrapper {
      margin-top: 50px;
      grid-template-columns: var(--grid-column4) var(--grid-column4);
      grid-column-gap: calc(var(--grid-column1) + 2 * var(--grid-gap));
    }

    &__parallax-container,
    &__parallax-container-inner {
      height: calc(503 / 690 * var(--grid-column9));
    }

    &__picture {
      height: calc(578 / 690 * var(--grid-column9));
    }

    &__picture-side-wrapper {
      margin-top: 70px;
      padding-left: calc(var(--grid-gap) + var(--grid-gap));
    }
  }
}

@include respond-down("medium") {
  .to-partners {
  }
}

@include respond-down("small") {
  .to-partners {
    padding-top: 60px;

    &__title {
      padding-top: 20px;
    }

    &__description-wrapper {
      margin-top: 40px;
      grid-template-rows: auto auto;
      grid-row-gap: 30px;
    }

    &__parallax-container,
    &__parallax-container-inner {
      height: calc(244 / 334 * var(--grid-column10));
    }

    &__picture {
      height: calc(281 / 334 * var(--grid-column10));
    }

    &__picture-side-wrapper {
      margin-top: 18px;
    }
  }
}
