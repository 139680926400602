.switcher {
  display: flex;
  align-items: center;

  &__button {
    width: 55px;
    height: 30px;
    display: flex;
    background: #fff;
    border-radius: 50px;

    &._active {
      .switcher {
        &__inner {
          border-width: 10px;
          transform: translate3d(25px, 0, 0);
        }
      }
    }
  }

  &__inner {
    width: 30px;
    height: 30px;
    background: var(--light-background);
    border: 2px solid #000;
    border-radius: 50%;
    transition: {
      property: transform, border-width;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
    };
  }
}

@include respond-up('medium') {
  .switcher {
    column-gap: 40px;
  }
}

@include respond-down('small') {
  .switcher {
    column-gap: 20px;
  }
}