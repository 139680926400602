// страница статьи это
.article {
  &__date {
    align-self: flex-end;
    text-align: right;
  }

  &__top-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    //width: 100%;
    transition: transform 1.2s var(--teaser-transition-func);
  }

  &__categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 6px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("x-large") {
  .article {
  }
}

@include respond-up("large") {
  .article {
  }
}

@include respond("large") {
  .article {
  }
}

@include respond-down("large") {
  .article {
  }
}

@include respond-up("s-large") {
  .article {
    padding-bottom: 35px;

    &__article-wrapper {
      margin-top: 100px;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(700 / 1400 * var(--grid-column15));
    }

    &__picture {
      height: calc(1.2 * 700 / 1400 * var(--grid-column15));
    }
  }
}

@include respond("s-large") {
  .article {
  }
}

@include respond-down("s-large") {
  .article {
  }
}

@include respond-up("xs-large") {
  .article {
    &__heading {
      grid-column: 1/13;
    }

    &__share {
      grid-column: 1/4;
    }

    &__article {
      grid-column: 4/16;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .article {
    padding-bottom: 20px;

    &__article-wrapper {
      margin-top: 90px;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(440 / 980 * var(--grid-column15));
    }

    &__picture {
      height: calc(490 / 980 * var(--grid-column15));
    }
  }
}

@include respond-down("xs-large") {
  .article {
  }
}

@include respond-up("medium") {
  .article {
    &__date {
      flex: 0 0 var(--grid-column2);
    }

    &__main-image-wrapper {
      margin-top: 28px;
    }

    &__category {
      margin-left: 20px;
    }

    &__categories {
      margin-left: -20px;
    }
  }
}

@include respond("medium") {
  .article {
    padding-bottom: 35px;

    &__article-wrapper {
      margin-top: 80px;
    }

    &__share {
      grid-column: 1/3;
    }

    &__article {
      grid-column: 3/11;
    }

    &__heading {
      grid-column: 1/9;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(347 / 774 * var(--grid-column10));
    }

    &__picture {
      height: calc(387 / 774 * var(--grid-column10));
    }
  }
}

@include respond-down("medium") {
  .article {
  }
}

@include respond-down("small") {
  .article {
    padding-bottom: 12px;

    &__article-wrapper {
      margin-top: 50px;
    }

    &__share {
      grid-column: 1/11;
      order: 2;
      padding-top: 20px;
      border-top: 1px solid var(--grey-lines);
      margin-top: 50px;
    }

    &__article {
      grid-column: 1/11;
      order: 1;
    }

    &__category {
      margin-left: 16px;
    }

    &__categories {
      margin-left: -16px;
    }

    &__heading {
      grid-column: 1/11;
    }

    &__parallax-container-inner,
    &__parallax-container {
      height: calc(147 / 334 * var(--grid-column10));
    }

    &__picture {
      height: calc(167 / 334 * var(--grid-column10));
    }

    &__main-image-wrapper {
      margin-top: 20px;
    }

    &__date {
      flex: 0 0 var(--grid-column4);
    }
  }
}