.customers {
  &__text-wrap {
    article {
      p {
        big {
          @extend %text-large;
          text-transform: none;
        }
      }
    }
  }

  &__wrap {
    padding-bottom: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    &:not(:first-child) {
      border-top: 1px solid var(--grey-lines);
    }

    &_second-item {
      border-top: 1px solid var(--grey-lines);
    }

    &_first-item {
      &:not(:first-child) {
        border-top: none
      }
    }
  }

  &__tabs-link {
    display: block;
  }

  &__map-wrap {
    width: 100%;
  }

  &__map {
    height: 100%;
    width: 100%;
  }
}

@include respond-up('s-large') {
  .customers {
    &__text-wrap {
      grid-column: 8/16;
    }

    &__map-wrap {
      height: 500px;
    }
  }
}

@include respond-up('xs-large') {
  .customers {
    margin-top: 80px;

    &__list {
      row-gap: 90px;
    }

    &__item {
      &:not(:first-child),
      &_second-item {
        padding-top: 50px;
      }
    }

    &__title {
      grid-column: 1/6;
    }
  }
}

@include respond('xs-large') {
  .customers {
    &__text-wrap {
      grid-column: 7/16;
    }

    &__map-wrap {
      height: 393px;
    }
  }
}

@include respond-up('medium') {
  .customers {
    &__map-block-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 35px;
    }

    &__map-block-info {
      display: flex;
      column-gap: 64px;
    }

    &__map-phone {
      position: relative;
      padding: 10px;
      margin: -10px;

      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: -26px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: black;
      }
    }

    &__file-block-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
    }
  }
}

@include respond('medium') {
  .customers {
    margin-top: 70px;

    &__list {
      row-gap: 70px;
    }

    &__item {
      &:not(:first-child),
      &_second-item {
        padding-top: 35px;
      }
    }

    &__title {
      grid-column: 1/11;
    }

    &__text-wrap {
      grid-column: 2/11;
    }

    &__map-wrap {
      height: 472px;
    }
  }
}

@include respond-down('medium') {
  .customers {
    &__item {
      &.grid-block {
        grid-row-gap: 30px;
      }
    }

    &__title {
      grid-row: 1;
    }

    &__text-wrap {
      grid-row: 2;
    }
  }
}

@include respond-down('small') {
  .customers {
    margin-top: 35px;

    &__list {
      row-gap: 40px;
    }

    &__item {
      &:not(:first-child),
      &_second-item {
        padding-top: 25px;
      }
    }

    &__title {
      grid-column: 1/11;
    }

    &__text-wrap {
      grid-column: 1/11;
    }

    &__map-wrap {
      height: 420px;
    }

    &__map-block-info {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &__map-block-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }

    &__file-block-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }
  }
}