.hero-block-collection {
  .hero-block {
    &__image-wrap {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: 25;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
      }
    }

    &__text-wrapper {
      display: grid;
      grid-column-gap: var(--grid-gap);
    }

    &__label {
      grid-column: 1/4;
      height: max-content;
    }

    &__heading {
      grid-column: 1/11;
    }
  }
}

@include respond-up('s-large') {
  .hero-block-collection {
    .hero-block {
      &__text-wrapper {
        grid-row-gap: 60px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .hero-block-collection {
    .hero-block {
      &__text-wrapper {
        grid-template-columns: repeat(15, 1fr);
      }

      &__description {
        grid-column: 6/11;
      }
    }
  }
}

@include respond-between('medium', 'xs-large') {
  .hero-block-collection {
    .hero-block {
      &__text-wrapper {
        grid-row-gap: 80px;
      }
    }
  }
}

@include respond-up('medium') {
  .hero-block-collection {
    .hero-block {
      &__heading {
        grid-row: 1;
      }

      &__label,
      &__description {
        grid-row: 2;
      }
    }
  }
}

@include respond('medium') {
  .hero-block-collection {
    .hero-block {
      &__description {
        grid-column: 4/8;
      }
    }
  }
}

@include respond-down('medium') {
  .hero-block-collection {
    .hero-block {
      &__text-wrapper {
        grid-template-columns: repeat(10, 1fr);
      }
    }
  }
}

@include respond-down('small') {
  .hero-block-collection {
    .hero-block {
      &__label {
        grid-row: 1;
      }

      &__heading {
        grid-row: 2;
        margin-top: 20px;
      }

      &__description {
        grid-column: 3/11;
        grid-row: 3;
        margin-top: 40px;
      }
    }
  }
}