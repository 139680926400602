.cart-page-pay-options {
  &__text-help {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 120%;
    color: var(--grey-font);
  }
}

@include respond-up('s-large') {
  .cart-page-pay-options {
    margin-top: 40px;
  }
}

@include respond('xs-large') {
  .cart-page-pay-options {
    margin-top: 30px;
  }
}

@include respond-up('medium') {
  .cart-page-pay-options {
    &__text-wrap {
      margin-top: 30px;
    }

    &__text-help {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .cart-page-pay-options {
    margin-top: 35px;
  }
}

@include respond-down('small') {
  .cart-page-pay-options {
    margin-top: 30px;

    &__link {
      &.cart-tabs__link {
        padding: 15px 20px;
      }
    }

    &__text-wrap {
      margin-top: 20px;
    }

    &__text-help {
      font-size: 12px;
    }
  }
}