%text-small-points {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}

@include respond-up("medium") {
  %text-small-points {
    font-size: 14px;
  }
}

@include respond-down("small") {
  %text-small-points {
    font-size: 12px;
  }
}