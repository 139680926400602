/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
        small: (
                from: 0,
                to: 767
        ),
        medium: (
                from: 768,
                to: 1023
        ),
        xs-large: (
                from: 1024, // laptop это 1100, это этот диапазон
                to: 1599
        ),
        s-large: (
                from: 1600,
                to: 1799
        ),
        large: (
                from: 1800,
                to: 1920
        ),
        x-large: (
                from: 1921
        )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  // TT Norms
  --font: "TT Norms", sans-serif;
  // Gilroy
  --h-font: "Gilroy", sans-serif;

  --t: transparent;

  // палитра макета
  // + меняются цвета в темных блоках. они в _layout.scss
  --primary-color: black; // black font
  --light-background: #EDEBE8; // Фоновый
  --dark-background: #E3E1DE; // Фоновый
  --dark-background-2: #DFDCD7; // Фоновый темный
  --dark-background-3: #DCD8D2; // Фоновый темный на стр дизайнерам
  --dark-background-4: #EAE9E6; // Фоновый темный на стр дизайнерам
  --black-background: #1F1E1C; // Black background
  --light-color: white; // white font

  --light-color-opacity-15: rgba(255, 255, 255, .15);
  --light-color-opacity-50: rgba(255, 255, 255, .5); // lines on black background
  --light-color-opacity-58: rgba(255, 255, 255, .58);
  --light-color-opacity-70: rgba(255, 255, 255, .7); // lines on black background

  --grey-background: #F8F8F8;
  --grey-background-opacity-70: rgba(237, 235, 232, 0.7);
  --grey-lines: #BCBCBC; // grey lines on light background
  --grey-font: #5B5856; // grey font on black background
  --grey-icons: #A39F9D;
  --grey-elements: #CECDCC;
  --grey-light: #F4F3F1; // Grey light
  --grey-beige: #DBD8D0;

  --black-bg-opacity-05: rgba(0, 0, 0, 0.05);
  --black-bg-opacity-30: rgba(0, 0, 0, 0.3);
  --black-bg-opacity60: rgba(0, 0, 0, 0.6);
  --black-bg-opacity-80: rgba(0, 0, 0, 0.8);

  //temp
  --alert-color: #801C1C;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --default-bezier: cubic-bezier(0.25, 0.1, 0.25, 1);

  --primary-color-opacity-40: rgba(0, 0, 0, .4);
  --primary-color-opacity-85: rgba(0, 0, 0, .85);

  --default-timing: .4s;
  --long-timing: .8s;
  --teaser-transition-func: cubic-bezier(.25, .1, .25, 1);
  --header-timing-function: cubic-bezier(.65, 0, .25, 1);
}
