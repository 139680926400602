.cart-block-heading {
  display: flex;
  align-items: center;

  &__index {
    font-family: var(--h-font);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #000;
    color: #fff;
  }
}

@include respond-up('s-large') {
  .cart-block-heading {
    margin-bottom: 35px;

    &__index {
      width: 37px;
      height: 37px;
      font-size: 23px;
    }
  }
}

@include respond('xs-large') {
  .cart-block-heading {
    &__index {
      width: 35px;
      height: 35px;
      font-size: 20px;
    }
  }
}

@include respond-between('medium', 'xs-large') {
  .cart-block-heading {
    margin-bottom: 30px;
  }
}

@include respond-up('medium') {
  .cart-block-heading {
    column-gap: 22px;

    &__index {
      line-height: 135%;
    }
  }
}

@include respond('medium') {
  .cart-block-heading {
    &__index {
      width: 34px;
      height: 34px;
      font-size: 21px;
    }
  }
}

@include respond-down('small') {
  .cart-block-heading {
    margin-bottom: 18px;
    column-gap: 16px;

    &__index {
      width: 28px;
      height: 28px;
      line-height: 136%;
      font-size: 16px;
    }
  }
}