.button {
  appearance: none;
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  width: fit-content;

  &._icon {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__more,
  &__close {
    padding: 10px;
    margin: -10px;
  }

  &__more {
    :not(:first-child) {
      margin-left: 15px;
    }
  }

  &__close {
    :not(:first-child) {
      margin-left: 10px;
    }
  }

  &__arrow-catalog {
    column-gap: 15px;
    padding: 10px; //оборачивайте во что-то с паддингом не менее 10 пкс
    margin: -10px;
  }

  &__more-text-wrap,
  &__close-text-wrap,
  &__arrow-catalog-text {
    transition: transform .2s ease-out;
  }

  &__more-text-wrap,
  &__arrow-catalog-text {
    line-height: 110%;
    padding-top: 2px; // костыль
  }

  &__close-text-wrap {
    line-height: 120%;
  }

  &__more-plus-wrap,
  &__close-icon-wrap {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: var(--text-and-border-color-by-theme);
      transform-origin: center;
      transition: transform .3s ease-out;
    }
  }

  &__more-plus-wrap {
    width: 12px;
    height: 12px;

    &:before {
      width: 100%;
      height: 2px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      width: 2px;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__close-icon-wrap {
    width: 14px;
    height: 14px;

    &:before,
    &:after {
      width: 16px;
      height: 2px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      transform: translateY(-50%) rotate(-45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(45deg);
    }
  }

  &__arrow-catalog-icon {
    display: flex;
    align-items: center;
    overflow: hidden;

    svg path {
      stroke: var(--text-and-border-color-by-theme);
    }
  }

  &__big {
    background: var(--button-big-bg);
    color: var(--light-color);
    border: 1px solid var(--text-and-border-color-by-theme);
    text-transform: uppercase;

    &_white-transparent {
      background: transparent;
      border: 1px solid var(--light-color);
    }
  }

  &__button-big-label-anim,
  &__button-big-label-wrap {
    pointer-events: none;
  }
}

@include respond-up('xs-large') {
  .button {
    &__more {
      &:hover {
        .button {
          &__more-plus-wrap {
            &:before {
              transform: translateY(-50%) rotate(90deg);
            }

            &:after {
              transform: translateX(-50%) rotate(90deg);
            }
          }

          &__more-text-wrap {
            transform: translateX(-6px);
          }
        }
      }
    }

    &__close {
      &:hover {
        .button {
          &__close-icon-wrap {
            &:before {
              transform: translateY(-50%) rotate(45deg);
            }

            &:after {
              transform: translateY(-50%) rotate(135deg);
            }
          }

          &__close-text-wrap {
            transform: translateX(6px);
          }
        }
      }
    }

    &__arrow-catalog {
      &:hover {
        .button {
          &__arrow-catalog-icon {
            svg {
              animation: push-arrow .55s cubic-bezier(.77, 0, .175, 1) reverse;
            }

            &_left {
              svg {
                animation: push-arrow .55s cubic-bezier(.77, 0, .175, 1);
              }
            }
          }

          &__arrow-catalog-text {
            transform: translateX(-4px);
          }
        }
      }
    }

    &__big {
      position: relative;

      &:hover {
        .button {
          &__button-big-label {
            opacity: 0;
            transition: opacity .2s linear;
          }

          &__button-big-label-anim {
            opacity: 1;
            transform: translate3d(-50%, -50%, 0);
            transition: opacity .33s linear .1s, transform .4s cubic-bezier(.26, 1, .48, 1) .1s;
          }
        }
      }
    }

    &__button-big-label-wrap {
      opacity: 1;
      transform: translateZ(0);
      transition: opacity .33s linear .4s, transform .5s cubic-bezier(.26, 1, .48, 1) .4s;
    }

    &__button-big-label {
      opacity: 1;
      transition: none;
    }

    &__button-big-label-anim {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: center;
      opacity: 0;
      transform: translate3d(-50%, 50%, 0);
      transition: none;
    }
  }
}

@include respond-down('medium') {
  .button {
    &__big {

    }

    &__button-big-label-anim {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .button {
    &__more-text-wrap,
    &__arrow-catalog-text {
      font-size: 16px;
    }

    &__close-text-wrap {
      font-size: 15px;
    }

    &__big {
      padding: 23px 42px;
      line-height: 115%;
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .button {
    &__more-text-wrap,
    &__arrow-catalog-text {
      font-size: 15px;
    }

    &__close-text-wrap {
      font-size: 14px;
    }

    &__big {
      padding: 20px 10px;
      line-height: 120%;
      font-size: 15px;
    }
  }
}

@keyframes push-arrow {
  from {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-100%, 0);
  }

  51% {
    transform: translate(100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}