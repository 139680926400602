.empty-cart {
  &__subtitle {
    color: var(--grey-font);
  }
}

@include respond-up('medium') {
  .empty-cart {
    &__subtitle {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-down('small') {
  .empty-cart {
    &__subtitle {
      margin-top: 10px;
    }

    &__button-wrap {
      margin-top: 25px;
    }

    &__button {
      width: 100%;
    }
  }
}