.breadcrumbs {
  --text-and-border-color-by-theme: black;
  // непринципиально. крошки зависят от страницы. не от блока

  &_white {
    .breadcrumbs {
      &__item {
        color: white;
      }

      &__item_delimiter {
        background: white;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: block;
    color: var(--grey-font);
    font-weight: 400;
    line-height: 135%;
    font-family: var(--h-font);
    padding: 4px;

    &.breadcrumbs {
      &__item_delimiter {
        padding: 0;
      }
    }
  }

  &__item_delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 1px;
    background: var(--grey-lines);
    margin: 0 8px;
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }

  &__link {
    padding: 4px;
    margin: -4px;
    white-space: nowrap;
  }

  &__list {
    margin: -4px;
  }
}

@include respond-up("medium") {
  .breadcrumbs {
    margin-top: 30px;

    &__item {
      font-size: 13px;
    }

    &__link {
      transition: color .4s;

      &:hover {
        color: var(--text-and-border-color-by-theme);
      }
    }

    &_white {
      .breadcrumbs {
        &__link {
          &:hover {
            color: white;
          }
        }
      }
    }

  }
}

@include respond("medium") {
  .breadcrumbs {
    margin-top: 40px;
  }
}

@include respond-down("medium") {
  .breadcrumbs {
    &__item {
      font-size: 11px;
    }

    &__item {
      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
    margin-top: 14px;
  }
}
