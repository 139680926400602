.line-form {
  --line-form-bg: var(--black-background);
  --line-form-text-color: var(--light-color);
  --line-form-input-bg: var(--light-color-opacity-15);
  --line-form-input-color: var(--line-form-text-color);

  background-color: var(--line-form-bg);
  color: var(--line-form-text-color);

  &__policy-label {
    color: rgba(255, 255, 255, 0.6);
    cursor: none!important;
  }

  &_white {
    --line-form-bg: var(--dark-background-2);
    --line-form-text-color: black;
    --line-form-input-bg: var(--light-background);
    --line-form-input-color: black;

    .line-form {
      &__text,
      &__policy-label {
        color: var(--grey-font);
      }

      &__policy-link {
        color: black;
      }
    }
  }

  &_light {
    --line-form-bg: var(--light-background);
    --line-form-text-color: black;
    --line-form-input-bg: var(--dark-background);

    .line-form {
      &__policy-label {
        color: var(--grey-font);
      }

      &__button {
        background-color: black;
      }

      &__policy-link,
      &__success-title,
      &__success-text {
        color: black;
      }

      &__success-figure {
        svg {
          circle,
          path {
            stroke: black;
          }
        }
      }
    }
  }

  &_order {
    .line-form {
      &__form-name {
        min-height: unset;
      }

      &__field {
        textarea {
          resize: none;
          height: var(--default-input-height);
        }
      }
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__order {
    grid-area: order;
    border-top: 1px solid var(--grey-lines);

    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  & &__badge {
    color: var(--line-form-text-color);

    &:before {
      background-color: var(--line-form-text-color);
    }
  }

  &__form-line {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__badge-container {
    grid-area: badge;
  }

  &__form {
    grid-area: form;
  }

  &__form-name {
    grid-area: title;
  }

  &__text {
    grid-area: description;
  }

  &__input {
    background: var(--line-form-input-bg);
    color: var(--line-form-text-color);
  }

  &__field {
    position: relative;
    transition: box-shadow 0.4s;

    &:has(.errors:not(:empty)) {
      box-shadow: 0 0 0 1px var(--alert-color);
    }

    .errors {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #fff;
      background-color: var(--alert-color);
      padding: 3px 8px;
      font-size: 10px;
    }
  }

  &__policy-block {
    display: grid;
    grid-template-columns: 16px 1fr;
    grid-gap: 12px;
    color: var(--grey-font);
  }

  &__policy-link {
    color: #fff;
  }

  &__success {
    display: grid;
    grid-gap: var(--grid-gap);

    &_white {
      .line-form {
        &__success-figure {
          svg {
            circle,
            path {
              stroke: black;
            }
          }
        }

        &__success-title {
          color: black;
        }

        &__success-text {
          color: var(--grey-font);
        }
      }
    }
  }

  &__success-figure {
    grid-area: figure;
    display: flex;
    justify-content: center;
  }

  &__success-description {
    grid-area: description;
    color: var(--light-color-opacity-70);
  }

  &__field-radio-label-container {
    label {
      font-weight: 500;
      font-size: 16px;
    }
  }

  &__input-radio-items {
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    input {
      display: none;
    }

    label {
      background-color: var(--light-color-opacity-15);
      border-radius: 100px;
      cursor: pointer;
      padding: 10px 30px;
      transition: background-color 0.4s, color 0.4s, opacity 0.2s;

      &:hover {
        opacity: 0.9;
      }
    }

    input:checked + label {
      color: var(--primary-color);
      background-color: var(--light-color);
    }
  }

  &__order-config-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: var(--grey-font);
  }

  &__order-image-wrap {
    grid-area: order-image;
  }

  &__order-title-wrap {
    grid-area: order-title;
  }

  &__order-chars {
    grid-area: order-chars;
  }

  &__chars-list {
    margin: -6px 0;
  }

  &__order-char {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 6px 0;
  }

  &__char-name {
    color: var(--grey-font);
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@include respond-up('large') {
  .line-form {
    &__success-text {
      max-width: calc(var(--grid-column4) - 30px);
    }
  }
}

@include respond-up('s-large') {
  .line-form {
    &_order {
      .line-form {
        &__order {
          margin-bottom: 30px;
        }
      }
    }

    &__form-line {
      grid-template-areas: "badge . title"
                           "description . form";
      grid-template-columns: var(--grid-column4) var(--grid-column2) var(--grid-column9);
      grid-row-gap: 40px;
    }

    &__success {
      grid-template-areas: "figure . description";
      grid-template-columns: var(--grid-column4) var(--grid-column2) var(--grid-column9);
    }

    &__success-title {
      margin-bottom: 50px;
    }

    &__description {

    }

    &__form-container {

    }

    &__order-config-title {
      margin-top: 20px;
    }

    &__order-price {
      margin-top: 111px;
    }

    &__order-char {
      font-size: 14px;
    }

    &__close {
      margin-top: 35px;
    }
  }
}

@include respond('s-large') {
  .line-form {
    &__success-text {
      max-width: var(--grid-column4);
    }
  }
}

@include respond-up('xs-large') {
  .line-form {
    &_inline {
      padding: 120px 0 120px;
    }

    &_modal {
      padding: 120px 0 120px;
    }

    &_order {
      .line-form {
        &__form-line {
          grid-template-areas: "title title title"
                               "order order order"
                               "description . form";
        }

        &__total-field-wrap {
          margin-top: 30px;
        }
      }
    }

    &__order-image-wrap {
      height: var(--grid-column3);
    }

    &__order {
      border-bottom: 1px solid var(--grey-lines);
      padding: 20px 0;

      grid-template-columns: var(--grid-column3) var(--grid-column5) var(--grid-column) var(--grid-column6);
      grid-template-areas: "order-image order-title . order-chars";
    }

    &__fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      ._full {
        grid-column: span 2;
      }
    }

    &__field {
      &._tall {
        margin-bottom: 25px;
      }
    }

    &__form-name, &__badge-container {
      min-height: 87px;
    }

    &__order-config-title {
      font-size: 16px;
    }
  }
}

@include respond('xs-large') {
  .line-form {
    &_order {
      .line-form {
        &__form-line {
          grid-template-columns: var(--grid-column5) var(--grid-column1) var(--grid-column9);
          grid-row-gap: 20px;
        }

        &__order {
          margin-bottom: 20px;
        }
      }
    }

    &__form-line {
      grid-template-areas: "badge . title"
                           "description . form";
      grid-template-columns: var(--grid-column5) var(--grid-column1) var(--grid-column9);
      grid-row-gap: 40px;
    }

    &__success {
      grid-template-areas: "figure . description";
      grid-template-columns: var(--grid-column5) var(--grid-column1) var(--grid-column9);
    }

    &__success-title {
      margin-bottom: 40px;
    }

    &__order-config-title {
      margin-top: 12px;
    }

    &__order-price {
      margin-top: 55px;
    }

    &__order-char {
      font-size: 13px;
    }

    &__close {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .line-form {
    &__submit-line {
      margin-top: 45px;
      display: flex;
      justify-content: space-between;
    }

    &__policy-block {
      padding-right: 66px;
    }

    &__field-radio {
      display: flex;
      justify-content: flex-start;
      gap: 25px;
      align-items: center;
    }
  }
}

@include respond-between('medium', 'xs-large') {
  .line-form {
    &_order {
      .line-form {
        &__fields {
          grid-template-columns: 1fr 1fr;
        }

        &__field {
          &:nth-child(3),
          &:nth-child(4) {
            grid-column: 1/3;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .line-form {
    &_inline {
      padding: 100px 0 100px;
    }

    &_modal {
      padding: 100px 0 100px;
    }

    &_order {
      .line-form {
        &__form-line {
          grid-row-gap: 20px;
        }

        &__order {
          margin-bottom: 30px;
        }

        &__total-field-wrap {
          margin-top: 18px;
        }
      }
    }

    &__order {
      padding-top: 25px;
      grid-template-areas: "order-image order-title"
                           "order-image order-chars";
    }

    &__form-name, &__badge-container {
      min-height: 70px;
    }

    &__form-line {
      grid-template-areas: "badge . title"
                           "description . form";
      grid-template-columns: var(--grid-column3) var(--grid-column1) var(--grid-column6);
      grid-row-gap: 40px;
    }

    &__success {
      grid-template-areas: ". figure . description";
      grid-template-columns: var(--grid-column1) var(--grid-column2) var(--grid-column1) var(--grid-column6);
    }

    &__success-title {
      margin-bottom: 30px;
    }

    &__success-text {
      max-width: var(--grid-column4);
    }

    &__order-chars {
      margin-top: 25px;
    }

    &__order-config-title {
      font-size: 14px;
      margin-top: 18px;
    }

    &__order-price {
      margin-top: 50px;
    }

    &__close {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .line-form {
    &_order {
      .line-form {
        &__text {
          display: none;
        }

        &__form-line {
          grid-template-areas: "title"
                               "order"
                               "form";
          grid-template-columns: 1fr;
        }
      }
    }

    &__fields {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;

      ._full {
        grid-column: 1;
      }

      ._tall {
        margin-bottom: 15px;
      }
    }

    &__order {
      grid-template-columns: var(--grid-column4) var(--grid-column6);
    }

    &__order-chars {
      border-top: 1px solid var(--grey-lines);
      padding-top: 25px;
    }

    &__order-char {
      font-size: 12px;
    }

    &__order-image-wrap {
      height: var(--grid-column4);
    }
  }
}

@include respond-down('small') {
  .line-form {
    &_inline {
      padding: 60px 0 60px;
    }

    &_modal {
      padding: 60px 0 60px;
    }

    &_order {
      .line-form {
        &__form-line {
          grid-row-gap: 15px;
        }

        &__form-name {
          margin-bottom: 0;
        }

        &__order {
          margin-bottom: 25px;
        }

        &__total-field-wrap {
          margin-top: 15px;
        }
      }
    }

    &__order {
      padding-top: 15px;
      grid-template-areas: "order-image order-title"
                           "order-chars order-chars";
    }

    &__fields {
      grid-gap: 12px;
    }

    &__form-line {
      grid-template-areas: "badge"
                           "title"
                           "description"
                           "form";
      grid-template-columns: 1fr;
      grid-row-gap: 0;
    }

    &__badge-container {
      margin-bottom: 25px;
    }

    &__form {
    }

    &__form-name {
      margin-bottom: 18px;
    }

    &__text {
      margin-bottom: 40px;
    }

    &__submit-line {
      margin-top: 35px;
    }

    &__policy-block {
      margin-bottom: 25px;
      padding-right: 20px ;
    }

    &__submit-container {
      display: flex;
      justify-content: flex-end;

      .button {
        width: var(--grid-column5);
      }
    }

    &__success {
      display: block;
      width: 100%;
      text-align: center;
    }

    &__success-figure {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      svg {
        width: 128px;
        height: auto;
      }
    }

    &__success-title {
      margin-bottom: 18px;
    }

    &__field-radio-label-container {
      margin-bottom: 12px;
    }

    &__order-chars {
      margin-top: 15px;
    }

    &__order-config-title {
      font-size: 13px;
      margin-top: 8px;
    }

    &__order-price {
      margin-top: 35px;
    }

    &__close {
      margin-top: 25px;
    }
  }
}