.premium {
  height: calc(378 / 774 * var(--grid-column10));
  position: relative;

  span {
    display: flex;
  }

  &__product-text {
    display: flex;
    flex-direction: column;
  }

  &__link,
  &__picture-wrapper,
  &__picture-main {
    height: inherit;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__product-wrapper {
    position: absolute;
    background: white;
    display: flex;
    align-items: center;
  }

  &__product-name {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }

  &__product-price {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__product-text {
    padding-right: 10px;
  }
}

@include respond-up("x-large") {
  .premium {
  }
}

@include respond-up("large") {
  .premium {
  }
}

@include respond("large") {
  .premium {
  }
}

@include respond-down("large") {
  .premium {
  }
}

@include respond-up("s-large") {
  .premium {
  }
}

@include respond("s-large") {
  .premium {
  }
}

@include respond-down("s-large") {
  .premium {
  }
}

@include respond-up("xs-large") {
  .premium {
  }
}

// laptop 1100
@include respond("xs-large") {
  .premium {
  }
}

@include respond-down("xs-large") {
  .premium {
  }
}

@include respond-up("medium") {
  .premium {
    &__product-picture,
    &__picture-product {
      width: 46px;
      height: 46px;
    }

    &__product-text {
      margin-left: 12px;
    }

    &__product-wrapper {
      padding: 8px;
      left: 22px;
      bottom: 22px;
      height: 62px;
    }

    &__product-name {
      font-size: 14px;
    }

    &__product-price {
      margin-top: 4px;
      font-size: 13px;
    }
  }
}

@include respond("medium") {
  .premium {
  }
}

@include respond-down("medium") {
  .premium {
  }
}

@include respond-down("small") {
  .premium {
    &__product-picture,
    &__picture-product {
      width: 32px;
      height: 32px;
    }

    &__product-text {
      margin-left: 7px;
    }

    &__product-wrapper {
      padding: 5px;
      left: 10px;
      bottom: 10px;
      height: 42px;
    }

    &__product-name {
      font-size: 9px;
    }

    &__product-price {
      margin-top: 2px;
      font-size: 8px;
    }
  }
}
