//заголовки коллекций в слайдере коллекций на главной
%text-collection-slider {
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-transform: uppercase;
}

@include respond-up("s-large") {
  %text-collection-slider {
    font-size: 19px;
  }
}

// laptop
@include respond("xs-large") {
  %text-collection-slider {
    font-size: 18px;
  }
}

@include respond("medium") {
  %text-collection-slider {
    font-size: 16px;
  }
}

@include respond-down("small") {
  %text-collection-slider {
    font-size: 14px;
  }
}
