.blog-arrow-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--grey-lines);
  border-radius: 50%;

  &__icon {
    display: flex;
    overflow: hidden;

    svg {
      height: 100%;
      width: auto;
    }
  }
}

@include respond-up("xs-large") {
  .blog-arrow-link {
    &:hover {
      .blog-arrow-link__icon svg {
        animation: push-arrow-diagonal .55s;
      }
    }
  }
}

@include respond-up("xs-large") {
  .blog-arrow-link {
    width: 66px;
    height: 66px;

    &__icon {
      width: 16px;
      height: 16px;
    }
  }
}

@include respond-down("medium") {
  .blog-arrow-link {
    width: 46px;
    height: 46px;

    &__icon {
      width: 12px;
      height: 12px;
    }
  }
}