h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
.h1__index-page,
.h1__article,
.h1__cart {
  font-family: var(--h-font);
  font-size: var(--h-size);
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
}

h1, .h1,
.h1__index-page,
.h1__article {
  line-height: 125%;
}

h2, .h2 {
  line-height: 135%;
}

h3, .h3,
h4, .h4 {
  line-height: 140%;
}

h5, .h5 {
  line-height: 130%;
}

@include respond-up("s-large") {
  .layout, .modal__layout {
    h1, .h1 {
      --h-size: 75px;
    }

    .h1__index-page {
      --h-size: 65px;
    }

    .h1__article,
    .h1__cart {
      --h-size: 46px;
    }

    .h1__cart {
      line-height: 135%;
    }

    h2, .h2 {
      --h-size: 46px;
    }

    h3, .h3 {
      --h-size: 32px;
    }

    h4, .h4 {
      --h-size: 24px;
    }

    h5, .h5 {
      --h-size: 18px;
    }
  }
}

// laptop
@include respond("xs-large") {
  .layout, .modal__layout {
    h1, .h1 {
      --h-size: 60px;
    }

    .h1__index-page {
      --h-size: 50px;
    }

    .h1__article {
      --h-size: 38px;
    }

    .h1__cart {
      --h-size: 46px;
      line-height: 135%;
    }

    h2, .h2 {
      --h-size: 38px;
    }

    h3, .h3 {
      --h-size: 28px;
    }

    h4, .h4 {
      --h-size: 24px;
    }

    h5, .h5 {
      --h-size: 16px;
    }
  }
}

@include respond("medium") {
  .layout, .modal__layout {
    h1, .h1 {
      --h-size: 55px;
    }

    .h1__index-page {
      --h-size: 50px;
    }

    .h1__article {
      --h-size: 36px;
    }

    .h1__cart {
      --h-size: 35px;
    }

    h2, .h2 {
      --h-size: 38px;
    }

    h3, .h3 {
      --h-size: 26px;
    }

    h4, .h4 {
      --h-size: 22px;
    }

    h5, .h5 {
      --h-size: 16px;
    }
  }
}

@include respond-down("medium") {
  .layout, .modal__layout {
    .h1__cart {
      line-height: 125%;
    }
  }
}

@include respond-down("small") {
  .layout, .modal__layout {
    h1, .h1 {
      --h-size: 35px;
    }

    .h1__index-page {
      --h-size: 35px;
    }

    .h1__article {
      --h-size: 22px;
    }

    .h1__cart {
      --h-size: 24px;
    }

    h2, .h2 {
      --h-size: 24px;
    }

    h3, .h3 {
      --h-size: 19px;
    }

    h4, .h4 {
      --h-size: 17px;
    }

    h5, .h5 {
      --h-size: 14px;
    }
  }
}
