.content-header {
  &.need-animation {
    .content-header__heading {
      opacity: 0;
    }
  }
}

@include respond-up('s-large') {
  .content-header {
    &__heading {
      padding: 92px 0 60px;
    }
  }
}

@include respond-up('xs-large') {
  .content-header {
    &_product-page {
      padding-bottom: 42px;
    }

    &__cart-heading {
      margin-bottom: 50px;
    }
  }
}

@include respond('xs-large') {
  .content-header {
    &__heading {
      padding: 82px 0 60px;
    }
  }
}

@include respond('medium') {
  .content-header {
    &__heading {
      padding: 70px 0 45px;
    }

    &_product-page {
      padding-bottom: 31px;
    }

    &__cart-heading {
      margin-bottom: 40px;
    }
  }
}

@include respond-down('small') {
  .content-header {
    &__heading {
      padding: 40px 0 25px;
    }

    &_product-page {
      padding-bottom: 20px;
    }

    &__cart-heading {
      margin-bottom: 25px;
    }
  }
}