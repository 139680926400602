.pagination {
  &.swiper-pagination {
    display: flex;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-color: black;
    --swiper-pagination-bullet-inactive-color: var(--grey-icons);

    --swiper-pagination-bullet-width: 27px;
    --swiper-pagination-bullet-height: 2px;
    border-radius: 0;
  }

  &_bullets {
    .swiper-pagination-bullet {
      --swiper-pagination-bullet-inactive-opacity: 1;
      --swiper-pagination-color: black;
      --swiper-pagination-bullet-inactive-color: var(--grey-beige);
      border-radius: 100%;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        border: 1px solid var(--grey-beige);
        border-radius: 100%;
        display: none;
      }

      &.swiper-pagination-bullet-active {
        &::before {
          display: block;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .pagination {
    &_bullets {
      .swiper-pagination-bullet {
        --swiper-pagination-bullet-horizontal-gap: 7px;
        --swiper-pagination-bullet-width: 6px;
        --swiper-pagination-bullet-height: 6px;

        &::before {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .pagination {
    &_bullets {
      .swiper-pagination-bullet {
        --swiper-pagination-bullet-horizontal-gap: 5px;
        --swiper-pagination-bullet-width: 4px;
        --swiper-pagination-bullet-height: 4px;

        &::before {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}