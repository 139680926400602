.showroom {
  margin-left: calc(-1 * var(--grid-spacer-and-indent));

  &__wrap {
    display: flex;
    position: relative;
  }

  &__image-wrap-inner {
    position: absolute;
    z-index: 10;
  }

  &__image-wrap,
  &__info-wrap {
    z-index: 20;
  }

  &__schedule-list {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__info {
    display: flex;
  }

  &__image-wrapper {
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
  }

  &__schedule-item {
    display: flex;
    column-gap: 25px;
  }
}

@include respond-up('s-large') {
  .showroom {
    margin-top: 20px;

    &__wrap {
      padding-bottom: 73px;
    }

    &__text-wrap {
      margin-top: 90px;
      width: var(--grid-column4);
    }

    &__info {
      flex-direction: row;
      column-gap: var(--grid-gap);
    }

    &__address-wrap {
      width: var(--grid-column4);
    }

    &__schedule-wrap {
      width: var(--grid-column3);
    }

    &__picture {
      height: calc((660 / 740) * var(--grid-column8));
    }
  }
}

@include respond-up('xs-large') {
  .showroom {
    &__wrap {
      column-gap: calc(var(--grid-column) + (var(--grid-gap) * 2));
    }

    &__info {
      margin-top: 40px;
    }

    &__text-wrap {
      row-gap: 40px;
    }

    &__image-wrap-inner {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@include respond('xs-large') {
  .showroom {
    margin-top: 20px;

    &__wrap {
      padding-bottom: 83px;
    }

    &__text-wrap {
      margin-top: 50px;
      width: var(--grid-column6);
    }

    &__info {
      flex-direction: column;
      row-gap: 35px;
    }

    &__image-wrapper {
      svg {
        width: 647px;
        height: auto;
      }
    }

    &__picture {
      height: calc((557 / 502) * var(--grid-column8));
    }
  }
}

@include respond-up('medium') {
  .showroom {
    &__address,
    &__schedule-list {
      margin-top: 14px;
    }
  }
}

@include respond('medium') {
  .showroom {
    &__wrap {
      row-gap: 50px;
    }

    &__info {
      margin-top: 35px;
      flex-direction: row;
      column-gap: var(--grid-gap);
    }

    &__text-wrap {
      margin-top: 60px;
      width: var(--grid-column7);
      row-gap: 40px;
    }

    &__address-wrap {
      width: var(--grid-column4);
    }

    &__schedule-wrap {
      width: var(--grid-column3);
    }

    &__image-wrap-inner {
      top: 147px;

      svg {
        width: 525px;
        height: auto;
      }
    }

    &__picture {
      height: calc((372 / 573) * (var(--grid-column7) + var(--grid-spacer)));
    }

    &__image-wrap {
      width: calc(var(--grid-column7) + var(--grid-spacer));
    }
  }
}

@include respond-down('medium') {
  .showroom {
    &__wrap {
      flex-direction: column;
    }

    &__image-wrap-inner {
      right: 0;
    }

    &__info-wrap {
      padding-left: var(--grid-spacer-and-indent);
    }
  }
}

@include respond-down('small') {
  .showroom {
    &__wrap {
      row-gap: 30px;
    }

    &__info {
      margin-top: 26px;
      flex-direction: column;
      row-gap: 30px;
    }

    &__address,
    &__schedule-list {
      margin-top: 12px;
    }

    &__text-wrap {
      margin-top: 35px;
      row-gap: 35px;
    }

    &__image-wrap-inner {
      top: 71px;

      svg {
        width: 268px;
        height: auto;
      }
    }

    &__picture {
      height: calc((214 / 284) * (var(--grid-column8) + var(--grid-spacer)));
    }

    &__image-wrap {
      width: calc(var(--grid-column8) + var(--grid-spacer));
    }
  }
}