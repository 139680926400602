.product-collection {
  display: flex;

  &__link {
    display: flex;
    position: relative;
  }

  &__picture {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__title-wrap,
  &__description {
    position: absolute;
    z-index: 10;
  }

  &__title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__heading {
    color: #fff;

    &::before {
      background: #fff;
    }
  }

  &__title,
  &__description {
    color: #fff;
  }

  &__arrows {
    display: flex;
  }

  &__arrow {
    &:last-child {
      margin-left: 40px;
    }
  }

  &__slider-wrap {
    overflow: hidden;
  }

  &__slider.swiper {
    overflow: visible;
  }
}

@include respond-up('s-large') {
  .product-collection {
    &__title {
      margin-top: 30px;
    }

    &__description {
      bottom: 80px;
      left: var(--grid-spacer);
    }
  }
}

@include respond-up('xs-large') {
  .product-collection {
    justify-content: space-between;

    &__link {
      flex: 0 0 calc(var(--grid-column9) + var(--grid-spacer));
      height: calc((600 / 1100) * 100vw);
      max-height: 880px;

      &:hover {
        .product-collection__image {
          transform: scale(1.05);
        }
      }
    }

    &__picture {
      max-height: 880px;
    }

    &__image {
      transition: transform 1s var(--default-transition-function);
    }
    
    &__slider-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 calc(var(--grid-column5) + var(--grid-spacer));
    }

    &__slider.swiper {
      width: var(--grid-column5);
      margin-left: unset;
    }

    &__item.swiper-slide {
      width: var(--grid-column5);
    }

    &__arrows-wrap {
      display: flex;
      justify-content: flex-end;
      padding-right: var(--grid-spacer);
      margin-top: 60px;
    }

    &__slider-heading {
      display: none;
    }
  }
}

@include respond('xs-large') {
  .product-collection {
    &__description {
      left: 80px;
    }
  }
}

@include respond-down('xs-large') {
  .product-collection {
    &__title {
      margin-top: 20px;
    }

    &__description {
      bottom: 60px;
    }
  }
}

@include respond-up('medium') {
  .product-collection {
    &__description {
      width: var(--grid-column5);
    }
  }
}

@include respond('medium') {
  .product-collection {
    &__title {
      margin-top: 20px;
    }

    &__link {
      height: calc((600 / 834) * 100vw);
    }

    &__slider-wrap {
      margin-top: 50px;
    }

    &__slider {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .product-collection {
    flex-direction: column;

    &__description {
      left: var(--grid-spacer);
    }

    &__arrows {
      display: none;
    }

    &__slider-wrap {
      padding: 0 var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .product-collection {
    &__title {
      margin-top: 7px;
    }

    &__link {
      height: calc((400 / 374) * 100vw);
    }

    &__description {
      bottom: 30px;
      width: var(--grid-column10);
    }

    &__slider-wrap {
      margin-top: 40px;
    }

    &__slider {
      margin-top: 18px;
    }
  }
}