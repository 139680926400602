.quote {
  --text-and-border-color-by-theme: black; //temp
  --background-color-by-theme: white; //temp

  background: var(--text-and-border-color-by-theme);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;

  svg {
    path {
      fill: var(--background-color-by-theme);
    }
  }
}