.service-accordion {
  &__link {
    display: block;
    width: 100%;

    &.animation-item {
      &._text-wrap {
        &._wrap-after {
          .service-accordion__link-text {
            &:after {
              transition-delay: var(--animation-delay);
              transition: transform var(--animation-duration) cubic-bezier(0.4, 0, 0.2, 1),
              border var(--default-timing) var(--header-timing-function),
              opacity var(--default-timing) var(--header-timing-function);
            }
          }
        }

        .animation-item {
          &__inner {
            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__link-text {
    position: relative;
    width: 100%;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 5px 0 5px;
      border-color: #000000 transparent transparent transparent;
    }
  }

  &__title {
    grid-column: 1/5;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__block {
    height: 0;
    display: block;
    opacity: 0;
    transition: height, opacity;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--header-timing-function);
    pointer-events: none;
  }

  &__item {
    transition: padding-bottom var(--default-timing) var(--teaser-transition-func);
    overflow: hidden;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--grey-lines);
    }

    &:last-child {
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--grey-lines);
      }
    }

    &._opened {
      .service-accordion {
        &__block {
          height: auto;
          opacity: 1;
          pointer-events: auto;
        }

        &__link-text {
          &:after {
            border-width: 0 5px 7px 5px;
            border-color: transparent transparent #000000 transparent;
          }
        }
      }
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        &._closed {
          .animation-item {
            &._text-wrap {
              .animation-item {
                &__inner {
                  &:first-child {
                    span {
                      transition-delay: calc(((#{$i} - 1) * 0.2 + 0.4) * 1s); // .4, .6, .8 s
                    }
                  }

                  &:after {
                    transition-delay: calc(((#{$i} - 1) * 0.2 + 0.8) * 1s); // .8, 1, 1.2 s
                  }
                }
              }
            }
          }
        }

        &._closing {
          .animation-item {
            &._text-wrap {
              .animation-item {
                &__inner {
                  &:after {
                    transition-delay: 0s;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .service-accordion {

  }
}

@include respond-up('xs-large') {
  .service-accordion {
    &__container {
      grid-column: 7/16;
    }

    &__link {
      padding: 30px 0;
    }

    &__item {
      &._opened {
        padding-bottom: 45px;
      }
    }
  }
}

@include respond('xs-large') {
  .service-accordion {

  }
}

@include respond-up('medium') {
  .service-accordion {

  }
}

@include respond('medium') {
  .service-accordion {
    &.grid-block {
      grid-row-gap: 30px;
    }

    &__link {
      padding: 25px 0;
    }

    &__item {
      &._opened {
        padding-bottom: 40px;
      }
    }
  }
}

@include respond-down('medium') {
  .service-accordion {
    &__title {
      grid-row: 1;
    }

    &__container {
      grid-row: 2;
      grid-column: 1/11;
    }
  }
}

@include respond-down('small') {
  .service-accordion {
    &.grid-block {
      grid-row-gap: 18px;
    }

    &__link {
      padding: 20px 0;
    }

    &__item {
      &._opened {
        padding-bottom: 30px;
      }
    }
  }
}