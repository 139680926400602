.marquee-line {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  &__list-wrapper {
    position: absolute;
    width: auto;
    animation: marquee 15s linear 0s infinite;
  }

  &__list {
    display: flex;

    &_2 {
      transform: translate(100%, 0);
    }

    &_3 {
      transform: translate(200%, 0);
    }
  }

  &__item {
    white-space: nowrap;
    font-weight: 700;
    color: #FFFFFF;
    opacity: 0.2;
    font-size: 56px;
    font-style: normal;
    line-height: 125%; /* 70px */
    text-transform: uppercase;
  }
}

@include respond-up('xs-large') {
  .marquee-line {
    height: 70px;

    &__item {
      padding: 0 44px;
      font-size: 56px;
    }
  }
}

@include respond('medium') {
  .marquee-line {
    height: 58px;

    &__item {
      padding: 0 15px;
      font-size: 46px;
    }
  }
}

@include respond-down('small') {
  .marquee-line {
    height: 33px;

    &__item {
      padding: 0 10px;
      font-size: 26px;
    }
  }
}

@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}