.index-catalog {
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list {
    display: grid;
    grid-column-gap: var(--grid-wide-gap);
  }

  &__inner-item-link {
    overflow: hidden;
    display: flex;
    position: relative;
  }

  &__parallax-container-inner {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .3);
      z-index: 50;
    }
  }

  &__parallax-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__link-text {
    z-index: 70;
    width: 65%;
    height: fit-content;
    display: flex;
    justify-content: center;
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__item {
    overflow: hidden;
  }
}

@include respond-up("x-large") {
  .index-catalog {
  }
}

@include respond-up("large") {
  .index-catalog {
  }
}

@include respond("large") {
  .index-catalog {
  }
}

@include respond-down("large") {
  .index-catalog {
  }
}

@include respond-up("s-large") {
  .index-catalog {
    padding-bottom: 140px;

    &__list {
      grid-row-gap: 50px;
    }
  }
}

@include respond("s-large") {
  .index-catalog {
  }
}

@include respond-down("s-large") {
  .index-catalog {
  }
}

@include respond-up("xs-large") {
  .index-catalog {
    padding-top: 80px;

    &__link-text {
      opacity: 0;
      transition: opacity 1s var(--default-transition-function);
    }

    &__parallax-container-inner {
      &:before {
        opacity: 0;
        transition: opacity 1s var(--default-transition-function);
      }
    }

    &__image {
      transition: transform 1s var(--default-transition-function);
    }

    &__inner-item-link {
      &:hover {
        .index-catalog {
          &__image {
            transform: scale(1.05);
          }

          &__link-text {
            opacity: 1;
          }

          &__parallax-container-inner {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }

    &__list {
      margin-top: 40px;
      grid-template-columns: repeat(2, var(--grid-wide-column8));
    }

    &__inner-item-link,
    &__parallax-container-inner,
    &__parallax-container {
      height: calc(504 / 726 * var(--grid-wide-column8));
    }

    &__picture {
      height: calc(504 / 726 * var(--grid-wide-column8));
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .index-catalog {
    padding-bottom: 120px;

    &__list {
      grid-row-gap: 40px;
    }
  }
}

@include respond-down("xs-large") {
  .index-catalog {
  }
}

@include respond-up("medium") {
  .index-catalog {
  }
}

@include respond("medium") {
  .index-catalog {
    padding-top: 60px;
    padding-bottom: 100px;

    &__list {
      grid-template-columns: repeat(2, var(--grid-wide-column5));
      margin-top: 35px;
      grid-row-gap: 30px;
    }

    &__inner-item-link,
    &__parallax-container-inner,
    &__parallax-container {
      height: calc(266 / 378 * var(--grid-wide-column5));
    }

    &__picture {
      height: calc(266 / 378 * var(--grid-wide-column5));
    }
  }
}

@include respond-down("medium") {
  .index-catalog {
  }
}

@include respond-down("small") {
  .index-catalog {
    padding-top: 40px;
    padding-bottom: 60px;

    &__inner-item-link,
    &__parallax-container-inner,
    &__parallax-container {
      height: calc(235 / 334 * var(--grid-wide-column10));
    }

    &__picture {
      height: calc(235 / 334 * var(--grid-wide-column10));
    }

    &__list {
      grid-template-columns: var(--grid-wide-column10);
      margin-top: 18px;
      grid-row-gap: 18px;
    }
  }
}
