:root {
  --modal-bg: var(--black-background);
  --modal-content-radius: 0px;
  --modal-content-shadow: none;
  --modal-content-padding: 0;
  --modal-content-background: var(--black-background);
  --modal-closer-color: white;
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal {
  &__bg {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background: var(--t);
    transition: background .4s var(--teaser-transition-func);
    overflow: scroll;

    &._opened {
      background: var(--modal-bg);
    }

    &._closed {
      background: var(--t);
    }

    &.default {
      --modal-bg: var(--black-background);
      --modal-content-background: var(--black-background);

      .modal__closer {
        background-image: url('../images/svg/modal-closer-white.svg');
      }
    }

    &.light {
      --modal-bg: var(--light-background);
      --modal-content-background: var(--light-background);

      .modal__closer {
        background-image: url('../images/svg/modal-closer-black.svg');
      }
    }
  }

  &__layout {
    position: relative;
    z-index: 11000;
    cursor: default;
    margin: 0 auto;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    transition-delay: 0s;
    transition: transform .4s var(--teaser-transition-func);

    &._opened {
      cursor: none!important;
    }
  }

  &__container {
    border-radius: var(--modal-content-radius);
    box-shadow: var(--modal-content-shadow);
    position: relative;
    display: flex;
    align-items: center;
    background: var(--modal-content-background);

    &._opened {
    }

    &._closed {
    }
  }

  &__content {
    display: flex;

    img {
      max-width: 100%;
    }
  }

  &__closer {
    position: absolute;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    right: var(--grid-spacer-and-indent);
    top: 45px;
  }
}

body.modal-loading {
  overflow: hidden;
  //--preloader-size: 100px;

  //пульсирующий кружочек раньше был
  &__loader {
  }

  &__content {
    overflow: scroll;
    height: 100%;
    width: 100%;
  }
}

@include respond-up('s-large') {
  .modal {

    &__layout {
      justify-content: flex-end;

      &._opened {
      }

      &._closed {
      }
    }

    &__closer {
    }
  }
}

@include respond('s-large') {
  .modal {
  }
}

@include respond-up('medium') {
  .modal {

  }
}

@include respond('medium') {
  .modal {

  }
}

@include respond-down('medium') {
  .modal {
    &__layout {
      &._opened {
      }

      &._closed {
      }
    }
  }
}

@include respond-down('small') {
  .modal {

  }
}