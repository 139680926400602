.quantity {
  display: flex;

  &__item {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 500;
    line-height: 135%;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-lines);
    border-radius: 50%;
  }
}

@include respond-up("medium") {
  .quantity {
    &__item {
      font-size: 14px;
      margin-left: 20px;
      width: 45px;
      height: 45px;
    }
  }
}

@include respond-down("small") {
  .quantity {
    &__item {
      font-size: 9px;
      margin-left: 8px;
      width: 26px;
      height: 26px;
    }
  }
}
