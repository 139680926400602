.product-viewed {
  .swiper {
    //margin-left: calc(var(--grid-spacer) * -1);
    //margin-right: calc(var(--grid-spacer) * -1);
    padding: 0 var(--grid-spacer);
  }

  &__items {
    &.animation-wrap {
      overflow: visible;
    }
  }
}

@include respond-up('xs-large') {
  .product-viewed {
    &__slider {
      margin-top: 40px;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }

    &__product-card {
      &.no-cursor {
        cursor: pointer!important;
      }
    }
  }
}

@include respond-up('medium') {
  .product-viewed {

  }
}

@include respond('medium') {
  .product-viewed {
    &__slider {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .product-viewed {

  }
}

@include respond-down('small') {
  .product-viewed {
    &__slider {
      margin-top: 18px;
    }
  }
}