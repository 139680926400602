.product-card {
  &__title-wrap {
    display: flex;
    flex-direction: column;
  }

  &__price-wrap {
    display: flex;
    align-items: center;
  }

  &__price {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: var(--grey-font);
  }

  &__price-old {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: var(--grey-font);
    text-decoration: line-through;
  }

  &__main {
    position: relative;
    display: flex;
    width: var(--grid-column5);
    height: var(--grid-column5);
  }

  &__picture {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__item-tag {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
  }
}

@include respond-up('s-large') {
  .product-card {
    &__price {
      font-size: 17px;
    }
  }
}

@include respond-up('xs-large') {
  .product-card {
    &__slider,
    &__pagination {
      display: none;
    }

    &__title-wrap {
      margin-top: 20px;
    }
    
    &__item {
      flex: 0 1 100%;
      counter-increment: product-card-picture-item;

      &:first-child {
        .product-card__item-thumb {
          border: 1px solid var(--grey-beige);

          &::before {
            background-color: black;
          }
        }
      }

      &:hover {
        .product-card {
          &__picture-wrap {
            z-index: 10 !important;
          }

          &__item-thumb {
            border: 1px solid var(--grey-beige) !important;

            &::before {
              background-color: black !important;
            }
          }
        }
      }
    }

    &__items {
      display: flex;
      width: 100%;

      &:hover {
        .product-card__item {
          &:first-child {
            .product-card__item-thumb {
              border: unset;

              &::before {
                background-color: var(--grey-beige);
              }
            }
          }
        }
      }
    }

    &__picture-wrap {
      display: block;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &__item-thumb {
      display: block;
      position: absolute;
      z-index: 15;
      top: 20px;
      width: 18px;
      height: 18px;
      border-radius: 100%;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 6px;
        height: 6px;
        background-color: var(--grey-beige);
        border-radius: 100%;
      }
    }
  }
}

@include respond-down('xs-large') {
  .product-card {
    &__price {
      font-size: 15px;
    }
  }
}

@include respond-up('medium') {
  .product-card {
    &__price-wrap {
      margin-top: 8px;
    }

    &__price-old {
      margin-left: 40px;
    }
  }
}

@include respond('medium') {
  .product-card {
    &__title-wrap {
      margin-top: 18px;
    }

    &__pagination {
      top: 20px;
      right: 19px;
    }
  }
}

@include respond-down('medium') {
  .product-card {
    &__items {
      display: none;
    }

    &__pagination {
      position: absolute;
      height: max-content;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      width: max-content;
      left: unset;
    }
  }
}

@include respond-down('small') {
  .product-card {
    &__price-wrap {
      align-items: baseline;
      margin-top: 6px;
    }

    &__price {
      font-size: 13px;
    }

    &__price-old {
      font-size: 10px;
      margin-left: 14px;
    }

    &__title-wrap {
      margin-top: 16px;
    }

    &__pagination {
      top: 10px;
      right: 9px;
    }
  }
}