:root {
  --default-input-height: 64px;
  --default-input-padding: 22px;
  --errors-color: var(--alert-color, #b50000)
}

$inputs: 'input[type="text"], input[type="number"], input[type="search"], input[type="password"]';

form {
  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
  }

  label {
    display: block;
    font-variation-settings: var(--font-settings);
  }

  ul.errors {
    margin-top: 10px;
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:empty {
      display: none;
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    font-size: 16px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  label {
    font-size: 16px;
  }

  #{$inputs}, select {
    height: var(--default-input-height);
    padding: 0 var(--default-input-padding);
  }

  textarea {
    padding: 20px var(--default-input-padding);
  }

  input[type="checkbox"] {
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary-color);
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 0;
    display: grid;
    place-content: center;
    transition-property: background, border-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--teaser-transition-func);
    cursor: pointer;

    &::before {
      content: "";
      width: 16px;
      height: 16px;
      transition: transform var(--default-timing) var(--teaser-transition-func);
      background-image: url('../images/svg/round-checkbox-black-unchecked.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:checked {
      &::before {
        background-image: url('../images/svg/round-checkbox-black-checked.svg');
      }
    }

    &._white {
      &::before {
        background-image: url('../images/svg/round-checkbox-white-unchecked.svg');
      }

      &:checked {
        &::before {
          background-image: url('../images/svg/round-checkbox-white-transparent-checked.svg');
        }
      }
    }

    &._black-and-white {
      &::before {
        background-image: url('../images/svg/round-checkbox-white-unchecked.svg');
      }

      &:checked {
        &::before {
          background-image: url('../images/svg/round-checkbox-white-checked.svg');
        }
      }
    }
  }
}

@include respond-up('s-large') {
  form {
    #{$inputs}, textarea, select {

    }
  }
}

@include respond('medium') {
  form {
    #{$inputs}, textarea, select {
      //font-size: 25px;
    }
  }
}

@include respond-down('small') {
  form {
    --default-input-height: 57px;
    --default-input-padding: 18px;

    #{$inputs}, textarea, select {
      font-size: 15px;
    }

    label {
      font-size: 15px;
    }
  }
}