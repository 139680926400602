.category-page {
  &__item-premium {
    grid-column: span 2;
  }

  &__items {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__item {
    grid-column: span 1;
    display: block;
  }

  &__filter {
    position: relative;

    &_sticky {
      display: flex;
      align-items: flex-end;
      width: 100vw;

      left: 0;
      right: 0;
      transition: height .5s cubic-bezier(.65,0,.25,1), transform .5s cubic-bezier(.65,0,.25,1);
      will-change: transform;
      position: fixed;
      background-color: var(--light-background);
      padding: 0 var(--grid-spacer);
      height: var(--filter-height);
      top: 0;
      transform: translate3d(0, -100%, 0);
      z-index: 4600;

      &._sticky {
        transform: translate3d(0, 0, 0);
        //z-index: 4900;

        .catalog-filter {
          &__top {
            border-bottom: unset;
          }
        }

        &._up {
          height: calc(var(--filter-height) + var(--header-height));
          //z-index: 4600;
        }
      }
      .catalog-filter {
        &__block {
          padding: 0 var(--grid-spacer-and-indent);
        }
      }
    }
  }

  &__header {
    transition: transform .6s cubic-bezier(.65,0,.25,1);

    &._sticky {
      transform: translate3d(0, -100%, 0);
    }

    &._up {
      transform: translate3d(0, 0, 0);
    }
  }
}

@include respond-up('large') {
  .category-page {
    &__items {
      grid-template-columns: repeat(4, 1fr);
    }

    .premium {
      height: calc(2.75 * var(--grid-gap) + 3.75 * var(--grid-column1));
    }

    .product-card {
      &__main {
        width: calc(2.75 * var(--grid-gap) + 3.75 * var(--grid-column1));
        height: calc(2.75 * var(--grid-gap) + 3.75 * var(--grid-column1));
      }
    }
  }
}

@include respond-up('s-large') {
  .category-page {
    &_collection {
      padding-top: 60px;
      padding-bottom: 160px;
    }

    &__items {
      grid-row-gap: 80px;
    }
  }
}

@include respond('s-large') {
  .category-page {
    &__items {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include respond-down('s-large') {
  .category-page {
    .product-card {
      &__main {
        width: var(--grid-column5);
        height: var(--grid-column5);
      }
    }
  }
}

@include respond-up('xs-large') {
  .category-page {
    --filter-height: 70px;
  }
}

@include respond('xs-large') {
  .category-page {
    &_collection {
      padding-top: 60px;
      padding-bottom: 140px;
    }

    &__items {
      grid-row-gap: 70px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include respond-up('medium') {
  .category-page {
    &__items {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .category-page {
    --filter-height: 60px;

    &_collection {
      padding-top: 20px;
      padding-bottom: 120px;
    }

    &__items {
      grid-row-gap: 50px;
    }
  }
}

@include respond-down('medium') {
  .category-page {
    &__items {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond-down('small') {
  .category-page {
    --filter-height: 46px;
    &_collection {
      padding-top: 15px;
      padding-bottom: 50px;
    }

    &__items {
      grid-row-gap: 35px;
      margin-top: 25px;
    }
  }
}