@include respond-up('xs-large') {
  .cart-receiving {
    margin-top: 60px;
  }
}

@include respond-up('medium') {
  .cart-receiving {
    &__tab-contents {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .cart-receiving {
    margin-top: 45px;
  }
}

@include respond-down('small') {
  .cart-receiving {
    margin-top: 22px;

    &__tab-contents {
      margin-top: 25px;
    }
  }
}