%text-contacts {
  font-family: var(--h-font);
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}

@include respond-up("medium") {
  %text-contacts {
    font-size: 16px;
  }
}

@include respond-down("small") {
  %text-contacts {
    font-size: 15px;
  }
}
