// а вот это уже тег
article {
  cite {
    display: flex;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-size: 18px 12px;
      background: black url('../images/svg/quote.svg') no-repeat 50% 50%;
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }
  }

  //я фикшу список, который должен начинаться с 03
  .list-from-three {
    ol {
      &:first-child {
        counter-reset: list 2;
      }
    }
  }
  // локально в одном месте после ссылки на форму

  ol {
    counter-reset: list;
    //counter-reset: list '(source: ' attr(start) ')';

    li {
      position: relative;
      list-style-position: inside;

      &:before {
        width: 30px;
        counter-increment: list;
        content: "0" counter(list) ".";
        position: absolute;
        left: 0;
        top: 0;
        color: var(--text-and-border-color-by-theme);
        font-style: italic;
      }

      &:nth-child(n+10) {
        &:before {
          content: counter(list) ".";
        }
      }
    }
  }

  ul {
    li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        left: 0;
        background: var(--text-and-border-color-by-theme);
      }
    }
  }

  p, li {
    color: var(--grey-font);
  }

  &, p, li, span {
    @extend %text-main-big;
  }

  p {
    big,
    cite {
      @extend %text-paragraph;
      color: var(--text-and-border-color-by-theme);
    }
  }

  p, ul, ol {
    &:not(:first-child) {
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include respond-up("x-large") {
  article {
  }
}

@include respond-up("large") {
  article {
  }
}

@include respond("large") {
  article {
  }
}

@include respond-down("large") {
  article {
  }
}

@include respond-up("s-large") {
  article {
    cite {
      padding-left: var(--grid-column1);
    }
  }
}

@include respond("s-large") {
  article {
  }
}

@include respond-down("s-large") {
  article {
  }
}

@include respond-up("xs-large") {
  article {
    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    ol, ul {
      li {
        &:not(:first-child) {
          margin-top: 25px;
        }
      }
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  article {
    cite {
      padding-left: calc(var(--grid-column1) + var(--grid-gap));
    }
  }
}

@include respond-down("xs-large") {
  article {
  }
}

@include respond-up("medium") {
  article {
    p, ul, ol {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }

    ul, ol {
      li {
        padding-left: 48px;
      }
    }

    ul {
      li {
        &:before {
          top: 13px;
        }
      }
    }
  }
}

@include respond("medium") {
  article {
    cite {
      padding-left: var(--grid-column1);
    }


    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    ol, ul {
      li {
        &:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}

@include respond-down("medium") {
  article {
  }
}

@include respond-down("small") {
  article {
    cite {
      padding-left: 56px;
    }

    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    p, ul, ol {
      &:not(:first-child) {
        margin-top: 22px;
      }
    }

    ol, ul {
      li {
        &:not(:first-child) {
          margin-top: 18px;
        }
      }
    }

    ol {
      li {
        padding-left: 46px;
      }
    }

    ul {
      li {
        padding-left: 40px;

        &:before {
          top: 13px;
        }
      }
    }
  }
}
