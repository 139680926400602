.preloader {
  display: flex;
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--black-background);
  transition: opacity .3s var(--default-transition-function) .2s;

  &.need-animation {
    .preloader {
      &__logo,
      &__percent {
        transform: translate3d(0, 10px, 0);
        opacity: 0;
      }

      &__bar {
        opacity: 0;
      }
    }
  }

  &.animate-out {
    opacity: 0;
    pointer-events: none;

    .preloader {
      &__logo,
      &__percent {
        transform: translate3d(0, 10px, 0);
        opacity: 0;
      }

      &__bar {
        opacity: 0;
      }
    }
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__logo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    transition: opacity, transform;
    transition-duration: .1s;
    transition-timing-function: var(--default-transition-function);
    transition-delay: .1s;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff;
      }
    }
  }

  &__percent {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
    text-transform: uppercase;
    color: #fff;
    margin-top: 28px;
    overflow: hidden;
    width: 42px;

    transition: opacity, transform;
    transition-duration: .1s;
    transition-timing-function: var(--default-transition-function);
    transition-delay: .15s;
  }

  &__numbers {
    width: auto;
    display: flex;
    animation: countNumber 2s forwards alternate .4s;
    animation-timing-function: steps(100);
  }

  &__number {
    text-align: center;
    flex: 0 0 42px;
  }

  &__bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: var(--light-color-opacity-50);
    transition: opacity .1s var(--default-transition-function);
    transition-delay: .2s;
  }

  &__bar-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 0;
    background-color: #fff;
    animation: preloader-bar 2s linear forwards .4s;
  }
}

@include respond-up('xs-large') {
  .preloader {
    padding: 137px 165px;
  }
}

@include respond-up('medium') {
  .preloader {
    &__logo {
      width: 222px;
      height: 54px;
    }
  }
}

@include respond('medium') {
  .preloader {
    padding: 137px var(--grid-spacer);
  }
}

@include respond-down('medium') {
  .preloader {

  }
}

@include respond-down('small') {
  .preloader {
    padding: 110px var(--grid-spacer);

    &__logo {
      width: 148px;
      height: 36px;
    }
  }
}

@keyframes preloader-bar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes countNumber {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -4200px;
  }
}