.article-link-more {
  display: flex;

  &__item {
    display: flex;
    flex-direction: row;
  }

  &__categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 6px;
  }

  &__text-wrap {
    padding: var(--article-link-more-pagging) 0;
    display: flex;
    flex-direction: column;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("x-large") {
  .article-link-more {
  }
}

@include respond-up("large") {
  .article-link-more {
  }
}

@include respond("large") {
  .article-link-more {
  }
}

@include respond-down("large") {
  .article-link-more {
  }
}

@include respond-up("s-large") {
  .article-link-more {
    &__categories {
      margin-bottom: 35px;
    }

    &__image-wrap {
      flex: 0 0 var(--grid-column3);
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(135 / 258 * var(--grid-column3));
    }
  }
}

@include respond("s-large") {
  .article-link-more {
  }
}

@include respond-down("s-large") {
  .article-link-more {
  }
}

@include respond-up("xs-large") {
  .article-link-more {
    flex-direction: row;

    &__text-wrap {
      padding-left: var(--grid-gap);
    }

    &__categories {
      margin-bottom: 30px;
      margin-left: -20px;
    }

    &__category {
      margin-left: 20px;
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .article-link-more {
    &__image-wrap {
      flex: 0 0 var(--grid-column3);
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(91 / 173 * var(--grid-column3));
    }
  }
}

@include respond-down("xs-large") {
  .article-link-more {
  }
}

@include respond-up("medium") {
  .article-link-more {
  }
}

@include respond("medium") {
  .article-link-more {
    flex-direction: column;

    &__picture {
      width: var(--grid-column4);
      height: calc(158 / 299 * var(--grid-column4));
    }

    &__category {
      margin-left: 20px;
    }

    &__image-wrap {
      margin-bottom: 20px;
    }

    &__categories {
      margin-left: -20px;
      margin-bottom: 28px;
    }
  }
}

@include respond-down("medium") {
  .article-link-more {
  }
}

@include respond-down("small") {
  .article-link-more {
    flex-direction: row;

    &__image-wrap {
      flex: 0 0 var(--grid-column4);
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(68 / 129 * var(--grid-column4));
    }

    &__category {
      margin-left: 16px;
    }

    &__categories {
      margin-left: -16px;
      margin-bottom: 18px;
    }

    &__text-wrap {
      padding-left: calc(var(--grid-gap) * 2 + var(--grid-column1));
    }
  }
}