.materials {
  &__accordion-list {
    //border-top: 1px solid var(--grey-lines);
  }
}

@include respond-up('large') {
  .materials {

  }
}

@include respond-up('s-large') {
  .materials {
    &__accordion-list {
      margin-top: 40px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 140px;
      }
    }
  }
}

@include respond('xs-large') {
  .materials {
    &__accordion-list {
      margin-top: 30px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 120px;
      }
    }
  }
}

@include respond-up('medium') {
  .materials {

  }
}

@include respond('medium') {
  .materials {
    &__accordion-list {
      margin-top: 30px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 90px;
      }
    }
  }
}

@include respond-down('medium') {
  .materials {

  }
}

@include respond-down('small') {
  .materials {
    &__accordion-list {
      margin-top: 18px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 60px;
      }
    }
  }
}