.reviews-common-block {
  &__list {
    &.swiper-wrapper {
      align-items: stretch;
    }
  }

  &__slide {
    &::before,
    &::after {
      display: none;
    }

    &:not(:last-child) {
      border-right: 1px solid var(--grey-lines);
    }

    &.swiper-slide {
      height: auto;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__all-reviews {

  }
}

@include respond-up('s-large') {
  .reviews-common-block {
    &__slide {
      &:nth-child(1),
      &:nth-child(3) {
        flex: 0 0 var(--grid-column5);
      }

      &:nth-child(2) {
        flex: 0 0 calc(var(--grid-column5) + var(--grid-gap) * 2);
      }
    }
  }
}

@include respond-up('xs-large') {
  .reviews-common-block {
    &__slider-wrap {
      margin-top: 40px;
    }

    &__bottom {
      margin-top: 80px;
    }
  }
}

@include respond('xs-large') {
  .reviews-common-block {
    &__slide {
      &:nth-child(1) {
        padding-right: 48px;
      }

      &:nth-child(2) {
        padding-left: 48px;
      }
    }
  }
}

@include respond-between('medium', 'xs-large') {
  .reviews-common-block {
    &__slide {
      &:nth-child(1) {
        flex: 0 0 50%;
      }

      &:nth-child(2) {
        flex: 0 0 50%;
        border-right: unset;
      }

      &:nth-child(3) {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .reviews-common-block {
    &__slider {
      border-top: 1px solid var(--grey-lines);
    }

    &__pagination-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .reviews-common-block {
    &__slider-wrap {
      margin-top: 30px;
    }

    &__slide {
      &:nth-child(1) {
        padding-right: 40px;
      }

      &:nth-child(2) {
        padding-left: 40px;
      }
    }

    &__bottom {
      margin-top: 50px;
    }
  }
}

@include respond-down('small') {
  .reviews-common-block {
    &__slider-wrap {
      margin-top: 18px;
    }

    &__slider {
      &.swiper {
        margin: 0 calc(var(--grid-spacer) * -1);
        padding: 0 var(--grid-spacer);
      }
    }

    &__slide {
      &.swiper-slide {
        width: var(--grid-column9);
      }

      &.review-item {
        border-bottom: unset;
      }

      &:nth-child(1) {
        padding-right: 25px;
      }

      &:nth-child(2) {
        padding: 30px 25px 0;
      }

      &:nth-child(3) {
        padding-left: 25px;
      }
    }

    &__bottom {
      margin-top: 40px;
    }

    &__pagination-wrap {
      position: relative;
      height: 2px;
      margin-top: 30px;

      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 0;
      }
    }
  }
}