.catalog-filter {
  //position: relative;

  &__top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-lines);
  }

  &__block {
    overflow: hidden;
    height: 0;
    transition: height .3s ease-out;
    position: absolute;
    z-index: 50;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    background-color: var(--light-background);
    margin: 0 calc(var(--grid-spacer) * -1);
  }

  &__block-inner {
    display: flex;
    padding: 20px var(--grid-spacer) 30px;
    opacity: 0;
    transition: opacity .2s ease-out .3s;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__link-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
  }

  &__link-icon {
    display: flex;
  }

  &__buttons {
    display: flex;
  }

  &__total {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__clear {
    margin-bottom: -4px;
  }

  &__close-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__inner {
    width: 100%;
    &._opened {
      .catalog-filter {
        &__block-inner {
          opacity: 1;
          transition-delay: .2s;
        }
      }
    }
  }
}

@include respond-up('xs-large') {
  .catalog-filter {
    &__link {
      &:hover {
        .catalog-filter__link-icon svg {
          transform: rotate(45deg);
        }
      }
    }

    &__link-icon svg {
      transition: transform .3s ease-out;
    }

    &__buttons {
      width: var(--grid-column6);
    }

    &__block-inner {
      align-items: flex-start;
    }

    &__top {
      padding-bottom: 24px;
    }
  }
}

@include respond-up('medium') {
  .catalog-filter {
    &__link-icon {
      margin-left: 32px;
    }

    &__buttons {
      align-items: center;
      justify-content: space-between;
    }

    &__block-inner {
      justify-content: space-between;
    }

    &__total {
      font-size: 15px;
    }
  }
}

@include respond('medium') {
  .catalog-filter {
    &__buttons {
      margin-top: 60px;
    }

    &__top {
      padding-bottom: 22px;
    }
  }
}

@include respond-down('medium') {
  .catalog-filter {
    &__block-inner {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .catalog-filter {
    &__link-icon {
      margin-left: 20px;
    }

    &__link-icon {
      svg {
        width: 10px;
        height: 10px;
      }
    }

    &__buttons {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 40px;
    }

    &__close-wrap {
      flex: 0 0 100%;
      margin-top: 18px;
    }

    &__total {
      font-size: 14px;
    }

    &__top {
      padding-bottom: 16px;
    }
  }
}