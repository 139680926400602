:root {
  // Максимальная ширина строки (строка = контейнер со спейсерами)
  --max-row-width: 4980px;

  // Расстояние между колонками - задается динамически по разрешениям - смотри _container.scss
  --grid-wide-gap: 1px;

  // Отступ от края экрана до строки (строка = контейнер со спейсерами)
  --grid-wide-indent: 0px;

  // Отступ от края строки до колонок (строка = контейнер со спейсерами)
  --grid-wide-spacer: 1px;

  // Отступ от края контейнера до колонок
  --grid-wide-spacer-and-indent: calc(var(--grid-wide-indent) + var(--grid-wide-spacer));

  // Кол-во колонок
  --grid-wide-columns-number: 1;

  // Ширина одной колонки - задается динамически по разрешениям - смотри _container.scss
  --grid-wide-column: calc((100vw - 2 * var(--grid-wide-spacer-and-indent) - (var(--grid-wide-columns-number) - 1) * var(--grid-wide-gap)) / var(--grid-wide-columns-number));

  // Ширина колонки
  --grid-wide-column1: var(--grid-wide-column);
  --grid-wide-column2: calc((var(--grid-wide-column) * 2) + var(--grid-wide-gap));
  --grid-wide-column3: calc((var(--grid-wide-column) * 3) + (var(--grid-wide-gap) * 2));
  --grid-wide-column4: calc((var(--grid-wide-column) * 4) + (var(--grid-wide-gap) * 3));
  --grid-wide-column5: calc((var(--grid-wide-column) * 5) + (var(--grid-wide-gap) * 4));
  --grid-wide-column6: calc((var(--grid-wide-column) * 6) + (var(--grid-wide-gap) * 5));
  --grid-wide-column7: calc((var(--grid-wide-column) * 7) + (var(--grid-wide-gap) * 6));
  --grid-wide-column8: calc((var(--grid-wide-column) * 8) + (var(--grid-wide-gap) * 7));
  --grid-wide-column9: calc((var(--grid-wide-column) * 9) + (var(--grid-wide-gap) * 8));
  --grid-wide-column10: calc((var(--grid-wide-column) * 10) + (var(--grid-wide-gap) * 9));
  --grid-wide-column11: calc((var(--grid-wide-column) * 11) + (var(--grid-wide-gap) * 10));
  --grid-wide-column12: calc((var(--grid-wide-column) * 12) + (var(--grid-wide-gap) * 11));
  --grid-wide-column13: calc((var(--grid-wide-column) * 13) + (var(--grid-wide-gap) * 12));
  --grid-wide-column14: calc((var(--grid-wide-column) * 14) + (var(--grid-wide-gap) * 13));
  --grid-wide-column15: calc((var(--grid-wide-column) * 15) + (var(--grid-wide-gap) * 14));
}

//@media (min-width: 1200px) {
//  :root {
//    --grid-wide-indent: calc((100vw - var(--max-row-width)) / 2);
//  }
//}

@include respond-up('x-large') {
  :root {
    --grid-wide-gap: 30px;
    --grid-wide-spacer: 100px;
    --grid-wide-columns-number: 16;
    --grid-wide-indent: 0px;
  }
}

@include respond('large') {
  :root {
    --grid-wide-gap: 30px;
    --grid-wide-spacer: 60px;
    --grid-wide-columns-number: 16;
    --grid-wide-indent: 0px;
  }
}

@include respond('s-large') {
  :root {
    --grid-wide-gap: 30px;
    --grid-wide-spacer: 60px;
    --grid-wide-columns-number: 16;
    --grid-wide-indent: 0px;
  }
}

@include respond('xs-large') {
  :root {
    --grid-wide-gap: 30px;
    --grid-wide-spacer: 60px;
    --grid-wide-columns-number: 16;
    --grid-wide-indent: 0px;
  }
}

@include respond('medium') {
  :root {
    --grid-wide-gap: 18px;
    --grid-wide-spacer: 30px;
    --grid-wide-columns-number: 10;
    --grid-wide-indent: 0px;
  }
}

@include respond-down('small') {
  :root {
    --grid-wide-gap: 8px;
    --grid-wide-spacer: 20px;
    --grid-wide-columns-number: 10;
    --grid-wide-indent: 0px;
  }
}
