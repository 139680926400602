.text {
  transition: color;
  transition-duration: var(--default-timing);
  transition-timing-function: var(--default-transition-function);

  &__grey-font {
    color: var(--grey-font);
  }

  &__black-font {
    color: black;
  }

  &__catalog-category {
    @extend %text-catalog-category;
  }

  &__collection-slider {
    @extend %text-collection-slider;
  }

  &__paragraph {
    @extend %text-paragraph;
  }

  &__large {
    @extend %text-large;
  }

  &__main-big {
    @extend %text-main-big;
  }

  &__main-small {
    @extend %text-main-small;
  }

  &__small-points {
    @extend %text-small-points;
  }

  &__contacts {
    @extend %text-contacts;
  }

  &__error-page {
  }

  &__cart-point {
    @extend %text-cart-point;
  }

  &__small-cart {
    @extend %text-small-cart;
  }

  &__card-description {
    @extend %text-card-description;
  }
}