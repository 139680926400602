.three-d-model-tabs {
  &__list {
    width: fit-content;
    border: 1px solid #DDD;
    padding: 1px;
    background: #FAFAFA;
    display: flex;
  }

  &__item {
    transition: background var(--default-timing) var(--default-transition-function);
    background: var(--light-background);

    &._active {
      background: #FAFAFA;
    }

    &.smart-tab {
      .three-d-model-tabs {
        &__link {
          display: flex;
        }
      }
    }
  }

  &__link {
    column-gap: 6px;
    padding: 6px 12px;
  }

  &__link,
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: var(--h-font);
    line-height: 125%;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__icon {
    width: 14px;
    height: 14px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('s-large') {
  .three-d-model-tabs {

  }
}

@include respond-up('medium') {
  .three-d-model-tabs {
    &__title {
      font-size: 12px;
    }
  }
}

@include respond('medium') {
  .three-d-model-tabs {

  }
}

@include respond-down('medium') {
  .three-d-model-tabs {

  }
}

@include respond-down('small') {
  .three-d-model-tabs {
    &__title {
      font-size: 10px;
    }
  }
}