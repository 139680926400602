.share {
  display: flex;

  .ya-share2 {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    color: #000000;
  }

  .ya-share2__list.ya-share2__list_direction_vertical {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}

@include respond-up('medium') {
  .share {
    flex-direction: column;
    row-gap: 28px;
    position: sticky;
    top: calc(var(--header-height) + 40px);
    .ya-share2 {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .share {
    flex-direction: row;
    column-gap: 20px;
    .ya-share2 {
      font-size: 13px;
    }
  }
}