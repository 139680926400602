// кусочек статьи в теге article на странице статьи
.article-part {
  display: flex;
  flex-direction: column;

  //do magic
  &:not(:first-child) {
    & > * {
      margin-top: var(--article-part-top-margin);
    }
  }

  &__image-wrapper {
    &:not(:first-child) {
      margin-top: var(--article-part-top-margin);
    }
  }

  &__premium-wrapper {
    &:not(:first-child) {
      margin-top: var(--article-part-top-margin);
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: space-between;

    &_video {
      position: relative;
    }
  }

  &__play-link {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__picture,
  &__video-picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-title {
    color: var(--grey-font);
  }
}

@include respond-up("x-large") {
  .article-part {
  }
}

@include respond-up("large") {
  .article-part {
  }
}

@include respond("large") {
  .article-part {
  }
}

@include respond-down("large") {
  .article-part {
  }
}

@include respond-up("s-large") {
  .article-part {
    --article-part-top-margin: 100px;

    &__video-picture {
      height: calc(var(--grid-column12) * 599 / 1114);
    }

    &__premium-wrapper {
      .premium {
        height: calc(var(--grid-column12) * 580 / 1114);
      }
    }
  }
}

@include respond("s-large") {
  .article-part {
  }
}

@include respond-down("s-large") {
  .article-part {
  }
}

@include respond-up("xs-large") {
  .article-part {
    &__first-image-wrapper {
      width: var(--grid-column6);

      .article-part {
        &__picture {
          height: var(--grid-column6);
        }
      }
    }

    &__second-image-wrapper {
      width: var(--grid-column5);

      .article-part {
        &__picture {
          height: calc(311 / 308 * var(--grid-column5));
        }
      }
    }
  }
}

// laptop 1100
@include respond("xs-large") {
  .article-part {
    --article-part-top-margin: 90px;

    &__video-picture {
      height: calc(var(--grid-column12) * 418 / 778);
    }

    &__premium-wrapper {
      .premium {
        height: calc(var(--grid-column12) * 405 / 778);
      }
    }
  }
}

@include respond-down("xs-large") {
  .article-part {
  }
}

@include respond-up("medium") {
  .article-part {
    &__text {
      width: var(--grid-column8);
      align-self: center;
    }

    &__image-title {
      margin-top: 16px;
    }
  }
}

@include respond("medium") {
  .article-part {
    --article-part-top-margin: 80px;

    &__first-image-wrapper {
      width: var(--grid-column4);

      .article-part {
        &__picture {
          height: var(--grid-column4);
        }
      }
    }

    &__second-image-wrapper {
      width: var(--grid-column3);

      .article-part {
        &__picture {
          height: calc(223 / 220 * var(--grid-column3));
        }
      }
    }

    &__video-picture {
      height: calc(var(--grid-column8) * 331 / 616);
    }

    &__premium-wrapper {
      .premium {
        height: calc(var(--grid-column8) * 321 / 616);
      }
    }
  }
}

@include respond-down("medium") {
  .article-part {
  }
}

@include respond-down("small") {
  .article-part {
    --article-part-top-margin: 50px;

    &__first-image-wrapper {
      width: var(--grid-column5);

      .article-part {
        &__picture {
          height: var(--grid-column5);
        }
      }
    }

    &__second-image-wrapper {
      width: var(--grid-column4);

      .article-part {
        &__picture {
          height: calc(131 / 129 * var(--grid-column4));
        }
      }
    }

    &__image-title {
      margin-top: 14px;
    }

    &__video-picture {
      height: calc(var(--grid-column10) * 180 / 334);
    }

    &__premium-wrapper {
      .premium {
        height: calc(var(--grid-column10) * 174 / 334);
      }
    }
  }
}
