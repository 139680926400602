.contacts {
  &__main-block {
    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__main-block-line {
    border-top: 1px solid var(--grey-lines);
  }

  &__grey-title,
  &__main-block-text,
  &__main-block-phone,
  &__main-block-messengers-link,
  &__main-block-socials-link,
  &__main-block-email {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
  }

  &__grey-title,
  &__main-block-phone,
  &__main-block-email {
    line-height: 135%;
  }

  &__main-block-text,
  &__main-block-messengers-link,
  &__main-block-socials-link {
    line-height: 145%;
  }

  &__grey-title,
  &__main-block-text,
  &__main-block-phone {
    text-transform: uppercase;
  }

  &__grey-title,
  &__main-block-messengers-link {
    color: var(--grey-font);
  }

  &__main-block-messengers-link {
    font-weight: 400;
    font-size: 15px;
  }

  &__main-block-socials-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__main-block-messengers-list {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }

  &__main-block-messengers-link {
    padding-left: 18px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background: var(--primary-color);
      border-radius: 50%;
    }
  }

  &__main-block-phone,
  &__main-block-email {
    display: inline-flex;
  }
}

@include respond-up('s-large') {
  .contacts {
    padding: 92px 0 120px;

    &__main-block-text {
      font-size: 20px;
    }

    &__main-block-socials-list {
      margin-top: 20px;
    }
  }
}

@include respond-up('xs-large') {
  .contacts {
    &__main-block-phone,
    &__main-block-email {
      font-size: 36px;
    }

    &__main-block-title {
      grid-column: 1/7;
    }

    &__main-block-text {
      grid-column: 1/7;
    }

    &__main-block-messengers {
      grid-column: 9/16;
    }

    &__main-block-socials {
      grid-column: 1/8;
    }

    &__main-block-email-wrap {
      grid-column: 9/16;
    }

    &__main-block-text,
    &__main-block-messengers {
      margin-top: 60px;
    }

    &__main-block-socials,
    &__main-block-email-wrap {
      margin-top: 80px;
    }

    &__main-block-messengers-list {
      margin-top: 10px;
    }
  }
}

@include respond('xs-large') {
  .contacts {
    padding: 82px 0 100px;

    &__main-block-socials-list {
      margin-top: 17px;
    }
  }
}

@include respond-up('medium') {
  .contacts {
    &__grey-title {
      font-size: 15px;
    }

    &__main-block-socials-link {
      font-size: 16px;
    }

    &__main-block-title {
      grid-row: 1;
    }

    &__main-block-text,
    &__main-block-messengers {
      grid-row: 2;
    }

    &__main-block-socials,
    &__main-block-email-wrap {
      grid-row: 3;
    }

    &__main-block-line {
      padding-top: 30px;
    }

    &__main-block-phone,
    &__main-block-email {
      margin-top: 10px;
    }
  }
}

@include respond-between('medium', 'xs-large') {
  .contacts {
    &__main-block-text {
      font-size: 18px;
    }
  }
}

@include respond('medium') {
  .contacts {
    padding: 70px 0 90px;

    &__main-block-phone {
      font-size: 34px;
    }

    &__main-block-email {
       font-size: 32px;
    }

    &__main-block-title,
    &__main-block-text,
    &__main-block-socials {
      grid-column: 1/5;
    }

    &__main-block-messengers,
    &__main-block-email-wrap {
      grid-column: 6/11;
    }

    &__main-block-text,
    &__main-block-messengers {
      margin-top: 45px;
    }

    &__main-block-socials,
    &__main-block-email-wrap {
      margin-top: 50px;
    }

    &__main-block-socials-list {
      margin-top: 20px;
    }

    &__main-block-messengers-list {
      margin-top: 14px;
    }
  }
}

@include respond-down('small') {
  .contacts {
    padding: 40px 0 50px;

    &__main-block-text {
      font-size: 16px;
    }

    &__grey-title {
      font-size: 14px;
    }

    &__main-block-phone,
    &__main-block-email {
      font-size: 26px;
    }

    &__main-block-line {
      padding-top: 20px;
    }

    &__main-block-socials-link {
      font-size: 15px;
    }

    &__main-block-title,
    &__main-block-text,
    &__main-block-messengers,
    &__main-block-socials,
    &__main-block-email-wrap {
      grid-column: 1/11;
    }

    &__main-block-title {
      grid-row: 1;
    }

    &__main-block-messengers {
      grid-row: 2;
      margin-top: 25px;
    }

    &__main-block-email-wrap {
      grid-row: 3;
      margin-top: 30px;
    }

    &__main-block-socials {
      grid-row: 4;
      margin-top: 30px;
    }

    &__main-block-text {
      grid-row: 5;
      margin-top: 43px;
    }

    &__main-block-socials-list {
      margin-top: 11px;
    }

    &__main-block-messengers-list {
      margin-top: 8px;
    }

    &__main-block-phone,
    &__main-block-email {
      margin-top: 9px;
    }
  }
}