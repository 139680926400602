.hamburger {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  &._opened {
    .hamburger {
      &__top,
      &__bottom {
        width: 32px;
      }

      &__top {
        transform: translate(0, 4px) rotate(45deg);
      }

      &__bottom {
        transform: translate(0, -6px) rotate(-45deg);
      }
    }
  }

  &__top,
  &__bottom {
    position: relative;
    width: 100%;
    height: 2px;

    &:before,
    &:after {
      right: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      transition: background .5s var(--header-timing-function);
      background-color: var(--text-and-border-color-by-theme);
    }

    &:before {
      transform-origin: left;
    }

    &:after {
      transform-origin: right;
      transform: scaleX(0);
    }
  }
}

@include respond-up('xs-large') {
  .hamburger {
    &:hover {
      .hamburger {
        &__top {
          &:after {
            transition: transform .35s cubic-bezier(.215, .61, .355, 1) .35s;
            transform: scaleX(1);
          }

          &:before {
            transition: transform .35s cubic-bezier(.215, .61, .355, 1);
            transform: scaleX(0);
          }
        }

        &__bottom {
          &:after {
            transition: transform .35s cubic-bezier(.215, .61, .355, 1) .5s;
            transform: scaleX(1);
          }

          &:before {
            transition: transform .35s cubic-bezier(.215, .61, .355, 1) .15s;
            transform: scaleX(0);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .hamburger {
    width: 50px;
    height: 12px;
  }
}

@include respond-down('small') {
  .hamburger {
    width: 36px;
    height: 11px;
    &._opened {
      height: 18px;
      .hamburger {
        &__top,
        &__bottom {
          width: 20px;
          transform-origin: center;
        }

        &__top {
          transform: translateY(8px) rotate(45deg);
        }

        &__bottom {
          transform: translateY(-8px) rotate(-45deg);
          background: grey;
        }
      }
    }
  }
}