.heading-underlined {
  border-bottom: 1px solid var(--grey-lines);
  padding-bottom: var(--heading-underlined-padding-bottom);
  margin-bottom: var(--heading-underlined-padding-bottom);
}

@include respond-up("x-large") {
  .heading-underlined {
  }
}

@include respond-up("large") {
  .heading-underlined {
  }
}

@include respond("large") {
  .heading-underlined {
  }
}

@include respond-down("large") {
  .heading-underlined {
  }
}

@include respond-up("s-large") {
  .heading-underlined {
  }
}

@include respond("s-large") {
  .heading-underlined {
  }
}

@include respond-down("s-large") {
  .heading-underlined {
  }
}

@include respond-up("xs-large") {
  .heading-underlined {
    --heading-underlined-padding-bottom: 40px;
  }
}

// laptop 1100
@include respond("xs-large") {
  .heading-underlined {
  }
}

@include respond-down("xs-large") {
  .heading-underlined {
  }
}

@include respond-up("medium") {
  .heading-underlined {
  }
}

@include respond("medium") {
  .heading-underlined {
    --heading-underlined-padding-bottom: 35px;
  }
}

@include respond-down("medium") {
  .heading-underlined {
  }
}

@include respond-down("small") {
  .heading-underlined {
    --heading-underlined-padding-bottom: 18px;
  }
}
